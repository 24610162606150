export class PejFirebaseConfigClass {
    private _apiKey: Readonly<string> = undefined;
    public get apiKey(): Readonly<string> { return this._apiKey; }
    public set apiKey(apiKey: Readonly<string>) { this._apiKey = apiKey; }

    private _projectId: Readonly<string> = undefined;
    public get projectId(): Readonly<string> { return this._projectId; }
    public set projectId(projectId: Readonly<string>) { this._projectId = projectId; }

    private _region: Readonly<string> = undefined;
    public get region(): Readonly<string> { return this._region; }
    public set region(region: Readonly<string>) { this._region = region; }
}


export const PejFirebaseConfig = Object.seal(new PejFirebaseConfigClass());
