export class ShopEventPlace {
    assigned: ShopEventPlaceAssignedOrder[];
    count: number;
    description: string;
    eventId: string;
    name: string;
    placeId: string;
    remaining: number;
    root: boolean;
    shopId: number;
    status: string;
}

export class ShopEventPlaceAssignedOrder {
    orderId: number;
    seats: number;
}
