import firebase from 'firebase/app';
import {PejFirebaseConfig} from './firebase-config';

export class PejFirebaseServiceClass {
    private _app: firebase.app.App = undefined;

    public app(): firebase.app.App {
        if (this._app === undefined) {
            const config = {
                apiKey: PejFirebaseConfig.apiKey,
                databaseURL: `https://${PejFirebaseConfig.projectId}.firebaseio.com`,
                projectId: PejFirebaseConfig.projectId,
            };
            this._app = firebase.initializeApp(config);
        }
        return this._app;
    }
}


export const PejFirebaseService = Object.seal(new PejFirebaseServiceClass());
