import {Routes} from '@angular/router';
import {exponentialBackoff} from 'pej-sdk';
import {AuthInAppComponent} from './auth/auth-in-app.component';
import {CallbackMessageComponent} from './callback/callback-message.component';
import {NoContentComponent} from './no-content/no-content.component';
import {CustomCssGroupResolverService} from './services/custom-css-group-resolver.service';
import {CustomCssShopResolverService} from './services/custom-css-shop-resolver.service';
import {TranslationResolverService} from './services/translation-resolver.service';
import {ShopResolverService} from './services/shop-resolver.service';
import {RedirectGuard} from './guards/redirect.guard';

export const ROUTES: Routes = [
    {
        path: '', pathMatch: 'full',
        canActivate: [RedirectGuard],
        component: NoContentComponent,
    },
    {
        path: 'g',
        loadChildren: () => exponentialBackoff(() => import('./group/group.module')).then(m => m.GroupModule),
        resolve: {
            customCssGroup: CustomCssGroupResolverService,
            translation: TranslationResolverService,
        },
        data: {navbarType: 'shop'},
    },
    // must be second to last
    {
        path: ':shopId',
        loadChildren: () => exponentialBackoff(() => import('./shop/shop.module')).then(m => m.ShopModule),
        resolve: {
            customCssShop: CustomCssShopResolverService,
            shop: ShopResolverService,
            translation: TranslationResolverService,
        },
        data: {navbarType: 'shop'}
    },
    // must be last
    {path: '**', component: NoContentComponent, data: {navbarType: 'homeLight'}},
];
