import {Observable} from 'rxjs';
import {CollectionResponse, MenuOptionGroup, Shop, Week} from '../models';
import {callPollingDistinctCollectionResponse, callPollingDistinctObject, shareCached} from '../utils';
import {PejShopService} from './shop';

const GET_DELAY = 10000;
const GET_OPENING_HOURS_DELAY = 60000;

const cacheKeyGet = (id) => `get:${id}`;
const cacheKeyGetAnon = (id) => `getAnon:${id}`;
const cacheKeyGetOpeningHours = (id) => `getOpeningHours:${id}`;
const cacheKeyGetMenuOptionGroupList = (id) => `getMenuOptionGroupList:${id}`;

export class PejShopRxServiceClass {
    private observableCache = {};

    public get(id: number | string): Observable<Shop> {
        return shareCached(this.observableCache, cacheKeyGet(id), () => {
            const caller = () => PejShopService.get(id, (GET_DELAY - 10) / 1000);
            return callPollingDistinctObject(caller, GET_DELAY);
        });
    }

    public getAnon(id: number | string): Observable<Shop> {
        return shareCached(this.observableCache, cacheKeyGetAnon(id), () => {
            const caller = () => PejShopService.getAnon(id, (GET_DELAY - 10) / 1000);
            return callPollingDistinctObject(caller, GET_DELAY);
        });
    }

    public getMenuOptionGroupList(id: number | string): Observable<CollectionResponse<MenuOptionGroup>> {
        return shareCached(this.observableCache, cacheKeyGetMenuOptionGroupList(id), () => {
            const caller = () => PejShopService.menuOptionGroupList(id as number, (GET_DELAY - 10) / 1000);
            return callPollingDistinctCollectionResponse<MenuOptionGroup>(caller, GET_DELAY);
        });
    }

    public getOpeningHours(id: number | string): Observable<Week> {
        return shareCached(this.observableCache, cacheKeyGetOpeningHours(id), () => {
            const caller = () => PejShopService.openingHoursGet(id, (GET_OPENING_HOURS_DELAY - 10) / 1000);
            return callPollingDistinctObject<Week>(caller, GET_OPENING_HOURS_DELAY);
        });
    }
}


export const PejShopRxService = Object.seal(new PejShopRxServiceClass());
/** @deprecated Use PejShopRxService */
export const shopRxService = PejShopRxService;
