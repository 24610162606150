import {translateI18nFieldWithBackup} from '../core/locale';

/** The Order with subclasses OrderItem, OrderItemOption classes represent an
 * order that has been placed by a customer.
 *
 * When fetching an Order from the server, this is the Menu Options selected
 * for one OrderItem in the order.
 *
 * Rationale: This is a separate model from MenuOptionItem to avoid complexity.
 * MenuOptionItem is the specification and does not have a quantity set.
 * OrderItemOption does not have a status set.
 *
 * It is a separate model from OrderItemInputOption to easily maintain different
 * parameters. By having a separate Input class it is easy to get an overview of
 * which fields are input fields to the class. */
export class OrderItemOption {
    /** ID of the MenuOptionGroup the selected item belongs to. */
    group: string;
    /** ID of the MenuOptionItem selected. */
    id: string;
    /** I18n name map translated by the server according to Accept-Language as a
     * single string. */
    _name: string;
    /** I18n name dictionary */
    nameI18n: {[key: string]: string};
    price: number;
    /** Quantity of the selected option. Defaults to 1. */
    quantity: number;

    public get name(): string { return translateI18nFieldWithBackup(this.nameI18n, this._name); }
    public set name(string: string) { this._name = string; }
}
