import {Renderer2} from '@angular/core';

export function customCssSet(renderer: Renderer2, oldElement: Element | null, css: string | null): Element {
    if (oldElement) {
        renderer.removeChild(document.head, oldElement);
    }
    if (css) {
        const newElement = renderer.createElement('style') as Element;
        newElement.innerHTML = css;
        renderer.appendChild(document.head, newElement);
        return newElement;
    } else {
        return undefined;
    }
}
