/** Converts from a floating point number in the regular currency unit for
 *  the currency to an integer in the lowest currency unit.
 *
 *  For example:
 *  Input could be (1.10, 'USD') and output would be 110. The input is
 *  dollars as a floating point number and the output is cents as an integer.
 */
export function currencyFloatToInteger(value: number, currency: string) {
    if (value == null) { return value; }
    const multiplier = currencyFloatToIntegerMultiplier(currency);
    // https://stackoverflow.com/a/41716722
    return Math.round((value + Number.EPSILON) * multiplier);
}

/** The multiplier from the lowest currency unit to the regular currency unit
 *  for the currency. */
export function currencyFloatToIntegerMultiplier(currency: string) {
    switch (currency) {
        case 'EUR':
        case 'SEK':
        case 'USD':
        default:
            return 100;
    }
}

/** Converts from an integer in the lowest currency unit for the currency
 *  to a floating point number in the regular currency unit.
 *
 *  For example:
 *  Input could be (110, 'USD') and output will be 1.1. The input is
 *  cents as an integer and the output is dollars as a floating point number.
 */
export function currencyIntegerToFloat(value: number, currency: string) {
    if (value == null) { return value; }
    const multiplier = currencyFloatToIntegerMultiplier(currency);
    return value / multiplier;
}
