export function iteratorFilter<T>(iter: Iterator<T>, callbackfn: (value: T) => boolean): T[] {
    const result: T[] = [];

    let entry = iter.next();
    while (!entry.done) {
        if (callbackfn(entry.value) === true) {
            result.push(entry.value);
        }
        entry = iter.next();
    }

    return result;
}

export function iteratorFilterWithIndex<T>(iter: Iterator<T>, callbackfn: (value: T, index: number) => boolean): T[] {
    const result: T[] = [];

    let index = 0;
    let entry = iter.next();
    while (!entry.done) {
        if (callbackfn(entry.value, index) === true) {
            result.push(entry.value);
        }
        entry = iter.next();
        index++;
    }

    return result;
}
