import {translateI18nField} from '../core';

export class PejGuideStep {
    guideI18n: {[key: string]: string;}

    public get guide(): string {
        return translateI18nField(this.guideI18n)
    }

    public set guide(ignored) {
    }
}
