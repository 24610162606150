import {filterMapOwn} from './object';
import {isArray} from './type';


export function createMatrixString(params: Record<string, any>) {
    if (params == null) { return ''; }
    const matrixStringArray = filterMapOwn(params, (value) => value != null, (value: string, key) => `${key}=${encodeURIComponent(value)}`);
    return matrixStringArray.length ? ';' + matrixStringArray.join(';') : '';
}


export function createQueryString(params: Record<string, any>) {
    if (params == null) { return ''; }
    const queryStringArray = filterMapOwn(params, value => value != null, (value: string, key) => encodeQueryParam(key, value));
    return queryStringArray.length ? '?' + queryStringArray.join('&') : '';
}


export function encodeQueryParam(key: string, value: any) {
    if (isArray(value)) {
        return value.map(v => `${key}=${encodeURIComponent(v)}`).join('&');
    } else {
        return `${key}=${encodeURIComponent(value)}`;
    }
}


// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function emailValid(email: string) {
    return EMAIL_REGEX.test(email);
}


export function getQueryParam(name: string) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (const v of vars) {
        const pair = v.split('=', 2);
        if (pair.length === 2 && pair[0] === name) {
            return decodeURIComponent(pair[1].replace(/\+/g, ' '));
        }
    }
    return null;
}


const HTML_ESCAPE_MAP = {
    '&': '&amp',
    '<': '&lt',
    '>': '&gt',
    '"': '&quot',
    '\'': '&#39'
};
const HTML_ESCAPE_REGEX = /[&<>"']/g;
const HTML_ESCAPE_TESTER = RegExp(HTML_ESCAPE_REGEX.source);

// Inspired by lodash escape()
// https://github.com/lodash/lodash/blob/9d11b48ce5758df247607dc837a98cbfe449784a/escape.js

/**
 * Converts the characters "&", "<", ">", '"', and "'" in `string` to their
 * corresponding HTML entities.
 *
 * **Note:** No other characters are escaped. To escape additional
 * characters use a third-party library like [_he_](https://mths.be/he).
 *
 * Though the ">" character is escaped for symmetry, characters like
 * ">" and "/" don't need escaping in HTML and have no special meaning
 * unless they're part of a tag or unquoted attribute value. See
 * [Mathias Bynens's article](https://mathiasbynens.be/notes/ambiguous-ampersands)
 * (under "semi-related fun fact") for more details.
 *
 * When working with HTML you should always
 * [quote attribute values](http://wonko.com/post/html-escaping) to reduce
 * XSS vectors.
 *
 * @param [string=''] The string to escape.
 * @returns Returns the escaped string.
 * @example
 *
 * escape('fred, barney, & pebbles')
 * // => 'fred, barney, &amp pebbles'
 */
export function htmlEscape(string: string): string {
    return (string && HTML_ESCAPE_TESTER.test(string))
        ? string.replace(HTML_ESCAPE_REGEX, (chr) => HTML_ESCAPE_MAP[chr])
        : string;
}


export function localStorageSafeGet(key: string) {
    try {
        return localStorage?.getItem(key);
    } catch (e) {
        return null;
    }
}


export function localStorageSafeSet(key: string, value: string) {
    try {
        localStorage?.setItem(key, value);
    } catch (ignored) {}
}


export function localStorageSafeRemove(key: string) {
    try {
        localStorage?.removeItem(key);
    } catch (ignored) {}
}


export function sessionStorageSafeGet(key: string) {
    try {
        return sessionStorage?.getItem(key);
    } catch (e) {
        return null;
    }
}


export function sessionStorageSafeSet(key: string, value: string) {
    try {
        sessionStorage?.setItem(key, value);
    } catch (ignored) {}
}


export function sessionStorageSafeRemove(key: string) {
    try {
        sessionStorage?.removeItem(key);
    } catch (ignored) {}
}
