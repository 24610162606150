/** Iterates over objects properties and returns an array of values returned
 *  from `callback`.
 *
 *  Ignores properties that fail the map.hasOwnProperty() check AND entries
 *  for which `filter` returns false.*/
export function filterMapOwn<V>(
    obj: {[key: string]: V},
    filter: (v: V, k: string) => boolean,
    callback: (v: V, k: string) => V
): V[] {
    const result = [];
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            const value = obj[prop];
            if (filter(value, prop)) {
                result.push(callback(value, prop));
            }
        }
    }
    return result;
}

/** General forEach that you can use on objects. Ignores properties that fail the map.hasOwnProperty() check. */
export function forOwn<K, V>(
    obj: {[key: string]: V},
    callback: (v: V, k: string) => void
): void {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            callback(obj[prop], prop);
        }
    }
}

export function objectCompare<T>(a: Readonly<T>, b: Readonly<T>) {
    if (a === b) { // same object
        return true;
    } else if (a == null) {
        return b == null;
    } else if (a == null) {
        return false;
    } else {
        return JSON.stringify(a) === JSON.stringify(b);
    }
}

/** Most efficient way to check if an Object is empty. */
export function objectIsEmpty(obj: Record<string, any>): boolean {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
}
