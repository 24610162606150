/* eslint-disable @typescript-eslint/naming-convention */
import {BehaviorSubject, Subscription} from 'rxjs';
import {EtagResponse, handleError, handleEtagResponse, handleResponse} from '../utils/fetch';
import {jsonSerializeWithGetters, shallowClone} from '../utils/object-mapper';
import {createQueryString} from '../utils/web';
import {PejConfig} from './config';
import {PejLocale} from './locale';

export class PejConnectionServiceClass {
    private _authToken = new BehaviorSubject<Readonly<string>>(undefined);
    public set authToken(authToken: Readonly<string>) { this._authToken.next(authToken); }

    private disposables: Subscription[] = [];
    private _headers: {[id: string]: string} = undefined;
    private _headersAnon: {[id: string]: string} = undefined;

    constructor() {
        const langDisposable = PejLocale.langObservable.subscribe(lang => {
            if (this._headers !== undefined) {
                this._headers['Accept-Language'] = lang;
            }
            if (this._headersAnon !== undefined) {
                this._headersAnon['Accept-Language'] = lang;
            }
        });
        this.disposables.push(langDisposable);

        const authTokenDisposable = this._authToken.subscribe(authToken => {
            if (this._headers !== undefined) {
                if (authToken != null) {
                    this._headers['X-Auth-Token'] = authToken;
                } else {
                    delete this._headers['X-Auth-Token'];
                }
            }
        });
        this.disposables.push(authTokenDisposable);
    }

    public destroy() {
        this.disposables.forEach(subscription => subscription.unsubscribe());
        this.disposables.length = 0;
    }

    public delete<T>(path: string): Promise<void> {
        return fetch(PejConfig.rootUrl + path, {
            method: 'DELETE',
            headers: this.getHeaders(),
        }).then(handleResponse, handleError);
    }

    public get<T>(path: string, params: Record<string, unknown>, etag: string): Promise<EtagResponse<T>> {
        const queryString = createQueryString(params);
        return fetch(PejConfig.rootUrl + path + queryString, {
            method: 'GET',
            headers: this.getHeadersWithEtag(etag),
        }).then(handleEtagResponse, handleError);
    }

    public getAnon<T>(path: string, params: Record<string, unknown>, etag: string): Promise<EtagResponse<T>> {
        const queryString = createQueryString(params);
        return fetch(PejConfig.rootUrl + path + queryString, {
            method: 'GET',
            headers: this.getHeadersAnonWithEtag(etag),
        }).then(handleEtagResponse, handleError);
    }

    public patch<T>(path: string, body: any, params?: Record<string, unknown>): Promise<T> {
        const queryString = createQueryString(params);
        const json = typeof body === 'string' ? body : jsonSerializeWithGetters(body);
        return fetch(PejConfig.rootUrl + path + queryString, {
            method: 'PATCH',
            headers: this.getHeaders(),
            body: json,
        }).then(handleResponse, handleError);
    }

    public post<T>(path: string, body: any, params?: Record<string, unknown>): Promise<T> {
        const queryString = createQueryString(params);
        const json = typeof body === 'string' ? body : jsonSerializeWithGetters(body);
        return fetch(PejConfig.rootUrl + path + queryString, {
            method: 'POST',
            headers: this.getHeaders(),
            body: json,
        }).then(handleResponse, handleError);
    }

    public put<T>(path: string, body: any, params?: Record<string, unknown>): Promise<T> {
        const queryString = createQueryString(params);
        const json = typeof body === 'string' ? body : jsonSerializeWithGetters(body);
        return fetch(PejConfig.rootUrl + path + queryString, {
            method: 'PUT',
            headers: this.getHeaders(),
            body: json,
        }).then(handleResponse, handleError);
    }

    private getHeaders() {
        if (this._headers === undefined) {
            this._headers = {
                'Content-Type': 'application/json',
                'X-Api-Key': PejConfig.apiKey,
                'Accept-Language': PejLocale.lang,
            };
            if (this._authToken.value != null) {
                this.getHeaders()['X-Auth-Token'] = this._authToken.value;
            }
        }
        return this._headers;
    }

    private getHeadersWithEtag(etag: string) {
        if (etag != null) {
            const headers = shallowClone(this.getHeaders());
            headers['If-None-Match'] = etag;
            return headers;
        } else {
            return this.getHeaders();
        }
    }

    private getHeadersAnon() {
        if (this._headersAnon === undefined) {
            this._headersAnon = {
                'Content-Type': 'application/json',
                'X-Api-Key': PejConfig.apiKey,
                'Accept-Language': PejLocale.lang,
            };
        }
        return this._headersAnon;
    }

    private getHeadersAnonWithEtag(etag: string) {
        if (etag != null) {
            const headers = shallowClone(this.getHeadersAnon());
            headers['If-None-Match'] = etag;
            return headers;
        } else {
            return this.getHeadersAnon();
        }
    }
}


export const PejConnectionService = Object.seal(new PejConnectionServiceClass());
