import {getI18nField, translateI18nFieldWithBackup} from '../core/locale';
import {arrayRemove} from '../utils/array';
import {mapObject} from '../utils/object-mapper';
import {Category} from './category';
import {PosData, PosDataServiceCharge} from './pos-data';
import {FirestoreObjectNumericId} from './shared/firestore-object';
import {ServingImage} from './shared/serving-image';

export function itemNeedsImage(categories: Category[], item: Item) {
    if (item.containerIds == null) { return false; }
    for (const categoryId of item.containerIds) {
        for (const category of categories) {
            if (category.id === categoryId && category.layout !== 'popup_list') {
                return true;
            }
        }
    }
    return false;
}

export class ItemOverride {
    public menuOptionsPreset: string;
    public status: ItemStatusEnum;
}

export class Item implements FirestoreObjectNumericId {
    account: string;
    ageLimit: number;
    /** Price before discounts are applied as fractions of currency. A value of 15000 (with Swedish currency) represents 150 SEK. */
    beforeDiscount: number;
    category: number;
    categoryIds: number[];
    containerIds: number[];
    deliveryGroupsFilter: string[];
    _description: string;
    descriptionI18n: {[id: string]: string};
    eventIds: string[];
    images: ServingImage[];
    itemId: number;
    key: string;
    menuOptionGroups: string[];
    nameI18n: {[id: string]: string};
    offerId: number;
    overrideDeliveryOptions: string[];
    posData: PosData;
    pricePrefix: string;
    priceUnit: string;
    price: number;
    prices: {[id: string]: number};
    rank: number;
    recycleDeposits: {[id: string]: number};
    section: string;
    shopId: number;
    status: ItemStatusEnum;
    storefront: Category;
    tags: string[];
    /** Tax rate with fractions. A value of 600 represents 6%. */
    taxRate: number;
    timeCreated: number;
    timeIntervalMap: {[id: string]: ItemOverride};
    timeUpdated: number;
    title: string;
    type: ItemType;
    upsellCategories: number[];

    public revive() {
        if (this.posData) {
            this.posData = mapObject(this.posData, PosData);
        }
        if (this.storefront) {
            this.storefront = mapObject(this.storefront, Category);
        }
    }

    public get description() {
        return translateI18nFieldWithBackup(this.descriptionI18n, this._description);
    }
    public set description(description: string) {
        this._description = description;
    }

    public get name() {
        return translateI18nFieldWithBackup(this.nameI18n, this.title);
    }
    public set name(ignored) {
    }

    public static calcTax(amount: number, taxRate: number) {
        return amount - amount * 10000.0 / (10000.0 + taxRate);
    }

    public static compare(lhs: Item, rhs: Item) {
        if (lhs.rank === undefined) {
            return 1;
        } else if (rhs.rank === undefined) {
            return -1;
        }
        if (lhs.rank < rhs.rank) {
            return -1;
        } else if (lhs.rank > rhs.rank) {
            return 1;
        }
        if (lhs.timeUpdated < rhs.timeUpdated) {
            return -1;
        } else if (lhs.timeUpdated > rhs.timeUpdated) {
            return 1;
        } else {
            return 0;
        }
    }

    public static getIdFromKey(itemKey: string) {
        const parts = itemKey.split('-', 3);
        return Number(parts[1]);
    }

    public static getKey(shopId: number, itemId: number | string) {
        return `${shopId}-${itemId}`;
    }

    public static getShopIdFromKey(itemKey: string) {
        const parts = itemKey.split('-', 2);
        return Number(parts[0]);
    }

    public static mapByCategory(items: Item[]): {[id: number]: Item[]} {
        const map: {[id: number]: Item[]} = {};
        for (const item of items) {
            if (item.containerIds != null) {
                for (const categoryId of item.containerIds) {
                    let ls: Item[] = map[categoryId];
                    if (ls == null) {
                        ls = [];
                        map[categoryId] = ls;
                    }
                    ls.push(item);
                }
            }
        }
        return map;
    }

    public static validateObject(item: Record<string, any>, currencies: Array<string>) {
        const itemObj: Item = mapObject(item, Item);
        itemObj.setDefaults();
        return itemObj.validate(currencies);
    }

    public getName(lang?: string) {
        return getI18nField(this, 'nameI18n', 'title', lang);
    }

    public hasDeliveryGroup(id) {
        return this.deliveryGroupsFilter && this.deliveryGroupsFilter.indexOf(id) !== -1;
    }

    public isInContainer(id) {
        return this.containerIds && this.containerIds.indexOf(id) !== -1;
    }

    public setDefaults() {
        if (this.descriptionI18n == null) {
            this.descriptionI18n = {};
            if (this.description != null) {
                this.descriptionI18n['en'] = this._description;
            }
        }
        if (this.nameI18n == null) {
            this.nameI18n = {};
            if (this.title != null) {
                this.nameI18n['en'] = this.title;
            }
        }
        if (this.tags == null) {
            this.tags = [];
        }
        if (this.timeIntervalMap == null) {
            this.timeIntervalMap = {};
        }
        return this;
    }

    public setHasDeliveryGroup(id, enabled) {
        if (this.deliveryGroupsFilter === undefined) {
            this.deliveryGroupsFilter = [];
        }
        if (enabled) {
            this.deliveryGroupsFilter.push(id);
        } else {
            arrayRemove(this.deliveryGroupsFilter, id);
        }
    }

    public setInContainer(id, enabled) {
        if (this.containerIds === undefined) {
            this.containerIds = [];
        }
        if (enabled) {
            this.containerIds.push(id);
        } else {
            arrayRemove(this.containerIds, id);
        }
    }

    public setStringId(id: string): void {
        this.itemId = Number(id);
    }

    public trackingEquals(o: Item) {
        return o != null && this.key === o.key && this.timeUpdated === o.timeUpdated;
    }

    public validate(currencies: Array<string>) {
        const violations = [];
        if (!this.nameI18n['en']) {
            violations.push({ prop: 'nameI18n.en', message: 'Produkttitel (default) är inte ifyllt'});
        }
        const hasServiceCharge = this.posData?.serviceCharge?.id;
        for (const c of currencies) {
            let p = this.prices?.[c];
            if (!p || isNaN(p)) {
                violations.push({ prop: `prices.${c}`, message: 'Pris är inte ifyllt'});
            }
            if (!hasServiceCharge) {
                continue;
            }
            p = this.posData.serviceCharge?.prices?.[c];
            if (!p || isNaN(p) || p < 0) {
                violations.push({ prop: `posData.serviceCharge.prices.${c}`, message: 'Pris för service charge är inte ifyllt'});
            }
        }
        if (this.taxRate == null) {
            violations.push({ prop: 'taxRate', message: 'Momssats är inte ifyllt'});
        }
        return violations;
    }
}

export type ItemStatusEnum = null |
    /** This item is being show-cased (but not sold) online and can be bought from the store. */
    'for_sale' |
    /** This item has been hidden by a shop admin. It is hidden from other users. */
    'hidden' |
    /** Restore root status on item. Used in TimeIntervalOverride logic. */
    'restore' |
    /** This item is sold out. It will still be visible to shop visitors but not in global browsing. */
    'sold_out';

export type ItemType = null |
    /** this item will turn into a charged_card Offer on purchase, with
     * Offer.id=Item.offerId */
    'charged_card' |
    /** this item will add a 10 minute countdown in the Order view, replacing
     * the displayed status while status is wait_packaging or
     * wait_delivery_pickup. This only affects the Order in any way on the
     * client-side. */
    'countdown_ticket';
