/** Lazy-loading Google Maps API wrapper. */
export class GoogleMapsWrapperClass {
    private googleMaps: typeof google.maps = undefined;
    private key: string = undefined;
    private libraries: string[] = [];
    private scriptLoadedPromise: Promise<void> = undefined;

    public addLibrary(library: string) {
        if (this.libraries.indexOf(library) === -1) {
            this.libraries.push(library);
            this.googleMaps = undefined;
            this.scriptLoadedPromise = undefined;
        }
    }

    public get(): Promise<any> {
        if (this.googleMaps == null) {
            return this.init();
        } else {
            return Promise.resolve(this.googleMaps);
        }
    }

    public setKey(key: string) {
        this.key = key;
    }

    private init() {
        return this.load().then(() => {
            if (this.googleMaps != null) {
                return this.googleMaps;
            } else {
                // `google.maps` is a global namespace created by Google Maps JavaScript API and defined in @types/googlemaps
                this.googleMaps = google.maps;
                return this.googleMaps;
            }
        });
    }

    private load(): Promise<void> {
        if (this.scriptLoadedPromise == null) {
            this.scriptLoadedPromise = new Promise((resolve) => {
                this.loadRecursive(resolve);
            });
        }
        return this.scriptLoadedPromise;
    }

    private loadRecursive(resolve: (value?: PromiseLike<void>) => void, backoff = 100) {
        const script = document.createElement('script');
        const libraries = this.libraries.join(',');
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=${libraries}&key=${this.key}`;
        script.onload = () => {
            resolve();
        };
        script.onerror = () => {
            document.head.removeChild(script);
            setTimeout(() => {
                this.loadRecursive(resolve, Math.min(backoff * 2, 2000));
            }, backoff);
        };
        document.head.appendChild(script);
    }
}

export const googleMapsWrapper = Object.seal(new GoogleMapsWrapperClass());
