import {translateI18nField} from '../core/locale';
import {mapObject} from '../utils/object-mapper';

export class Offer {
    /** `charged_card`: The amount of items you can purchase from one ticket of
     * this offer. May not be null. */
    charges: number;
    chargesPerUser: number;
    /** `promo_code`: The code required */
    code: string;
    descriptionI18n: { [id: string]: string };
    /** `punch_card`: The amount of items needed to be purchased to trigger the
     * reward. May not be null. */
    goal: number;
    id: number;
    /** Item IDs of items that count towards the goal. Keys from multiple shops are supported. */
    itemIds: number[];
    /** Item keys of items that count towards the goal. Keys from multiple shops are supported. */
    itemKeys: string[];
    nameI18n: { [id: string]: string };
    /** `quantity_price`: The price for quantity (goal) number of items. */
    prices: {[id: string]: number};
    shopIds: number[];
    /** `punch_card`: Specify the amount of items needed to be purchased for the
     * offer to show up to the customer. Defaults to 1. */
    showAfter: number;
    /** Timestamp of when this entity was created. */
    timeCreated: number;
    /** Timestamp specifying when orders stop counting against this offer. May
     * be null. */
    timeEnd: number;
    /** Duration in milliseconds that all the orders must be within. May be
     * null. */
    timeSpan: number;
    /** Timestamp specifying when orders start counting against this offer. May
     * be null. */
    timeStart: number;
    /** ID to use in integrations */
    posReferenceId: string;
    /** The reward of this offer */
    reward: OfferReward;
    /** Time interval settings for when the offer is active. May be null. */
    timeInterval: OfferTimeInterval;

    type: OfferTypes;

    public revive() {
        if (this.reward) {
            this.reward = mapObject(this.reward, OfferReward);
        }
        if (this.timeInterval) {
            this.timeInterval = mapObject(this.timeInterval, OfferTimeInterval);
        }
    }

    public get description() {
        return translateI18nField(this.descriptionI18n);
    }

    public set description(ignored) {
    }

    public get name() {
        return translateI18nField(this.nameI18n);
    }

    public set name(ignored) {
    }

    public setDefaults() {
        if (this.descriptionI18n == null) {
            this.descriptionI18n = {};
        }
        if (this.itemIds == null) {
            this.itemIds = [];
        }
        if (this.itemKeys == null) {
            this.itemKeys = [];
        }
        if (this.nameI18n == null) {
            this.nameI18n = {};
        }
        if (this.reward == null) {
            this.reward = new OfferReward();
        }
        this.reward.setDefaults();
        if (this.timeInterval == null) {
            this.timeInterval = new OfferTimeInterval();
        }
        return this;
    }
}

export type OfferTypes = 'always_apply' |
    'charged_card' |
    'promo_code' |
    'punch_card' |
    'quantity_price';

export class OfferReward {
    discountPercentage: number;
    itemIds: number[];
    itemKeys: string[];
    maxValues: {[id: string]: number};
    name: string;
    type: OfferRewardTypes;

    public setDefaults() {
        if (this.itemIds == null) {
            this.itemIds = [];
        }
        if (this.itemKeys == null) {
            this.itemKeys = [];
        }
    }
}

export type OfferRewardTypes =
    /** The reward is a free item from {@link OfferReward#itemKeys}. */
    'free_item_other' |
    /** The reward is a free item from {@link Offer#itemKeys}. */
    'free_item_same';

export class OfferTimeInterval {
    timeIntervalId: string;
    inverse: boolean;
}
