import {FirestoreObjectStringId} from './shared/firestore-object';
import {Shop} from './shop';


const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export class PejReportSpecification implements FirestoreObjectStringId, FirebaseOrderedCollection, ReportSpecification {
    id: string;
    ordering: number;
    fromCache: boolean;

    contents: ReportContent[];
    emailConfig?: EmailConfig;
    localeSettings: LocaleSettings;

    parameters: ReportData;
}


export class PejScheduledReport implements FirestoreObjectStringId, FirebaseOrderedCollection, ScheduledReport {
    id: string;
    ordering: number;
    fromCache: boolean;

    name: string;
    active = true;

    period: 'day' | 'week' | 'month' = 'month';
    timeZone: string = 'Europe/Stockholm';
    offsetDays = 0;

    spec: ScheduledReportSpecification;

    public setStringId(id: string): void {
        this.id = id;
    }
    public setDefaultReportSpecification(shop: Shop): void {
        this.timeZone = shop.timeZone;
        this.spec = {
            parameters: {
                shopIds: [shop.id],
                masterShopIds: [],
            },
            contents: [{type: 'pdf', tables: ['accounting']}],
            localeSettings: {
                lang: 'en',
                timeZone: shop.timeZone
            },
            emailConfig: {
                emails: [],
                sendEmpty: false
            }
        };
    }
}

interface FirebaseOrderedCollection {
    ordering: number;
    fromCache: boolean;
}

export type PejReportTableType =
    'accounting' |
    'payment_methods' |
    'stripe_payouts' |
    'sales_per_product' |
    'sales_per_account' |
    'transactions' |
    'menu_options' |
    'stripe_charges' |
    'sales_per_place' |
    'sales_per_channel' |
    'shop_availability';

export const ALL_TABLES: PejReportTableType[] = [
    'accounting', 'payment_methods', 'stripe_payouts',
    'sales_per_product', 'sales_per_account', 'transactions',
    'menu_options', 'stripe_charges', 'sales_per_place', 'sales_per_channel',
    'shop_availability'
];

export interface LocaleSettings {
    lang: string;
    // Determines what time zone to use for date formatting
    timeZone: string;
}

interface PdfContent {
    type: 'pdf';
    tables: PejReportTableType[];
}
interface CsvContent {
    type: 'csv';
    table: PejReportTableType;
    delimiter: string;
}

interface JsonContent {
    type: 'json';
    tables: PejReportTableType[];
}

export type ReportContent =
    PdfContent
    | CsvContent
    | JsonContent
    | {type: 'metrics'}
    | {type: 'orders'}
    | {type: 'sie'};

export interface ReportData {
    masterShopIds: number[];
    shopIds: number[];
    start: number;
    end: number;
}

export interface EmailConfig {
    emails: string[];
    sendEmpty?: boolean;
    subject?: string;
}

interface _ReportContentAndSettings {
    contents: ReportContent[];
    emailConfig?: EmailConfig;
    localeSettings: LocaleSettings;
}

export interface ReportSpecification extends _ReportContentAndSettings {
    parameters: ReportData;
}

export interface ScheduledReportSpecification extends _ReportContentAndSettings {
    parameters: Omit<ReportData, 'start'|'end'>;
}

export interface ScheduledReport {
    name: string;
    active: boolean;

    spec: ScheduledReportSpecification;
    
    period: 'day' | 'week' | 'month';
    offsetDays: number;
    /** Determines what time zone to use to define `start` and `end` of the period */
    timeZone: string;
}

export interface ReportFile {
    id: string;
    created: number;
    filename: string;
    contentType: string;
    size: number;
    /** Indicates the selected time interval did not contain any data.
     * Used by the scheduled report email service to determine
     * if it should send the file or not */
    isEmpty: boolean;
};

enum ReportStatusCode {
    finished = 'finished',
    pending = 'pending',
    error = 'error',
    invalid = 'invalid'
}

interface ReportRunResult {
    created: number;
    finished?: number;
    status: ReportStatusCode;
    error?: any;
    result?: ReportFile[];
    emailHasBeenSent?: boolean;
}

export type PejReportRun = ReportRunResult & ReportSpecification;

const e = (message: string) => {
    throw {status: 400, error: {key: 'param_invalid', message}};
}

const TABLES_JOINED = ALL_TABLES.map(t => `"${t}"`).join(', ');


function validateTimeZone(timeZone: string) {
    if (typeof timeZone !== 'string')
        e('`timeZone` parameter must be a string');
    try {
        Intl.DateTimeFormat('default', {timeZone});
    }
    catch (_) {
        e(`"${timeZone}" is not a valid time zone`);
    }
}

function validateLocaleSettings(l: LocaleSettings): l is LocaleSettings {
    if (typeof l.lang !== 'string')
        e(`"${l.lang}" is not a valid language string`);

    validateTimeZone(l.timeZone);

    return true;
}

function validatePdfContent(c: PdfContent): c is PdfContent {
    if (c.type !== 'pdf')
        e('PDF content must have `type` "pdf"');

    if (!isArray(c.tables) || !c.tables.every(t => ALL_TABLES.includes(t)))
        e(`when type is \`pdf\` then \`content.tables\` must be an array containing only tables from ${TABLES_JOINED}`);

    return true;
}

function validateCsvContent(c: CsvContent): c is CsvContent {
    if (c.type !== 'csv')
        e('PDF content must have `type` "csv"');

    if (!c.delimiter)
        e('csv content must have a delimiter');

    if (c.delimiter.length > 1)
        e('csv delimiter must be a single character');

    if (typeof c.table !== 'string' || !ALL_TABLES.includes(c.table))
        e(`when format is \`csv\` the \`content.table\` must be one of ${TABLES_JOINED}`);

    return true;
}

function validateJsonContent(c: JsonContent): c is JsonContent {
    if (c.type !== 'json')
        e('PDF content must have `type` "json"');

    if (!isArray(c.tables) || !c.tables.every(t => ALL_TABLES.includes(t)))
        e(`when type is \`json\` then \`content.tables\` must be an array containing only tables from ${TABLES_JOINED}`);

    return true;
}

function validateContents(contents: ReportContent[]) {
    if (!contents || !isArray(contents))
        e('`contents` must be a list of report contents');

    if (contents.some(c => c.type === 'csv' && c.table === 'shop_availability')) {
        if (contents.length !== 1) {
            e('when using table `shop_availability` it must be the only report content in `contents`');
        }
    }

    for (const content of contents)
        validateContent(content);

    return true;
}

function validateContent(content: ReportContent): content is ReportContent {

    if (!content.type)
        e('content must have a type');

    if (content.type === 'pdf')
        validatePdfContent(content);
    else if (content.type === 'csv')
        validateCsvContent(content);
    else if (content.type === 'json')
        validateJsonContent(content);
    else if (['metrics', 'orders', 'sie', 'caspeco'].includes(content.type))
        'this is valid';
    else
        e('the supported content types are "pdf", "csv", "json", "metrics", "orders", "sie" and "caspeco"');

    return true;
}

export function validateEmailConfig(config: EmailConfig): config is EmailConfig {

    if (!config || typeof config !== 'object')
        e('`emailConfig` must be an email message configuration object');

    if (config.sendEmpty !== undefined && typeof config.sendEmpty !== 'boolean')
        e('`emailConfig.sendEmpty` must be a boolean or not set');

    if (config.subject !== undefined && typeof config.subject !== 'string')
        e('`emailConfig.subject` must be a string or not set');

    if (!isArray(config.emails) ||
        !config.emails.every(e => e.match(EMAIL_REGEX)) ||
        config.emails.length === 0)
        e('`emailConfig.emails` must be a list of valid emails containing at least one adress');

    return true;
}

export function validateReportData(report: ReportData): report is ReportData {

    const masters = report.masterShopIds;
    const shops = report.shopIds;
    
    if (!isArray(masters) || masters.some(id => !isNumber(id)))
        e('`masterShopIds` should be array of shop ids');

    if (!isArray(shops) || shops.some(id => !isNumber(id)))
        e('`shopIds` should be array of shop ids');

    if (masters.length === 0 && shops.length === 0) {
        e('either `shopIds` or `masterShopIds` must be non-empty');
    }

    const start = report.start;
    const end = report.end;

    if (!isNumber(start) || start < Date.now() / 1000)
        e('`start` time must be a number expressed in ms');

    if (!isNumber(end) || end < Date.now() / 1000)
        e('`end` time must be a number expressed in ms');

    if (start >= end)
        e('`start` must begin before `end`');
    
    if (masters.length > 0 && end - start > 1000 * 60 * 60 * 24 * 32)
        e('when fetching from masterShops the time interval `start` to `end` cannot exceed one month');

    return true;
}

export function validateReportSpecification(s: ReportSpecification): s is ReportSpecification {

    if (!s.parameters)
        e('`parameters` must be report data parameters');

    validateReportData(s.parameters);

    validateContents(s.contents);

    if (s.emailConfig)
        validateEmailConfig(s.emailConfig);

    if (!s.localeSettings)
        e('`localeSettings` must be provided in the report specification');

    validateLocaleSettings(s.localeSettings);
    
    return true;
}

export function validateScheduledReport(scheduled: ScheduledReport): scheduled is ScheduledReport {

    if (typeof scheduled.active !== 'boolean')
        e('`active` must be boolean');

    if (typeof scheduled.name !== 'string')
        e('`name` must be string');

    if (!['day', 'week', 'month'].includes(scheduled.period))
        e('`period` must be "day"|"week"|"month"');

    if (!isNumber(scheduled.offsetDays) || scheduled.offsetDays < 0)
        e('`offsetDays` must be a positive number');

    if (!scheduled.timeZone)
        e('`timeZone` must be a valid time zone');

    validateTimeZone(scheduled.timeZone)

    if (!scheduled.spec || !scheduled.spec.parameters || typeof scheduled.spec.parameters !== 'object')
        e('`spec` must be a report specification');

    validateReportSpecification(
        {
            ...scheduled.spec,
            // mock valid start and end times
            parameters: {
                start: Date.now() - 1000,
                end: Date.now(),
                ...scheduled.spec.parameters
            },        
        }
    );
    
    return true;
}


const isNumber = (v): v is number =>
    typeof v === 'number' && isFinite(v);

const isArray = <T>(a: T[]): a is Array<T> => Array.isArray(a);