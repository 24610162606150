export class OrderingInterface {
    ordering: number;
}

export const MIN_ORDERING = 0;
export const MAX_ORDERING = 9000000000000000; // Number.MAX_SAFE_INTEGER but rounded down

/** Move `object` down in `array` (increase position by 1) and update the
 * `ordering` field for the objects in the array as necessary. Everything
 * is updated in-place.
 *
 * Returns a set of the objects that have updated ordering values. */
export function orderingMoveDown<T extends OrderingInterface>(array: T[], object: T): Set<T> {
    const i = array.indexOf(object);
    if (i === -1) { return new Set(); }
    if (i === array.length - 1) { return new Set(); }
    array.splice(i, 1);
    const newIndex = Math.min(i + 1, array.length);
    array.splice(newIndex, 0, object);
    return orderingUpdate(array, newIndex);
}

/** Move `object` up in `array` (decrease position by 1) and update the
 * `ordering` field for the objects in the array as necessary. Everything
 * is updated in-place.
 *
 * Returns a set of the objects that have updated ordering values. */
export function orderingMoveUp<T extends OrderingInterface>(array: T[], object: T): Set<T> {
    const i = array.indexOf(object);
    if (i < 1) { return new Set(); }
    array.splice(i, 1);
    const newIndex = Math.max(i - 1, 0);
    array.splice(newIndex, 0, object);
    return orderingUpdate(array, newIndex);
}

/** Update the `ordering` field for the objects in the array as necessary.
 * Everything is updated in-place.
 *
 * Returns a set of the objects that have updated ordering values. */
export function orderingUpdate<T extends OrderingInterface>(array: T[], newIndex: number): Set<T> {
    const dirty = new Set<T>();
    // remove any undefined values
    for (const current of array) {
        if (current.ordering == null) {
            current.ordering = 0;
        }
    }
    orderingUpdateSingle(array, newIndex, dirty);
    let lastOrdering = array[0].ordering;
    for (let i = 1; i < array.length; i++) {
        const current = array[i];
        if (current.ordering <= lastOrdering) {
            orderingUpdateRecursive(array, i - 1, i, dirty);
        }
        lastOrdering = current.ordering;
    }
    return dirty;
}

/** Attempt to fix ordering of `array` by changing just the object at `index`.
 */
function orderingUpdateSingle<T extends OrderingInterface>(array: T[], index: number, dirty: Set<T>) {
    const current = array[index];
    if (index === 0) {
        // start reached; set ordering min value
        if (current.ordering !== MIN_ORDERING) {
            current.ordering = MIN_ORDERING;
            dirty.add(current);
        }
    } else if (index === array.length - 1) {
        // end reached; set max ordering max value
        if (current.ordering !== MAX_ORDERING) {
            current.ordering = MAX_ORDERING;
            dirty.add(current);
        }
    } else {
        const prev = array[index - 1];
        const next = array[index + 1];
        if (prev.ordering < current.ordering && current.ordering < next.ordering) {
            // already ordered
        } else if (prev.ordering + 2 > next.ordering) {
            // can't solve - leave this to orderingUpdateRecursive()
        } else {
            const step = (next.ordering - prev.ordering) / 2;
            current.ordering = Math.floor(prev.ordering + step);
            dirty.add(current);
        }
    }
}

/** This function should be called with prevIndex and currentIndex values for
 * array that does not currently satisfy the ordering based on the current
 * array ordering.
 *
 * What is done is to increase the interval by one step and then check if
 * the edges of the interval satisfy the ordering. If it does not; recurse.
 *
 * Once the edges satisfy the ordering, the items in-between will be
 * granted new ordering values on a uniform linear scale between the edge items.
 *
 * If we reach the first item, we will set the first item's ordering to
 * a min value.
 *
 * If we reach the last item, we will set the last item's ordering to
 * a max value.
 */
function orderingUpdateRecursive<T extends OrderingInterface>(array: T[], prevIndex: number, currentIndex: number, dirty: Set<T>): Set<T> {
    let startIndex;
    let endIndex;
    let fullyExpanded = false;
    if (currentIndex < array.length - 1) {
        // increase interval one step by increasing end index
        startIndex = prevIndex;
        endIndex = currentIndex + 1;
    } else if (prevIndex > 0) {
        // increase interval one step by decreasing start index
        startIndex = prevIndex - 1;
        endIndex = currentIndex;
    } else {
        // Safeguard against endless recursion. In theory this would only
        // happen if you have MAX_ORDERING elements in your array.
        startIndex = prevIndex;
        endIndex = currentIndex;
        fullyExpanded = true;
    }

    const start = array[startIndex];
    const end = array[endIndex];
    if (startIndex === 0) {
        // start reached; set ordering min value
        if (start.ordering !== MIN_ORDERING) {
            start.ordering = MIN_ORDERING;
            dirty.add(start);
        }
    }
    if (endIndex === array.length - 1) {
        // end reached; set max ordering max value
        if (start.ordering !== MAX_ORDERING) {
            end.ordering = MAX_ORDERING;
            dirty.add(end);
        }
    }

    // Check if there are enough discrete steps in the interval to give
    // unique ordering integer values to each object, in order.
    if (!fullyExpanded && end.ordering - start.ordering < endIndex - startIndex) {
        // Edge items do not satisfy the ordering; recurse
        // (`dirty` will be re-created by the recursing function)
        return orderingUpdateRecursive(array, startIndex, endIndex, dirty);
    }

    // distribute ordering values between start and end
    const step = (end.ordering - start.ordering) / (endIndex - startIndex);
    for (let i = startIndex + 1, j = 1; i < endIndex; i++, j++) {
        const ordering = Math.floor(start.ordering + j * step);
        const current = array[i];
        if (current.ordering !== ordering) {
            current.ordering = ordering;
            dirty.add(current);
        }
    }

    return dirty;
}
