/** Ninja'd from https://github.com/ReactiveX/rxjs/blob/master/src/internal/util/isArray.ts */
export const isArray = Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number');

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(x: any): x is Function {
    return typeof x === 'function';
}

/** Ninja'd from https://github.com/ReactiveX/rxjs/blob/master/src/internal/util/isNumeric.ts */
export function isNumeric(val: any): val is number {
    // parseFloat NaNs numeric-cast false positives (null|true|false|"")
    // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
    // subtraction forces infinities to NaN
    // adding 1 corrects loss of precision from parseFloat (#15100)
    return !isArray(val) && (val - parseFloat(val) + 1) >= 0;
}

/**
 * Returns whether the provided value is a promise
 *
 * @param value Potential promise
 */
export function isPromise(value: any): value is PromiseLike<any> {
    return value !== undefined && typeof value === 'object' && value.then && typeof value.then === 'function';
}
