/**
 * Run the provided function until it returns a resolved Promise or the number
 * of tries is exhausted.
 *
 * @param func Function to run repeatedly. Must return a Promise.
 * @param retries Number of times to retry.
 * @param backoff Number of milliseconds to wait before first retry.
 * @param maxBackoff Maximum number of milliseconds to wait between retries.
 */
export function exponentialBackoff(
    func: () => Promise<any>,
    retries: number = 50,
    backoff: number = 100,
    maxBackoff: number = 3000
): Promise<any> {
    return new Promise((resolve, reject) => {
        exponentialBackoffRecursive(func, resolve, reject, retries, backoff, maxBackoff);
    });
}

function exponentialBackoffRecursive(
    func: () => Promise<any>,
    resolve: (value?: unknown) => void,
    reject: (reason?: unknown) => void,
    retries: number,
    backoff: number,
    maxBackoff: number
): void {
    func().then(resolve, error => {
        if (retries > 0) {
            setTimeout(() => {
                exponentialBackoffRecursive(func, resolve, reject, retries - 1, Math.min(backoff * 2, maxBackoff), maxBackoff);
            }, backoff);
        } else {
            reject(error);
        }
    });
}

/**
 * Run the provided function until it returns a resolved Promise, returns a
 * rejected Promise that fails the conditional retry function, or the number
 * of tries is exhausted.
 *
 * @param func Function to run repeatedly. Must return a Promise.
 * @param conditional Function to run for a rejected Promise. If this returns false we stop retrying.
 * @param retries Number of times to retry.
 * @param backoff Number of milliseconds to wait before first retry.
 * @param maxBackoff Maximum number of milliseconds to wait between retries.
 */
export function exponentialBackoffConditional(
    func: () => Promise<any>,
    conditional: (error: any) => boolean,
    retries: number = 50,
    backoff: number = 100,
    maxBackoff: number = 3000
): Promise<any> {
    return new Promise((resolve, reject) => {
        exponentialBackoffConditionalRecursive(func, conditional, resolve, reject, retries, backoff, maxBackoff);
    });
}

function exponentialBackoffConditionalRecursive(
    func: () => Promise<any>,
    conditional: (error: any) => boolean,
    resolve: (value?: unknown) => void,
    reject: (reason?: unknown) => void,
    retries: number,
    backoff: number,
    maxBackoff: number
): void {
    func().then(resolve, error => {
        if (retries <= 0 || !conditional(error)) {
            reject(error);
        } else {
            setTimeout(() => {
                // eslint-disable-next-line max-len
                exponentialBackoffConditionalRecursive(func, conditional, resolve, reject, retries - 1, Math.min(backoff * 2, maxBackoff), maxBackoff);
            }, backoff);
        }
    });
}
