export class TimeInterval {
    private static colonRegex = new RegExp(':');
    private static commaRegex = new RegExp(',');
    private static dashRegex = new RegExp('[-–]');
    public h0: number;
    public h1: number;
    public m0: number;
    public m1: number;

    public static create(h0: number, m0: number, h1: number, m1: number) {
        const o = new TimeInterval();
        o.h0 = h0;
        o.m0 = m0;
        o.h1 = h1;
        o.m1 = m1;
        return o;
    }

    public static fromString(s: string) {
        if (!s) {
            return;
        }
        const timeIntervals = s.split(TimeInterval.commaRegex);
        const result = [];
        for (const timeInterval of timeIntervals) {
            const startEndString = timeInterval.split(TimeInterval.dashRegex, 2);
            if (startEndString.length < 2) {continue;}
            const start = startEndString[0].split(TimeInterval.colonRegex, 2);
            if (start.length < 2) {continue;}
            const end = startEndString[1].split(TimeInterval.colonRegex, 2);
            if (end.length < 2) {continue;}
            const h0 = Number(start[0].trim());
            const m0 = Number(start[1].trim());
            const h1 = Number(end[0].trim());
            const m1 = Number(end[1].trim());
            result.push(TimeInterval.create(h0, m0, h1, m1));
        }
        return result;
    }

    /** Returns the number of minutes the shop will remain open or a negative
     * number (or 0) if it is closed */
    public static open(h: number, m: number, intervalsToday: TimeInterval[]): number {
        for (const interval of intervalsToday) {
            if (interval.h0 === h) {
                if (interval.h1 !== h) {
                    if (interval.m0 <= m) {
                        return interval.timeUntilEnd(h, m);
                    }
                } else { // interval opens and closes within the same hour
                    if (interval.m0 <= m && m < interval.m1) {
                        return interval.timeUntilEnd(h, m);
                    }
                }
            } else if (interval.h0 < h) {
                if (h < interval.h1) {
                    return interval.timeUntilEnd(h, m);
                } else if (h === interval.h1) {
                    if (m < interval.m1) {
                        return interval.timeUntilEnd(h, m);
                    }
                }
            }
        }
        // Not open. How long until it opens?
        let least = Number.MAX_VALUE;
        for (const interval of intervalsToday) {
            const left = interval.timeUntilStartToday(h, m);
            if (left < least) {
                least = left;
            }
        }
        return -least;
    }

    public timeUntilEnd(h: number, m: number): number {
        return 60 * (this.h1 - h) + this.m1 - m;
    }

    public timeUntilStartToday(h: number, m: number): number {
        if (h < this.h0) {
            return 60 * (this.h0 - h) + this.m0 - m;
        } else if (this.h0 === h && m < this.m0) {
            return this.m0 - m;
        } else {
            return Number.MAX_VALUE;
        }
    }
}
