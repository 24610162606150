export class Address {
    room?: string;
    street: string;
    postal: string;
    city: string;
    county: string;
    country: string;
    latitude: number;
    longitude: number;
}
