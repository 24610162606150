import {translateI18nField} from '../core/locale';

export class OrderServiceCharge {
    id: number;
    total: number;
    quantity: number;
    price: number;
    serviceChargeId: string;
    nameI18n: {[key: string]: any};
    orderItemItemIds: number[];
    status: ServiceChargeStatus;

    public get name() {
        return translateI18nField(this.nameI18n);
    }
    public set name(_: string) {
    }
}

export class OrderInputServiceCharge {
    total: number;
    quantity: number;
    serviceChargeId: string;
    nameI18n: {[key: string]: any};
    orderItemItemIds: number[];
    status: ServiceChargeStatus;

    public get name() {
        return translateI18nField(this.nameI18n);
    }
    public set name(_: string) {
    }
}

export type ServiceChargeStatus = 'CONFIRMED' | 'REFUNDED';
