import firebase from 'firebase/app';
import 'firebase/auth';
import {combineLatest, from, Observable, of} from 'rxjs';
import {distinctUntilChanged, switchMap, take} from 'rxjs/operators';
import {ApiError} from '../models';
import {PejAuthService} from '../services/auth';
import {exponentialBackoffConditional} from '../utils/promise';
import {PejFirebaseService} from './firebase';
type Auth = firebase.auth.Auth;
type User = firebase.User;

/** Create RxJs Observable from Firebase subscription */
function idTokenChangedObservable(auth: Auth): Observable<User> {
    return new Observable(subscriber => auth.onIdTokenChanged(subscriber));
}

export class PejFirebaseAuthServiceClass {
    private _firebaseAuth: Auth = undefined;

    public auth() {
        if (this._firebaseAuth === undefined) {
            this._firebaseAuth = firebase.auth(PejFirebaseService.app());
        }
        return this._firebaseAuth;
    }

    /** Observable of the currently logged in Firebase user. If the user is not
     *  already logged in, this will attempt to sign in using a custom token
     *  from our backend. Auth is persisted by the Firebase SDK.
     *
     *  If our backend session has expired, but PejAuthService.userId is still
     *  set, we will call PejAuthService.clear() to remove this outdated value.
     */
    get currentUser(): Observable<User | null> {
        const fbAuth = PejFirebaseAuthService.auth();
        return combineLatest([PejAuthService.userId, idTokenChangedObservable(fbAuth)])
            .pipe(
                switchMap(([pejUserId, _]) => {
                    const currentUserNullable = fbAuth.currentUser;
                    const currentUid = currentUserNullable != null ? currentUserNullable.uid : null;
                    if (currentUid != null) {
                        const userIdString = `${pejUserId}`;
                        if (currentUid !== userIdString) {
                            if (!currentUserNullable.isAnonymous) {
                                fbAuth.signOut();
                            }
                        } else {
                            return of(currentUserNullable);
                        }
                    }
                    if (!pejUserId) {
                        return of(null);
                    } else {
                        /* Sign in to Firebase using a custom token from our
                         * backend. We retry this with exponential backoff -
                         * unless our backend session has expired. */
                        const signInPromise = exponentialBackoffConditional(
                            () => PejAuthService.createFirebaseToken()
                                .then(auth => fbAuth.signInWithCustomToken(auth.token))
                                .then(authResult => authResult.user),
                            (error) => error?.key !== 'session_not_found');
                        const p = signInPromise
                            .catch(error => {
                                if (error?.key === 'session_not_found') {
                                    // We are actually logged out but
                                    // PejAuthService does not know it.
                                    PejAuthService.clear();
                                }
                                return null;
                            });
                        return from(p);
                    }
                }),
                distinctUntilChanged((x: User | null, y: User | null) => x?.uid === y?.uid)
            );
    }

    /** Tries to retrieve either currentUser or if that is null; an anonymous
     *  Firebase user. */
    get anonUser(): Observable<User> {
        return this.currentUser.pipe(switchMap(currentUser => {
            if (currentUser != null) {
                return of(currentUser);
            } else {
                const firebaseAuth = PejFirebaseAuthService.auth();
                const p = firebaseAuth.signInAnonymously().then(auth =>
                    auth.user
                ).catch(error => {
                    console.warn(error);
                    return null;
                });
                return from(p);
            }
        }));
    }

    get currentUserPromise(): Promise<User> {
        return this.currentUser.pipe(take(1)).toPromise().then(user => {
            if (user != null) {
                return user;
            } else {
                throw ApiError.create('no_firebase_auth', 'No Firebase auth');
            }
        });
    }
}


export const PejFirebaseAuthService = Object.seal(new PejFirebaseAuthServiceClass());
