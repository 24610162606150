import {ServingImage} from './shared/serving-image';

export class DigitalSignage {
    backgrounds: {[id: string]: ServingImage};
    channels: string[];
    headers: {[id: string]: string};
    images: DigitalSignageImage[];
    subheaders: {[id: string]: string};

    public static setDefaults(object: DigitalSignage) {
        if (object.backgrounds == null) {
            object.backgrounds = {};
        }
        if (object.channels == null) {
            object.channels = [];
        }
        if (object.channels.indexOf('default') === -1) {
            object.channels.unshift('default');
        }
        if (object.headers == null) {
            object.headers = {};
        }
        if (object.images == null) {
            object.images = [];
        } else {
            for (const image of object.images) {
                if (image.channels == null) {
                    image.channels = [];
                }
            }
        }
        if (object.subheaders == null) {
            object.subheaders = {};
        }
    }

    public trackingEquals(o: DigitalSignage) {
        return JSON.stringify(this) === JSON.stringify(o); // need to add timeUpdated
    }
}

export class DigitalSignageImage extends ServingImage {
    channels?: string[];
}
