export class ServingImage {
    height: number;
    host: string;
    objectName: string;
    objectNameCrop: string;
    url: string;
    urlCrop: string;
    width: number;

    constructor(source?) {
        if (source) {
            for (const prop in Object.keys(source)) {
                if (source.hasOwnProperty(prop)) {
                    this[prop] = source[prop];
                }
            }
        }
    }
}
