/**
 * Removes leading and ending specified characters from `string`.
 */
export function trim(str: string, chars: string): string {
    return trimEnd(trimStart(str, chars), chars);
}

/**
 * Removes ending specified characters from `string`.
 */
export function trimEnd(str: string, chars: string): string {
    let i = str.length - 1;
    for (; 0 <= i; i--) {
        const c = str.charAt(i);
        if (chars.indexOf(c) === -1) {
            break;
        }
    }
    return str.substring(0, i + 1);
}

/**
 * Removes leading specified characters from `string`.
 *
 * @param str The string to trim.
 * @param chars The characters to trim.
 * @returns Returns the trimmed string.
 * @example
 *
 * _.trimStart('-_-abc-_-', '_-');
 * // => 'abc-_-'
 */
export function trimStart(str: string, chars: string): string {
    let i = 0;
    for (; i < str.length; i++) {
        const c = str.charAt(i);
        if (chars.indexOf(c) === -1) {
            break;
        }
    }
    return str.substring(i);
}
