<div class="bodyCell">
  <div class="bodyInfo">
    <img src="/assets/icon/ic_lock_primary.svg" class="icon">
    <h1 *ngIf="action === 'reset_password'">{{'auth_in_app_001_reset_password_title' | translate}}</h1>
    <h1 *ngIf="action === 'confirm_reset_password'">{{'auth_in_app_002_chose_new_password_title' | translate}}</h1>
    <h1 *ngIf="action === 'login_one_time_password'"><a [href]="getSanitizedUrl()">{{'auth_in_app_003_log_in_with_link_title' | translate}}</a></h1>
    <h1 *ngIf="action === 'validate'">{{'auth_in_app_004_validate_email_title' | translate}}</h1>
    <p *ngIf="action === 'reset_password'">{{'auth_in_app_005_enter_your_email_p' | translate}}</p>
    <p *ngIf="action === 'confirm_reset_password'">{{'auth_in_app_006_enter_your_password_p1' | translate}} <strong>{{email}}</strong>. {{'auth_in_app_007_enter_your_password_p2' | translate}}</p>
    <div *ngIf="action === 'login_one_time_password'">
      <p>{{'auth_in_app_008_press_the_link_p1' | translate}} <a [href]="getSanitizedUrl()">{{'auth_in_app_009_press_the_link_p2' | translate}}</a> {{'auth_in_app_010_press_the_link_p3' | translate}}</p>
      <p>&nbsp;</p>
      <hr/>
      <p class="small">{{'auth_in_app_011_link_through_other_device_p' | translate}}</p>
      <pre>{{password}}</pre>
      <p class="small">{{'auth_in_app_012_code_works_four_hours_p' | translate}}</p>
    </div>
    <form *ngIf="action !== 'login_one_time_password' && action !== 'validate'" method="post">
      <input *ngIf="action === 'reset_password'" class="auth-input" type="email" name="email" placeholder="{{'auth_in_app_013_enter_your_email' | translate}}" [(ngModel)]="email">
      <input *ngIf="action === 'confirm_reset_password'" class="auth-input" type="password" name="password" placeholder="{{'auth_in_app_014_enter_a_new_password' | translate}}" [(ngModel)]="password">
      <input class="submit" type="submit" value="{{'auth_in_app_015_send' | translate}}" (click)="submit()">
    </form>
    <p *ngIf="success" class="formMessage formSuccess">{{'general_done' | translate}}</p>
    <p *ngIf="error" class="formMessage formError">{{error}}</p>
  </div>
</div>
