import {registerLocaleData} from '@angular/common';
import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {getQueryParam} from 'pej-sdk';
import {PejLocale} from 'pej-sdk/core/locale';

import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import localeFi from '@angular/common/locales/fi';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';

const LANGUAGES = ['en', 'sv', 'fi', 'nb', 'nl'];

@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnInit, OnDestroy {
    private _lang = new BehaviorSubject<string>(null);
    public get langObservable(): Observable<string> {
        return this._lang;
    }
    public get lang(): Readonly<string> {
        return this._lang.value;
    }
    public set lang(lang: Readonly<string>) {
        this._lang.next(lang);
    }
    private disposables: Subscription[] = [];
    private lastLang = '';

    constructor(private activatedRoute: ActivatedRoute,
                private translate: TranslateService) {}

    /** This should only be called from AppComponent constructor */
    public setup() {
        this.translate.addLangs(LANGUAGES);
        this.translate.setDefaultLang('en');
        const queryLangOnLoad = getQueryParam('lang');
        let startLang;
        if (queryLangOnLoad) { // query param overrides language
            startLang = queryLangOnLoad;
        } else {
            const loadLang = this.load();
            if (loadLang) {
                startLang = loadLang;
            } else {
                // Chrome reports English for my Windows 10 computer with Swedish language?
                const bestLang = this.findBestLang(LANGUAGES);
                startLang = bestLang;
            }
        }
        this.lang = startLang;
        this.setLang(this.lang); // must be called sync to setup everything

        const langServiceDisposable = this.langObservable.subscribe(lang => {
            this.setLang(lang);
        });
        this.disposables.push(langServiceDisposable);

        const langParamDisposable = this.activatedRoute.queryParamMap.subscribe(queryParamMap => {
            const lang = queryParamMap.get('lang');
            if (lang != null) {
                this.lang = lang;
            }
        });
        this.disposables.push(langParamDisposable);
    }

    public ngOnInit(): void {
        // this is never called!
    }

    public ngOnDestroy(): void {
        this.disposables.forEach(subscription => subscription.unsubscribe());
        this.disposables.length = 0;
    }

    private load(): string {
        if (localStorage == null) { return null; }
        try {
            return localStorage.getItem('selected_language');
        } catch (e) {
            console.error(e?.message);
            return null;
        }
    }

    public store(lang: Readonly<string>) {
        this.lang = lang;
        if (localStorage == null) { return null; }
        try {
            localStorage.setItem('selected_language', lang);
        } catch (e) {
            console.error(e?.message);
            return null;
        }
    }

    private findBestLang(langs: string[]): string {
        const navLangs = navigator.languages;
        if (navLangs == null) {
            const navLang = navigator.language || (navigator as any).userLanguage;
            if (navLang) {
                const navLangFirstPart = navLang.split('-')[0];
                for (const lang of langs) {
                    if (lang === navLangFirstPart) {
                        return lang;
                    }
                }
            }
            return 'en';
        }
        // English gets higher priority in Chrome for whatever reason, so try other languages first
        for (const lang of langs) {
            if (lang === 'en') {
                continue;
            }
            for (const navLang of navLangs) {
                const navLangFirstPart = navLang.split('-')[0];
                if (lang === navLangFirstPart) {
                    return lang;
                }
            }
        }
        return 'en';
    }

    public setLang(lang: string) {
        const safeLang = LANGUAGES.includes(lang) ? lang : 'en';
        if (safeLang === this.lastLang) {
            return;
        }
        if (safeLang === 'en') {
            registerLocaleData(localeEn);
        } else if (safeLang === 'sv') {
            registerLocaleData(localeSv);
        } else if (safeLang === 'fi') {
            registerLocaleData(localeFi);
        } else if (safeLang === 'nb') {
            registerLocaleData(localeNb);
        } else if (safeLang === 'nl') {
            registerLocaleData(localeNl);
        }
        this.translate.use(safeLang);
        PejLocale.lang = safeLang;
        this.lastLang = safeLang;
    }
}
