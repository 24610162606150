import {BehaviorSubject, Observable} from 'rxjs';

export function getI18nField(obj: {[key: string]: any}, fieldNameI18nMap: string, fieldNameString: string, lang?: string) {
    if (obj[fieldNameI18nMap] != null) {
        if (lang && obj[fieldNameI18nMap][lang]) {
            return obj[fieldNameI18nMap][lang];
        } else if (obj[fieldNameI18nMap].en) {
            return obj[fieldNameI18nMap].en;
        }
    }
    if (obj[fieldNameString]) {
        return obj[fieldNameString];
    } else {
        return undefined;
    }
}

export function translateI18nField(i18nMap: {[key: string]: string}) {
    if (i18nMap != null) {
        const lang = PejLocale.lang;
        if (lang && i18nMap[lang]) {
            return i18nMap[lang];
        } else {
            return i18nMap['en'];
        }
    }
}

export function translateI18nFieldWithBackup(i18nMap: {[key: string]: string}, backup: string) {
    if (i18nMap != null) {
        const lang = PejLocale.lang;
        if (lang && i18nMap[lang]) {
            return i18nMap[lang];
        } else if (i18nMap['en']) {
            return i18nMap['en'];
        }
    }
    return backup;
}

export class PejLocaleClass {
    private _lang = new BehaviorSubject<Readonly<string>>(undefined);
    public get langObservable(): Observable<Readonly<string>> { return this._lang; }
    public get lang(): Readonly<string> { return this._lang.value; }
    public set lang(lang: Readonly<string>) { this._lang.next(lang); }
}


export const PejLocale = Object.seal(new PejLocaleClass());
