import slugify from 'slugify';
import {trim} from './string';

export function cleanId(string: string) {
    return trim(slugify(string, {replacement: ' ', remove: /[,/?&+()'"=<>]/g}), ' ');
}

export function slugifiedId(string: string) {
    return trim(slugify(string, {lower: true}), '-');
}
