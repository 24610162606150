import {DateTime} from 'luxon';
import {translateI18nField, translateI18nFieldWithBackup} from '../core/locale';
import {arrayRemoveAll} from '../utils/array';
import {mapArray, mapObject} from '../utils/object-mapper';
import {PejAccount} from './accounting';
import {Category} from './category';
import {FsgmConfig} from './fsgm-config';
import {PlaceSpec} from './place-spec';
import {Address, GeoPt, ServingImage, TimeInterval} from './shared';
import {FirestoreObjectNumericId} from './shared/firestore-object';
import {TrackingInterface} from './shared/tracking-interface';

// @dynamic
export class Shop implements FirestoreObjectNumericId {
    accounts: PejAccount[];
    active: boolean;
    address: Address;
    admin: boolean;
    /** From https://stripe.com/docs/api/cards/object#card_object-brand
     *
     * Possible options are: American Express, Diners Club, Discover, JCB,
     * MasterCard, UnionPay, Visa, or Unknown
     *
     * For merchants in European countries the only options are: American Express,
     * MasterCard, Visa
     *
     * Note: In the Stripe client SDK's differently formatted values for card
     * brands are used. */
    allowedCardTypes: string[];
    authorization: string[];
    backgroundImage: ServingImage;
    bankAccount: BankAccount;
    blogPost: string;
    consents: {[id: string]: ShopConsent};
    _countryCode: string;
    coverImage: ServingImage;
    currencies: string[];
    _deliveryDescription: string;
    deliveryDescriptionI18n: {[id: string]: string};
    deliveryOptions: DeliveryOptionsEnum[];
    description: string;
    discoverySettings: DiscoverySettings;
    feeFlat: number;
    feeFlatAmex: number;
    feePercent: number;
    feePercentAmex: number;
    fsgmConfig: FsgmConfig;
    id: number;
    image: ServingImage;
    infoEmail: string;
    infoFacebook: string;
    infoPhone: string;
    infoWebsite: string;
    internalName: string;
    inspirationalImages: ServingImage[];
    integrations: IntegrationEnum [];
    itemTags: string[];
    languages: string[];
    legalEntity: LegalEntity;
    legalPersons: ShopRepresentative[];
    listImage: ServingImage;
    location: GeoPt;
    messageConfirmed: {[id: string]: string};
    messageConfirmedWithSelfServe: {[id: string]: string};
    messagePackaged: {[id: string]: string};
    /** Unvalidated JSON metadata attached to the object */
    metadata: {
        [key: string]: any;
    };
    _name: string;
    nameI18n: {[id: string]: string};
    nextPageToken: string;
    orderOptions: OrderOptionsEnum[];
    paymentOptions: string[];
    payoutSchedule: PayoutSchedule;
    payoutStatementDescriptor: string;
    posMap: {[id: string]: ShopPos};
    public: boolean;
    refundPeriod: number;
    representative: ShopRepresentative;
    services: string[];
    smsSenderName: string;
    statementDescriptor: string;
    storefrontCount: number;
    stripeChargesEnabled: boolean;
    stripeConnectId: string;
    stripePayoutsEnabled: boolean;
    swishMerchantNumber: string;
    tagline: string;
    taglineDisabledI18n: {[id: string]: string};
    taglineI18n: {[id: string]: string};
    terms: {[id: string]: string};
    ticketmaster: TicketmasterShop;
    timeCreated: number;
    timeUpdated: number;
    _timeZone: string;
    type: string;
    uniqueName: string;
    welcomeText: string;
    welcomeTextDisabledI18n: {[id: string]: string};
    welcomeTextI18n: {[id: string]: string};
    vippsMerchantSerialNumber: number;

    fromCache: boolean;

    public get countryCode() {
        if (this._countryCode) { return this._countryCode; }
        switch (this.address?.country) {
            case 'FI':
            case 'Suomi':
            case 'Finland':
                return 'FI';
            case 'GB':
            case 'United Kingdom':
                return 'GB';
            case 'NO':
            case 'Norge':
            case 'Norway':
                return 'NO';
            case 'SE':
            case 'Sverige':
            case 'Sweden':
                return 'SE';
            default:
                return this.address?.country ?? '';
        }
    }
    public set countryCode(s: string) {
        this._countryCode = s;
    }

    public get deliveryDescription() {
        return translateI18nFieldWithBackup(this.deliveryDescriptionI18n, this._deliveryDescription);
    }
    public set deliveryDescription(deliveryDescription: string) {
        this._deliveryDescription = deliveryDescription;
    }

    private _hooks: Hook[];
    public get hooks(): Hook[] {
        return this._hooks;
    }
    public set hooks(list: Hook[]) {
        this._hooks = mapArray(list, Hook);
    }

    public get name() {
        return translateI18nFieldWithBackup(this.nameI18n, this._name);
    }
    public set name(name: string) {
        this._name = name;
    }

    private _schedulingSettings: SchedulingSettings;
    public get schedulingSettings(): SchedulingSettings {
        return this._schedulingSettings;
    }
    public set schedulingSettings(obj: SchedulingSettings) {
        this._schedulingSettings = mapObject(obj, SchedulingSettings);
    }

    private _storefronts: Category[];
    public get storefronts(): Category[] {
        return this._storefronts;
    }
    public set storefronts(list: Category[]) {
        this._storefronts = mapArray(list, Category);
    }

    private _timeIntervals: ShopTimeInterval[];
    public get timeIntervals(): ShopTimeInterval[] {
        return this._timeIntervals;
    }
    public set timeIntervals(list: ShopTimeInterval[]) {
        this._timeIntervals = mapArray(list, ShopTimeInterval);
    }

    public get timeZone(): string {
        if (this._timeZone) { return this._timeZone; }
        switch (this.countryCode) {
            case 'FI':
                return 'Europe/Helsinki';
            case 'GB':
                return 'Europe/London';
            case 'NO':
                return 'Europe/Oslo';
            case 'SE':
                return 'Europe/Stockholm';
            default:
                return 'Europe/Stockholm';
        }
    }
    public set timeZone(s: string) {
        this._timeZone = s;
    }

    public static findStorefront(shop, id) {
        return shop.storefronts.find((sf) => sf.id === id);
    }

    public getName(lang?: string) {
        if (this.nameI18n != null) {
            if (lang && this.nameI18n[lang]) {
                return this.nameI18n[lang];
            }
            if (this.nameI18n.en) {
                return this.nameI18n.en;
            }
        }
        return this.name;
    }

    public getPosList(): ShopPos[] {
        const ls = [];
        if (this.posMap != null) {
            for (const key of Object.getOwnPropertyNames(this.posMap)) {
                const row = this.posMap[key];
                row.id = key;
                ls.push(row);
            }
        }
        return ls;
    }

    public getTagline(lang?: string) {
        if (this.taglineI18n != null) {
            if (lang && this.taglineI18n[lang]) {
                return this.taglineI18n[lang];
            }
            if (this.taglineI18n.en != null) {
                return this.taglineI18n.en;
            }
        }
        if (this.tagline) {
            return this.tagline;
        }
        return '';
    }

    public getTaglineDisabled(lang?: string) {
        if (this.taglineDisabledI18n != null) {
            if (lang && this.taglineDisabledI18n[lang]) {
                return this.taglineDisabledI18n[lang];
            }
            if (this.taglineDisabledI18n.en != null) {
                return this.taglineDisabledI18n.en;
            }
        }
        return this.getTagline(lang);
    }

    public getWelcomeText(lang?: string) {
        if (this.welcomeTextI18n != null) {
            if (lang && this.welcomeTextI18n[lang]) {
                return this.welcomeTextI18n[lang];
            }
            if (this.welcomeTextI18n.en) {
                return this.welcomeTextI18n.en;
            }
        }
        if (this.welcomeText) {
            return this.welcomeText;
        }
        return `Välkommen till ${this.getName(lang)}`;
    }

    public get welcomeTextDisabled(): string {
        return translateI18nField(this.welcomeTextDisabledI18n);
    }

    public hasAllowedCardType(option) {
        return this.allowedCardTypes?.indexOf(option) !== -1 ?? true;
    }

    public hasAuthorization(service) {
        return this.authorization && this.authorization.indexOf(service) !== -1;
    }

    public hasConsent(key) {
        return this.consents && this.consents[key] != null;
    }

    public hasDeliveryOption(option) {
        return this.deliveryOptions && this.deliveryOptions.indexOf(option) !== -1;
    }

    public hasOrderOption(option) {
        return this.orderOptions && this.orderOptions.indexOf(option) !== -1;
    }

    public hasPaymentOption(option) {
        return this.paymentOptions && this.paymentOptions.indexOf(option) !== -1;
    }

    public hasService(service) {
        return this.services && this.services.indexOf(service) !== -1;
    }

    public isComplete() {
        // Uncomment when shop create is ready
        // return this.name != null && (this.storefronts != null && this.storefronts.length > 0) && this.infoEmail;
        return true;
    }

    public setAllowedCardType(option, enabled) {
        if (this.allowedCardTypes === undefined) {
            this.allowedCardTypes = ['American Express', 'MasterCard', 'Visa'];
        }
        if (enabled) {
            this.allowedCardTypes.push(option);
        } else {
            arrayRemoveAll(this.allowedCardTypes, option);
        }
    }

    public setAuthorization(service, enabled) {
        if (this.authorization === undefined) {
            this.authorization = [];
        }
        if (enabled) {
            this.authorization.push(service);
        } else {
            arrayRemoveAll(this.authorization, service);
        }
    }

    public setConsent(key: string, value: boolean, source: string, metadata?: {[id: string]: any}) {
        if (this.consents == null) {
            this.consents = {};
        }
        if (value !== false) {
            const consent = new ShopConsent();
            consent.metadata = metadata;
            consent.source = source;
            this.consents[key] = consent;
        } else {
            delete this.consents[key];
        }
    }

    public setDefaults() {
        if (this.accounts == null) {
            this.accounts = [];
        }
        if (this.bankAccount == null) {
            this.bankAccount = new BankAccount();
            this.bankAccount.country = 'SE';
            this.bankAccount.currency = 'SEK';
        }
        if (this.deliveryDescriptionI18n == null) {
            this.deliveryDescriptionI18n = {en: this.deliveryDescription};
        }
        if (this.discoverySettings === undefined) {
            this.discoverySettings = new DiscoverySettings();
        }
        if (this.discoverySettings.wifiNetworks === undefined) {
            this.discoverySettings.wifiNetworks = [];
        }
        if (this.hooks == null) {
            this.hooks = [];
        }
        for (const hook of this.hooks) {
            hook.setDefaults();
        }
        if (this.address == null) {
            this.address = new Address();
        }
        if (this.legalEntity == null) {
            this.legalEntity = new LegalEntity();
        }
        LegalEntity.setDefaults(this.legalEntity);
        if (this.location == null) {
            this.location = new GeoPt();
        }
        if (this.messageConfirmed === undefined) {
            this.messageConfirmed = {};
        }
        if (this.messageConfirmedWithSelfServe === undefined) {
            this.messageConfirmedWithSelfServe = {};
        }
        if (this.messagePackaged === undefined) {
            this.messagePackaged = {};
        }
        if (this.nameI18n == null) {
            this.nameI18n = {en: this.name};
        }
        if (this.payoutSchedule == null) {
            this.payoutSchedule = new PayoutSchedule();
        }
        if (this.representative == null) {
            this.representative = new ShopRepresentative();
        }
        ShopRepresentative.setDefaults(this.representative);
        if (this.schedulingSettings == null) {
            this.schedulingSettings = new SchedulingSettings();
        }
        if (this.storefronts == null) {
            this.storefronts = [];
        }
        if (this.taglineDisabledI18n == null) {
            this.taglineDisabledI18n = {};
        }
        if (this.taglineI18n == null) {
            this.taglineI18n = {en: ''};
        }
        if (this.ticketmaster == null) {
            this.ticketmaster = new TicketmasterShop();
        }
        if (this.timeIntervals == null) {
            this.timeIntervals = [];
        } else {
            for (const timeInterval of this.timeIntervals) {
                if (timeInterval.week == null) {
                    timeInterval.week = new Week();
                }
            }
        }
        if (this.welcomeTextDisabledI18n == null) {
            this.welcomeTextDisabledI18n = {};
        }
        if (this.welcomeTextI18n == null) {
            this.welcomeTextI18n = {};
        }
        return this;
    }

    public setDeliveryOption(option, enabled) {
        if (this.deliveryOptions === undefined) {
            this.deliveryOptions = [];
        }
        if (enabled) {
            this.deliveryOptions.push(option);
        } else {
            arrayRemoveAll(this.deliveryOptions, option);
        }
    }

    public setFeeFlatFromDecimal(feeFlat: number) {
        this.feeFlat = feeFlat * 100;
    }

    public setFeePercentFromDecimal(feePercent: number) {
        this.feePercent = feePercent * 100;
    }

    public setOrderOption(option, enabled) {
        if (this.orderOptions === undefined) {
            this.orderOptions = [];
        }
        if (enabled) {
            this.orderOptions.push(option);
        } else {
            arrayRemoveAll(this.orderOptions, option);
        }
    }

    public setPaymentOption(option, enabled) {
        if (this.paymentOptions === undefined) {
            this.paymentOptions = [];
        }
        if (enabled) {
            this.paymentOptions.push(option);
        } else {
            arrayRemoveAll(this.paymentOptions, option);
        }
    }

    public setService(service, enabled) {
        if (this.services === undefined) {
            this.services = [];
        }
        if (enabled) {
            this.services.push(service);
        } else {
            arrayRemoveAll(this.services, service);
        }
    }

    setStringId(id: string): void {
        this.id = Number(id);
    }

    public trackingEquals(o: Shop) {
        return this.id === o.id && this.timeUpdated === o.timeUpdated;
    }
}

export class BankAccount {
    country: string;
    currency: string;
    iban: string;
    stripeId: string;
}

export class WifiNetwork {
    /** WiFi MAC addresses the client should look for. One of these must match the network MAC address. May be null;
     * then no check is performed. */
    macAddresses: string[];
    /** Signal strength level required. Should not be null. */
    requiredLevel: number;
    /** WiFi SSID the client should look for. This must match the network SSID. May be null; then no check is
     * performed. */
    ssid: string;
}

export class DiscoverySettings {
    /** When reported location distance from user to shop is lower than this number (in meters), the client should be
     * treated as being inside the Shop. */
    locationDistance: number;
    /** When reported location distance from user to shop is lower than this number (in meters), the client should be
     * able to pre-order from the Shop. */
    preOrderDistance: number;
    /** When reported location distance from user to shop is lower than this number (in meters), the client should start
     * matching WifiNetworks WiFi senders. */
    wifiDistance: number;
    wifiNetworks: WifiNetwork[];
}

export class Hook {
    event: HookEventEnum;
    headers: { [id: string]: string };
    lang: string;
    name: string;
    sections: string[];
    url: string;

    public setDefaults() {
        if (this.headers == null) {
            this.headers = {};
        }
        if (this.sections == null) {
            this.sections = [];
        }
    }
}

export class LegalEntity {
    address: Address;
    mcc: string;
    name: string;
    number: string;
    vatNumber: string;
    verification: ShopRepresentativeVerification;

    public static setDefaults(obj: LegalEntity) {
        if (obj.verification == null) {
            obj.verification = new ShopRepresentativeVerification();
        }
    }
}

export class PayoutSchedule {
    interval: string;
    monthlyAnchor: number;
    weeklyAnchor: number;
}

export class SchedulingSettings {
    services: string[];

    public hasService(service) {
        return this.services && this.services.indexOf(service) !== -1;
    }

    public setService(service, enabled) {
        if (this.services === undefined) {
            this.services = [];
        }
        if (enabled) {
            this.services.push(service);
        } else {
            arrayRemoveAll(this.services, service);
        }
    }
}

export class ShopRepresentative {
    address: Address;
    dateOfBirthDay: number;
    dateOfBirthMonth: number;
    dateOfBirthYear: number;
    director: boolean;
    email: string;
    executive: boolean;
    firstName: string;
    idNumber: string;
    lastName: string;
    owner: boolean;
    phone: string;
    provided: string[];
    status: string;
    title: string;
    verification: ShopRepresentativeVerification;

    public static setDefaults(obj: ShopRepresentative) {
        if (obj.address == null) {
            obj.address = new Address();
        }
        if (obj.verification == null) {
            obj.verification = new ShopRepresentativeVerification();
        }
    }
}

export class ShopRepresentativeVerification {
    objectName: string;
}

export class TicketmasterShop {
    venueId: string;
}

export class ShopConsent {
    ip: string;
    metadata: {[id: string]: any};
    source: string;
    timestamp: number;
    userId: number;
}

export class ShopEvent {
    coverImage: ServingImage;
    description: string;
    descriptionI18n: {[id: string]: string};
    id: string;
    name: string;
    nameI18n: {[id: string]: string};
    status: ShopEventStatus;
    taglineI18nOverride: {[id: string]: string};
    ticketmasterId: string;
    ticketmasterUrl: string;
    timeBegin: number;
    timeCreated: number;
    timeEnd: number;
    timeUpdated: number;
    welcomeTextI18nOverride: {[id: string]: string};

    public setDefaults() {
        if (this.descriptionI18n == null) {
            this.descriptionI18n = {};
        }
        if (this.nameI18n == null) {
            this.nameI18n = {};
        }
        if (this.taglineI18nOverride == null) {
            this.taglineI18nOverride = {};
        }
        if (this.welcomeTextI18nOverride == null) {
            this.welcomeTextI18nOverride = {};
        }
        return this;
    }
}

export class Places {
    shopId: number;
    private _places: PlaceSpec[];
    public get places(): PlaceSpec[] {
        return this._places;
    }

    public set places(list: PlaceSpec[]) {
        this._places = mapArray(list, PlaceSpec);
    }
}

export class PosSummary {
    amountByTaxRate: {[id: string]: number};
    amountByPaymentOption:  {[id: string]: number};
    canceledCount: number;
    canceledTotal: number;
    deliveryCount: number;
    deliveryTotal: number;
    finalized: boolean;
    grandTotal: number;
    itemCount: number;
    itemCountAfterRefunds: number;
    itemTotal: number;
    legalName: string;
    offerCount: number;
    offerTotal: number;
    offerTaxTotal: number;
    orgNumber: string;
    posId: string;
    receiptCopyCount: number;
    receiptCopyTotal: number;
    receiptCount: number;
    receiptNumber: number;
    refundedAmountByTaxRate: {[id: string]: number};
    refundedDeliveryCount: number;
    refundedDeliveryTotal: number;
    refundedGrandTotal: number;
    refundedItemCount: number;
    refundedItemTotal: number;
    refundedReceiptCount: number;
    refundedTotal: number;
    shopId: number;
    taxByTaxRate:  {[id: string]: number};
    timestamp: number;
    total: number;
    totalAfterRefunds: number;

    public amountByPaymentOptionKeys() {
        if (this.amountByPaymentOption == null) {
            return [];
        }
        const keys = Object.getOwnPropertyNames(this.amountByPaymentOption);
        return keys.sort((lhs: string, rhs: string) => Number(lhs) - Number(rhs));
    }

    public amountByTaxRateKeys() {
        if (this.amountByTaxRate == null) {
            return [];
        }
        const keys = Object.getOwnPropertyNames(this.amountByTaxRate);
        return keys.sort((lhs: string, rhs: string) => Number(lhs) - Number(rhs));
    }

    public refundedAmountByTaxRateKeys() {
        if (this.refundedAmountByTaxRate == null) {
            return [];
        }
        const keys = Object.getOwnPropertyNames(this.refundedAmountByTaxRate);
        return keys.sort((lhs: string, rhs: string) => Number(lhs) - Number(rhs));
    }

    public taxByTaxRateKeys() {
        if (this.taxByTaxRate == null) {
            return [];
        }
        const keys = Object.getOwnPropertyNames(this.taxByTaxRate);
        return keys.sort((lhs: string, rhs: string) => Number(lhs) - Number(rhs));
    }
}

export class ShopPos {
    public controlUnitSerial: string;
    public id: string;
    public productionNumber: string;
}

export class ShopTimeInterval {
    public id: string;

    private _week: Week;
    public get week(): Week {
        return this._week;
    }
    public set week(obj: Week) {
        this._week = mapObject(obj, Week);
    }
}

export class OpeningHourOverrides {
    public overrides: {[id: string]: string};
}

export class Week implements TrackingInterface<Week> {
    public mon: string;
    public tue: string;
    public wed: string;
    public thu: string;
    public fri: string;
    public sat: string;
    public sun: string;

    public checkOpen(datetime: DateTime): number {
        const h = datetime.hour;
        const m = datetime.minute;
        const threeLetterDayOfWeek = datetime.setLocale('en').toFormat('ccc').toLowerCase();
        const timeIntervalString = this[threeLetterDayOfWeek];
        const timeIntervals = TimeInterval.fromString(timeIntervalString) ?? [];
        return TimeInterval.open(h, m, timeIntervals);
    }

    public trackingEquals(o: Week): boolean {
        return this.mon === o.mon &&
            this.tue === o.tue &&
            this.wed === o.wed &&
            this.thu === o.thu &&
            this.fri === o.fri &&
            this.sat === o.sat &&
            this.sun === o.sun;
    }
}

export type DeliveryOptionsEnum = 'none' |
    'at_counter' |
    /** delivery using an external delivery service */
    'external_delivery' |
    /** "in_store slow" - expected response within the hour */
    'in_store' |
    /** Same expected response time as a physical customer would have in the store (~less than 5 minutes) */
    'in_store_fast' |
    /** Delivery within the same city */
    'intracity' |
    'self_serve' |
    'take_away' |
    'to_car' |
    /** As in_store_fast but the seller delivers the order to the customers table */
    'to_table';

export type HookEventEnum = 'order_item_update' | 'fsgm_post_order';

export type IntegrationEnum = 'SIMPHONY' | 'RES' | 'GLK' | 'WAVE';

export type MenuOptionItemTypeEnum = 'checkbox' | 'radio' | 'quantity';

export type OrderOptionsEnum = 'none' |
    /** Automatically confirms all orders for the Shop. Everything is always in stock. */
    'auto_confirm' |
    /** The Shop has a step after the order is confirmed (and packaged) before the order is handed over */
    'delivery_pickup_step' |
    /** The Shop has an extra packaging step after the order has been confirmed */
    'package_step';

export type ShopEventStatus =
    /** The event has started or is in the future. */
    'active' |
    /** The event is in the past. The event has been completed. */
    'completed' |
    /** timeStart and/or timeEnd has not been set. */
    'invalid';
