// source: https://stackoverflow.com/a/12830454
export function roundNumber(num: number, decimals: number): number {
    if (!('' + num).includes('e')) {
        return +(Math.round((num + 'e+' + decimals) as any) + 'e-' + decimals);
    } else {
        const arr = ('' + num).split('e');
        let sig = '';
        if (+arr[1] + decimals > 0) {
            sig = '+';
        }
        return +(Math.round((+arr[0] + 'e' + sig + (+arr[1] + decimals)) as any) + 'e-' + decimals);
    }
}
