import {Address} from '../models/shared/address';

/** Format address to user friendly string. Primarily used for delivery address */
export function formattedAddress(address: Address) {
    if(!address) {
        return '';
    }
    return `${address.street}${address.room ? ' (' + address.room + ')' : ''}, ${address.postal} ${address.city}`;
}

/** Checks if an address has all the required fields */
export function isValidAddress(address: Address) {
    return address && address.street && address.city && address.postal && address.country;
}
