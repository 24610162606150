import {HttpClientModule} from '@angular/common/http';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {from, Observable} from 'rxjs';
import * as Sentry from '@sentry/browser';
import {exponentialBackoff} from 'pej-sdk';
import {AppComponent} from './app.component';
import {AppState} from './app.service';
import {AuthInAppComponent} from './auth/auth-in-app.component';
import {CallbackMessageComponent} from './callback/callback-message.component';
import {CookieService} from 'ngx-cookie-service';
import {NavbarComponent} from './navbar.component';
import {NoContentComponent} from './no-content/no-content.component';
import {RedirectGuard} from './guards/redirect.guard';
import {SharedModule} from './shared/shared.module';
import {ROUTES} from './app.routes';

Sentry.init({
    dsn: 'https://6a9ca42443074fa7932182a7692de151@sentry.io/1407881'
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
    }

    handleError(error) {
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}

const APP_PROVIDERS = [
    AppState,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    CookieService,
    RedirectGuard,
];


export class TranslateHttpLoaderWithExponentialBackoff implements TranslateLoader {
    constructor(public prefix: string = '/assets/i18n/', public suffix: string = '.json') {}

    public getTranslation(lang: string): Observable<Object> {
        return from(exponentialBackoff(() => fetch(`${this.prefix}${lang}${this.suffix}`).then(response => response.json())));
    }
}

export function translateModuleUseFactory() {
  return new TranslateHttpLoaderWithExponentialBackoff('/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthInAppComponent,
    CallbackMessageComponent,
    NavbarComponent,
    NoContentComponent,
  ],

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES, {
            scrollPositionRestoration: 'enabled',
            useHash: Boolean(history.pushState) === false,
        }),
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateModuleUseFactory,
            },
        }),
    ],
    /**
     * Expose our Services and Providers into Angular's dependency injection.
     */
    providers: [
        APP_PROVIDERS,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
