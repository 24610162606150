export class PejConfigClass {
    private _apiKey: Readonly<string> = undefined;
    public get apiKey(): Readonly<string> { return this._apiKey; }
    public set apiKey(apiKey: Readonly<string>) { this._apiKey = apiKey; }

    private _rootUrl: Readonly<string> = undefined;
    public get rootUrl(): Readonly<string> { return this._rootUrl; }
    public set rootUrl(rootUrl: Readonly<string>) { this._rootUrl = rootUrl; }
}


export const PejConfig = Object.seal(new PejConfigClass());
