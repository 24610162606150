<div class="time-requested-selector-component">
  <div class="pej-card-layout time-selection animate__animated animate__fadeInDown animate__faster">
    <!-- Name -->
    <div *ngIf="name" class="mx-3 mt-3 mb-1">
      <p class="time-selection__name">{{ name }}</p>
    </div>

    <!-- Description -->
    <div *ngIf="description" class="mx-3">
      <p class="time-selection__description">{{ description }}</p>
    </div>

    <!-- Body -->
    <div class="mt-3 time-selection__header">
      <div class="d-flex align-items-center time-selection__button" (click)="changeSelectionView()" [ngClass]="{'time-selection__button--selected': layout === 'small' && selectLaterDate}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ic__date" viewBox="0 0 16 16">
            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z"/>
          </svg>
          <p class="time-selection__button__title time-selection__button__title--date">{{'time_requested_selector_select_date' | translate}}</p>
      </div>
      <div class="d-flex align-items-center time-selection__button" (click)="changeSelectionView()" [ngClass]="{'time-selection__button--selected': layout === 'small' && selectLaterTime}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="ic__time" viewBox="0 0 16 16">
            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
          </svg>
          <p class="time-selection__button__title time-selection__button__title--time">{{'time_requested_selector_select_time' | translate}}</p>
      </div>
    </div>
    <div class="time-selection__body">
      <div class="d-none">
        <input type="hidden" [matDatepicker]="matDatePicker" [min]="minDate" [max]="maxDate" (dateChange)="setDateFromCalendar($event.value.getTime())">
        <mat-datepicker touchUi #matDatePicker></mat-datepicker>
      </div>
      <div *ngIf="layout === 'large' || (layout === 'small' && selectLaterDate)" class="time-selection__picker time-selection__picker--date">
        <div *ngIf="showCalendar">
          <mat-calendar *ngIf="showCalendar" [selected]="selectedDate" (selectedChange)="setDateFromCalendar($event.getTime())" [minDate]="minDate" [maxDate]="maxDate" [startAt]="selectedDate"></mat-calendar>
          <div class="time-selection__calendar__hide-btn" (click)="toggleCalendar()"><p>{{'time_requested_selector_hide_calendar' | translate}}</p></div>
        </div>
        <mat-selection-list *ngIf="!showCalendar" id="date-selection-list" [multiple]="false" [ngModel]="selectedDateOption">
          <mat-list-option *ngFor="let dateOption of dateOptions" [value]="dateOption" (click)="setDate([dateOption])">
            <div class="d-flex justify-content-between align-items-center time-selection__date-option">
              <p class="time-selection__date-option__primary">{{dateOption.primaryLabel}}</p>
              <p class="time-selection__date-option__secondary">{{dateOption.secondaryLabel}}</p>  
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div *ngIf="layout === 'large' || (layout === 'small' && selectLaterTime)">
        <div *ngIf="shopOpen || (timeList && timeList.length > 0); else noAvailableTimes" class="time-selection__picker time-selection__picker--time" [class.calendar-open]="showCalendar">
          <mat-selection-list id="time-selection-list" [multiple]="false" [ngModel]="[selectedTime24HString]" (ngModelChange)="setTime($event[0])">
            <mat-list-option *ngIf="shopOpen" [value]="'0'">
              {{'time_requested_selector_asap' | translate}}
            </mat-list-option>
            <mat-list-option *ngFor="let time of timeList" [value]="time">
              {{time}}
            </mat-list-option>
          </mat-selection-list>
        </div>
        <ng-template #noAvailableTimes>
          <div class="p-3 time-selection__body__no_times">
            <p>{{'time_requested_selector_no_available_times' | translate}}</p>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- Footer -->
    <div *ngIf="shopOpen || (timeList && timeList.length > 0)" class="time-selection__footer">
      <div class="d-flex flex-column justify-content-center align-items-start time-selection__selected-time">
        <p class="time-selection__selected-time__title">{{'time_requested_selector_selected_time' | translate}}</p>
        <p class="time-selection__selected-time__value">{{selectedDateRelativeString}}{{selectedTimeString && ', ' + selectedTimeString}}</p>
      </div>
      <button *ngIf="selectedTimeString != null" (click)="continueSelected()" class="pej-button pej-button-yellow time-selection__button--next-lg">
        {{'general_select' | translate}}
      </button>
      <button *ngIf="selectLaterDate" (click)="changeSelectionView()" class="pej-button pej-button-gray time-selection__button--next-md">
        {{'general_next' | translate}}
      </button>
      <button *ngIf="selectLaterTime && selectedTimeString != null" (click)="continueSelected()" class="pej-button pej-button-yellow time-selection__button--next-md">
        {{'general_select' | translate}}
      </button>
    </div>
  </div>
</div>
