import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PejGroupWeb} from 'pej-sdk';
import {PejGroupFirestoreService} from 'pej-sdk/services/group-firestore';
import {getRecursiveParamSnapshot} from '../utils/router-utils';

export interface CustomCssResolved {
    type: string;
    id;
}

@Injectable({
    providedIn: 'root',
})
export class CustomCssGroupResolverService implements Resolve<CustomCssResolved> {
    constructor(protected activatedRoute: ActivatedRoute) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomCssResolved> | Observable<never> {
        const id = getRecursiveParamSnapshot(route, 'groupId');
        if (id) {
            return PejGroupFirestoreService.groupWeb(id)
                .pipe(take(1))
                .pipe(map((obj: PejGroupWeb) => {
                    if (obj?.css) {
                        return {'type': 'group', 'id': id};
                    } else {
                        return null;
                    }
                }));
        } else {
            return null;
        }
    }
}
