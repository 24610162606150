import firebase from 'firebase/app';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PejFirestoreService} from '../core/firestore';
import {Category, ContainerLayoutEnum, ServingImage} from '../models';
import {FirestoreMap} from '../models/shared/firestore-map';
import {firestoreQueryListener} from '../utils/firestore';
import {shareCached} from '../utils/rx';
import Query = firebase.firestore.Query;

export class CategoryFirestore { // XXX: ADD BACKEND
    coverImage: ServingImage;
    descriptionI18n: {[id: string]: string};
    id: number;
    layout: ContainerLayoutEnum;
    nameI18n: {[id: string]: string};
    shopId: number;
    timeCreated: number;
    timeUpdated: number;
}

export interface CategoryFirestoreSearchParams {
    shopId: number;
    placeId?: string;
    eventId?: string;
    timeInterval?: string;
}

function fsShopCategories(shopId: number): Query {
    return PejFirestoreService.firestore()
        .collection('shops')
        .doc(`${shopId}`)
        .collection('categories')
        .orderBy('ordering', 'asc');
}

export class PejCategoryFirestoreServiceClass {
    private observableCache = {};

    public forShop(shopId: number): Observable<FirestoreMap<Category>> {
        const path = `shops/${shopId}/categories`;
        return shareCached(this.observableCache, path, () =>
            firestoreQueryListener(fsShopCategories(shopId), Category)
                .pipe(map((it: FirestoreMap<Category>) => {
                    for (const obj of it.values()) {
                        obj.shopId = shopId;
                    }
                    return it;
                })));
    }
}


export const PejCategoryFirestoreService = Object.seal(new PejCategoryFirestoreServiceClass());
