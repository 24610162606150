import {arrayRemoveAll} from '../utils/array';
import {PejCardReader} from './shared/card-reader';

export class PejKioskProfile {
    public active: boolean;
    public id: string;
    public digitalSignageChannel: string;
    public paymentOptions: string[];
    public placeIds: string[];
    public posId: string;
    public printerIp: string;
    public printerDeviceType: number;
    public receiptOptions: string[];
    /** List of serial numbers that are allowed to connect to the kiosk. */
    public cardReaders: PejCardReader[];

    public hasPaymentOption(option: string) {
        return this.paymentOptions && this.paymentOptions.indexOf(option) !== -1;
    }

    public hasPlaceId(id: string) {
        return this.placeIds?.includes(id);
    }

    public hasReceiptOption(option: string) {
        return this.receiptOptions && this.receiptOptions.indexOf(option) !== -1;
    }

    public setPaymentOption(option: string, enabled: boolean) {
        if (this.paymentOptions === undefined) {
            this.paymentOptions = [];
        }
        if (enabled) {
            this.paymentOptions.push(option);
        } else {
            arrayRemoveAll(this.paymentOptions, option);
        }
    }

    public setPlaceId(id: string, enabled: boolean) {
        if (this.placeIds == null) {
            this.placeIds = [];
        }
        if (enabled) {
            this.placeIds.push(id);
        } else {
            arrayRemoveAll(this.placeIds, id);
        }
    }

    public setReceiptOption(option: string, enabled: boolean) {
        if (this.receiptOptions === undefined) {
            this.receiptOptions = [];
        }
        if (enabled) {
            this.receiptOptions.push(option);
        } else {
            arrayRemoveAll(this.receiptOptions, option);
        }
    }

    public setStringId(id: string) {
        this.id = id;
    }
}
