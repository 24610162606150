import {TrackingInterface} from '../models/shared/tracking-interface';

export function trackingEquals<T extends TrackingInterface<T>>(a: T, b: T) {
    if (a === b) { // object reference comparison
        return true;
    }
    if (a == null) {
        return b == null; // one null and one undefined would slip by first ===
    }
    if (b == null) {
        return false;
    }
    return a.trackingEquals(b);
}

export function trackingArrayEquals<T extends TrackingInterface<T>>(a: T[], b: T[]) {
    if (a === b) { // object reference comparison
        return true;
    }
    if (a == null) {
        return b == null; // one null and one undefined would slip by first ===
    }
    if (b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (!trackingEquals(a[i], b[i])) {
            return false;
        }
    }
    return true;
}

export function trackingMapEquals<T extends TrackingInterface<T>>(a: {[key: string]: T}, b: {[key: string]: T}) {
    if (a === b) { // object reference comparison
        return true;
    }
    if (a == null) {
        return b == null; // one null and one undefined would slip by first ===
    }
    if (b == null) {
        return false;
    }
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) {
        return false;
    }
    for (const k of aKeys) {
        const aValue = a[k];
        const bValue = b[k];
        // in cases of an undefined value, make sure the key
        // actually exists on the object so there are no false positives
        if (trackingEquals(aValue, bValue) || (a === undefined && !b.hasOwnProperty(k))) {
            return false;
        }
    }
    return true;
}
