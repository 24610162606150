import {translateI18nField} from '../core';

export class PejHelpQuestion {
    questionI18n: {[key: string]: string;}
    answerI18n: {[key: string]: string}
    rank: number;

    public get question(): string {
        return translateI18nField(this.questionI18n);
    }

    public set question(ignored){
    }

    public get answer(): string {
        return translateI18nField(this.answerI18n);
    }

    public set answer(ignored){
    }
}
