import {Address, ServingImage} from '.';

export class User {
    /** Last used address. This is stored so we can suggest the same address for
     * their next purchase. We only have this information if the if user has
     * made a purchase using a delivery service. */
    address: Address;
    /** Pej-global admin flag. */
    adminShopModerator: boolean;
    consents: {[id: string]: UserConsent};
    /** Date of birth. This is stored for merchants for know-your-customer
     * reasons. It is never entered for consumers. */
    dateOfBirth: string;
    /** The user's e-mail. This is used to auth the user. Indexing is needed for
     * finding user during auth and to help admins find users. */
    email: string;
    /** The user's first name. For consumers this is used to identify the person
     * when he/she has placed an order and to personalize e-mails. For merchants
     * this is stored for know-your-customer reasons. */
    firstName: string;
    /** The user's very own Pej ID. */
    id: number;
    /** An image that the user has uploaded of himself/herself. For consumers
     * this is optional information that can be used to identify the person when
     * he/she has placed an order. */
    image: ServingImage;
    /** The user's last name. For consumers this is optional information used to
     * identify the person when he/she has placed an order and to personalize
     * e-mails. For merchants this is stored for know-your-customer reasons. */
    lastName: string;
    /** The user's license plate. For consumers this is optional information used
     * to identify the person when he/she has placed an order. */
    licensePlate: string;
    /** The user's phone number entered when ordering something using a delivery
     * service. This is stored so we can suggest the same number for their next
     * purchase. We only have this information if the user has made a purchase
     * using delivery. */
    phone: string;
    /** A list of Pej Spot IDs that the user is administrating */
    shops: number[];
    timeCreated: number;
    timeUpdated: number;

    public formatName() {
        let name = '';
        if (this.firstName) {
            name = this.firstName;
        }
        if (this.lastName) {
            if (name) {
                name += ' ';
            }
            name += this.lastName;
        }
        return name;
    }

    public hasConsent(key) {
        return this.consents && this.consents[key] != null;
    }

    public setConsent(key: string, value: boolean, source: string, metadata?: {[id: string]: any}) {
        if (this.consents == null) {
            this.consents = {};
        }
        if (value !== false) {
            const consent = new UserConsent();
            consent.metadata = metadata;
            consent.source = source;
            this.consents[key] = consent;
        } else {
            delete this.consents[key];
        }
    }
}

export class UserCard {
    /** Card brand. Can be `American Express`, `Diners Club`, `Discover`, `JCB`,
     * `MasterCard`, `UnionPay`, `Visa`, or `Unknown`. */
    brand: string;
    /** Cardholder name. */
    cardHolder: string;
    /** Two-digit number representing the card’s expiration month. */
    expMonth: number;
    /** Four-digit number representing the card’s expiration year. */
    expYear: number;
    /** Unique Stripe-ID for the card. */
    id: string;
    /** The card's position in the list. The first card in the list is the
     * default selected in the app. This can be set in PUT to re-order the
     * cards. */
    index: number;
    /** The last four digits of the card. */
    last4: string;
    /** The user's selected name for this card. It may be useful for users who
     * have many cards to be able to name them. */
    name: string;
    /** 3D Secure support
     *
     * "required" - 3D Secure is required. The process must be completed for a
     * charge to be successful.
     *
     * "recommended" - 3D Secure is recommended. The process is not required,
     * but you can be covered by liability shift with a minimal impact to your
     * conversion rate.
     *
     * "optional" - 3D Secure is optional. The process isn’t required but can be
     * performed to help reduce the likelihood of fraud.
     *
     * "not_supported" - 3D Secure is not supported on this card. Proceed with a
     * regular card payment instead. */
    threeDSecure: ThreeDSecureEnum;
    /** True if 3D Secure has been verified for this card. */
    threeDSecureVerified: boolean;
    /** Stripe token set by the Stripe library in the app. It is used to create
     * a Stripe card on the user. Not saved in our database. Input only. */
    token: string;
    /** Always "stripe" for now. */
    type: string;
}

export class UserConsent {
    ip: string;
    metadata: {[id: string]: any};
    source: string;
    timestamp: number;
}

export type ThreeDSecureEnum = 'required' | 'recommended' | 'optional' | 'not_supported';
