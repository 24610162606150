import firebase from 'firebase/app';
import {DateTime} from 'luxon';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {PejFirebaseAuthService} from '../core/firebase-auth';
import {PejFirestoreService} from '../core/firestore';
import {FirestoreObject} from '../models';
import {PejAccounting} from '../models/accounting';
import {PejScheduledReport, PejReportRun, ReportSpecification} from '../models/scheduled-report';
import {FirestoreMap} from '../models/shared/firestore-map';
import {Shop, ShopTimeInterval} from '../models/shop';
import {PejShopInfo} from '../models/shop-info';
import {PejShopPrivate, PejShopPrivateAdmin} from '../models/shop-private';
import {PejShopWeb} from '../models/shop-web';
import {shareCached, unmapGetters} from '../utils';
import {firestoreDocumentListener, firestoreQueryListener} from '../utils/firestore';
import DocumentReference = firebase.firestore.DocumentReference;
import Query = firebase.firestore.Query;

/** Assembles a list of all active time interval keys, in the same order as
 * they are listed in Shop.timeIntervals with "default" added as a first
 * key  */
export function determineActiveTimeIntervals(
    timeIntervals: ShopTimeInterval[] | undefined,
    timeZone: string,
    timestamp: number | undefined
): string[] {
    const active: string[] = [];
    active.push('default');
    if (timeIntervals != null) {
        let cal = DateTime.local().setZone(timeZone);
        if (timestamp != null && timestamp !== 0) {
            cal = DateTime.fromMillis(timestamp).setZone(timeZone);
        }
        for (const timeInterval of timeIntervals) {
            if (timeInterval.week.checkOpen(cal) > 0) {
                active.push(timeInterval.id);
            }
        }
    }
    return active;
}

function fsAccounting(id: number): DocumentReference {
    return PejFirestoreService.firestore().collection('accounting').doc(`${id}`);
}

function fsScheduledReports(shopId: number): Query {
    return PejFirestoreService.firestore()
        .collection('shops-private').doc(`${shopId}`)
        .collection('scheduled-reports')
        .orderBy('ordering', 'asc');
}

function fsScheduledReport(shopId: number, reportId: string): DocumentReference {
    return PejFirestoreService.firestore()
        .collection('shops-private').doc(`${shopId}`)
        .collection('scheduled-reports')
        .doc(`${reportId}`);
}

function fsReports(shopId: number) {
    return PejFirestoreService.firestore()
        .collection('shops-private').doc(`${shopId}`)
        .collection('report-specs');
}

function fsReportsOrdered(shopId: number) {
    return fsReports(shopId).orderBy('created', 'desc');
}

function fsShop(id: number): DocumentReference {
    return PejFirestoreService.firestore().collection('shops').doc(`${id}`);
}

function fsShopInfo(id: number): DocumentReference {
    return PejFirestoreService.firestore().collection('shops-info').doc(`${id}`);
}

function fsShopPrivate(id: number): DocumentReference {
    return PejFirestoreService.firestore().collection('shops-private').doc(`${id}`);
}

function fsShopPrivateAdmin(shopId: number, userId: number): DocumentReference {
    return PejFirestoreService.firestore()
        .collection('shops-private').doc(`${shopId}`)
        .collection('admins').doc(`${userId}`);
}

function fsShopWeb(id: number): DocumentReference {
    return PejFirestoreService.firestore().collection('shops-web').doc(`${id}`);
}

export class PejShopFirestoreServiceClass {
    private observableCache: { [id: string]: Observable<any> } = {};

    public accounting(id: number): Observable<PejAccounting> {
        const path = `accounting/${id}`;
        return shareCached(this.observableCache, path, () =>
            PejFirestoreService.authedFirestoreObservable().pipe(switchMap(() =>
                firestoreDocumentListener(fsAccounting(id), PejAccounting))));
    }

    public accountingOnline(id: number): Observable<PejAccounting> {
        return this.accounting(id).pipe(filter(obj => !obj?.fromCache));
    }

    public accountingPatch(id: number, body: Partial<PejAccounting>): Promise<void> {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsAccounting(id).set(body, {merge: true}));
    }

    public shop(id: number): Observable<Shop> {
        const path = `shops/${id}`;
        return shareCached(this.observableCache, path, () =>
            firestoreDocumentListener(fsShop(id), Shop));
    }

    public shopOnline(id: number): Observable<Shop> {
        return this.shop(id).pipe(filter(obj => !obj?.fromCache));
    }

    public shopInfo(id: number): Observable<PejShopInfo> {
        const path = `shops-info/${id}`;
        return shareCached(this.observableCache, path, () =>
            firestoreDocumentListener(fsShopInfo(id), PejShopInfo));
    }

    public shopInfoOnline(id: number): Observable<PejShopInfo> {
        return this.shopInfo(id).pipe(filter(obj => !obj?.fromCache));
    }

    public shopInfoPatch(id: number, body: Partial<PejShopInfo>): Promise<void> {
        const unmappedBody = unmapGetters(body);
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsShopInfo(id).set(unmappedBody, {merge: true}));
    }

    public shopPrivate(id: number): Observable<PejShopPrivate> {
        const path = `shops-private/${id}`;
        return shareCached(this.observableCache, path, () =>
            PejFirestoreService.authedFirestoreObservable().pipe(switchMap(() =>
                firestoreDocumentListener(fsShopPrivate(id), PejShopPrivate))));
    }

    public shopPrivateAdmin(shopId: number, userId: number): Observable<PejShopPrivateAdmin> {
        const path = `shops-private/${shopId}/admins/${userId}`;
        return shareCached(this.observableCache, path, () =>
            PejFirestoreService.authedFirestoreObservable().pipe(switchMap(() =>
                firestoreDocumentListener(fsShopPrivateAdmin(shopId, userId), PejShopPrivateAdmin))));
    }

    public shopPrivateOnline(id: number): Observable<PejShopPrivate> {
        return this.shopPrivate(id).pipe(filter(obj => !obj?.fromCache));
    }

    public shopPrivatePatch(id: number, body: Partial<PejShopPrivate>): Promise<void> {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsShopPrivate(id).set(body, {merge: true}));
    }

    public scheduledReportPatch(shopId: number, reportId: string, body: Partial<PejScheduledReport>) {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsScheduledReport(shopId, reportId).set(body, {merge: true}));
    }

    public scheduledReportDelete(shopId: number, reportId: string) {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsScheduledReport(shopId, reportId).delete());
    }

    public scheduledReports(shopId: number): Observable<FirestoreMap<PejScheduledReport>> {
        const cachePath = `shops-private/${shopId}/scheduled-reports`;
        return shareCached(this.observableCache, cachePath, () =>
            PejFirestoreService.authedFirestoreObservable()
                .pipe(switchMap(() =>
                    firestoreQueryListener(fsScheduledReports(shopId), PejScheduledReport))
                ));
    }

    public reportAdd(shopId: number, body: Partial<ReportSpecification>) {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsReports(shopId).add(body));
    }

    public reports(shopId: number): Observable<FirestoreMap<PejReportRun>> {
        const cachePath = `shops-private/${shopId}/report-specs`;
        return shareCached(this.observableCache, cachePath, () =>
            PejFirestoreService.authedFirestoreObservable()
                .pipe(switchMap(() =>
                    firestoreQueryListener(fsReports(shopId), (obj => obj) as unknown as (new () => (PejReportRun & FirestoreObject))))
                ));
    }

    public shopWeb(id: number): Observable<PejShopWeb> {
        const path = `shops-web/${id}`;
        return shareCached(this.observableCache, path, () =>
            firestoreDocumentListener(fsShopWeb(id), PejShopWeb));
    }

    public shopWebOnline(id: number): Observable<PejShopWeb> {
        return this.shopWeb(id).pipe(filter(obj => !obj?.fromCache));
    }

    public shopWebPatch(id: number, body: Partial<PejShopWeb>): Promise<void> {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsShopWeb(id).set(body, {merge: true}));
    }
}


export const PejShopFirestoreService = Object.seal(new PejShopFirestoreServiceClass());
