import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

import {ApiError, AuthInput, AuthResponse} from 'pej-sdk';
import {PejAuthService} from 'pej-sdk/services/auth';

// URL:s to test
// http://localhost:8080/auth-in-app?action=login_one_time_password&email=marcushenrik%40gmail.com&password=madeUpPassword
// http://localhost:8080/auth-in-app?action=reset_password
// http://localhost:8080/auth-in-app?action=confirm_reset_password&email=marcushenrik%40gmail.com&key=madeUpKey

@Component({
  selector: 'auth-in-app',
  styleUrls: ['./auth-in-app.component.scss'],
  templateUrl: './auth-in-app.component.html',
})
export class AuthInAppComponent implements OnDestroy, OnInit {
  public action: string;
  public email: string;
  public error: string;
  public key: string;
  public success: string;
  public password: string;
  private queryParamsHandle: Subscription;

  constructor(private activatedRoute: ActivatedRoute,
              private sanitizer: DomSanitizer) {
  }

  public ngOnInit() {
    this.queryParamsHandle = this.activatedRoute.queryParams.subscribe((params) => {
      this.action = params['action'];
      this.email = params['email'];
      this.key = params['key'];
      this.password = params['password'];
      this.setup();
    });
  }

  ngOnDestroy() {
    this.queryParamsHandle.unsubscribe();
  }

  public getSanitizedUrl() {
    // pej://auth?action=login_one_time_password&email={{email}}&password={{password}}
    const url = "pej://auth?action=" + this.action + "&email=" + this.email + "&password=" + this.password;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public submit() {
    this.error = undefined;
    this.success = undefined;
    const input = new AuthInput();
    input.action = this.action;
    input.email = this.email;
    input.key = this.key;
    input.password = this.password;
    return PejAuthService.authPost(input).then((r: AuthResponse) => {
      this.success = "Klart";
    }).catch((e: ApiError) => {
      this.error = e.message;
    });
  }

  private setup() {
    if (this.action === "validate") {
      const input = new AuthInput();
      input.action = "validate";
      input.email = this.email;
      input.key = this.key;
        PejAuthService.authPost(input).then((r: AuthResponse) => {
        this.success = "Klart";
      }).catch((e: ApiError) => {
        this.error = e.message;
      });
    }
  }
}
