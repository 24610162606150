import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";


@Component({
  selector: 'callback-message',
  styleUrls: ['callback-message.component.scss'],
  templateUrl: 'callback-message.component.html',
})
export class CallbackMessageComponent implements OnDestroy, OnInit {
  public body: string;
  public header: string;
  private queryParamsHandle: Subscription;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  public ngOnInit() {
    this.queryParamsHandle = this.activatedRoute.queryParams.subscribe((params) => {
      this.body = params['body'];
      this.header = params['header'];
    });
  }

  ngOnDestroy() {
    this.queryParamsHandle.unsubscribe();
  }
}
