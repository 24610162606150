<div *ngIf="navbarType === 'homeLight' || navbarType === 'user'" class="navbar-container">
  <div class="navbar">
    <a routerLink="/" (click)="booleanValue = false">
      <img src="/assets/logo/pej-logo-white.svg" alt="Pej" height="30" width="54">
    </a>
    <ng-container *ngIf="navbarType === 'user'">
    <div class="d-none d-sm-none d-md-block">
      <div class="navbar-items">
        <a class="navbar-item" routerLink="/user" queryParamsHandling="preserve" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'web_general_personal_data' | translate}}</a>
        <a class="navbar-item" routerLink="/user/contact" queryParamsHandling="preserve" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{'web_general_contact' | translate}}</a>
        <a class="navbar-item" *ngIf="isLoggedIn()" (click)="logout()">{{'web_general_log_out' | translate}}</a>
      </div>
    </div>
    <div class="d-block d-md-none">
      <a (click)="booleanValue = !booleanValue">
        <img src="/assets/icon/ic_menu-white.svg" alt="Menu" height="30" width="39">
      </a>
    </div>
    </ng-container>
  </div>
</div>

<div *ngIf="booleanValue" @showMobileMenu class="mobile-menu">
  <ng-container *ngIf="navbarType === 'user'">
    <div class="mobile-menu-header">
      <a (click)="booleanValue = !booleanValue">
        <img src="/assets/icon/ic_close-light.svg" alt="Menu" height="42" width="42">
      </a>
    </div>
    <div class="mobile-menu-items">
      <a class="navbar-item" routerLink="/user" queryParamsHandling="preserve" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="booleanValue = !booleanValue">{{'web_general_personal_data' | translate}}</a>
      <a class="navbar-item" routerLink="/user/contact" queryParamsHandling="preserve" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="booleanValue = !booleanValue">{{'web_general_contact' | translate}}</a>
      <a class="navbar-item" *ngIf="isLoggedIn()" (click)="logout()">{{'web_general_log_out' | translate}}</a>
    </div>
  </ng-container>
</div>
