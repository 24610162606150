import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, EMPTY, of, combineLatest} from 'rxjs';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {isNumeric, Shop} from 'pej-sdk';
import {shopRxService} from 'pej-sdk/services/shop-rx';
import {PejCategoryFirestoreService} from 'pej-sdk/services/category-firestore';
import {PejItemFirestoreService} from 'pej-sdk/services/item-firestore';
import {PejMenuOptionFirestoreService} from 'pej-sdk/services/menu-option-firestore';
import {PejOfferFirestoreService} from 'pej-sdk/services/offer-firestore';
import {PejPlaceFirestoreService} from 'pej-sdk/services/place-firestore';
import {PejShopFirestoreService} from 'pej-sdk/services/shop-firestore';
import {getRecursiveParamSnapshot} from '../utils/router-utils';

export interface ShopResolved {
    id: number; // always numeric!
}

@Injectable({
    providedIn: 'root',
})
export class ShopResolverService implements Resolve<ShopResolved> {
    constructor(protected activatedRoute: ActivatedRoute) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ShopResolved> | Observable<never> {
        const paramId = getRecursiveParamSnapshot(route, 'shopId');
        if (paramId) {
            // support custom URLs: get the numeric shop ID
            let shopIdNumericObservable;
            if (isNumeric(paramId)) {
                shopIdNumericObservable = of(Number(paramId));
            } else {
                shopIdNumericObservable = shopRxService.getAnon(paramId)
                    .pipe(catchError((error) => {
                        if (error.key === 'resource_not_found') {
                            return EMPTY;
                        }
                    }))
                    .pipe(take(1))
                    .pipe(map((shop: Shop) => shop.id));
            }
            const combinedObservable = shopIdNumericObservable.pipe(switchMap((id: number) => {
                const observables = [
                    PejShopFirestoreService.shop(id),
                    PejShopFirestoreService.shopInfo(id),
                    PejPlaceFirestoreService.forShop(id),
                    shopRxService.getOpeningHours(id).pipe(take(1)),
                    PejCategoryFirestoreService.forShop(id),
                    PejItemFirestoreService.forShop(id),
                    PejMenuOptionFirestoreService.forShop(id),
                    PejOfferFirestoreService.myShopOffers(id),
                ];
                return combineLatest(observables);
            }));
            /* Add a background listener to keep the connections alive
             * and to not experience undesirable behavior by observables
             * disconnecting and reconnecting because there are no
             * listeners in between route changes. */
            const backgroundDisposable = combinedObservable.subscribe();
            /* Keep track of if shopId in param has changed. */
            const backgroundIntervalId = setInterval(() => {
                const intervalRoute = this.activatedRoute.snapshot;
                const intervalParamId = getRecursiveParamSnapshot(intervalRoute, 'shopId');
                if (paramId !== intervalParamId) {
                    clearInterval(backgroundIntervalId);
                    backgroundDisposable.unsubscribe();
                }
            }, 60000);

            /* We do not actually pass the objects here. Instead we rely on
             * the services themselves. From resolve we can only resolve
             * observables once which would lead to stale data.
             *
             * Also all data will not be needed for the first page. */

            /* We proceed immediately without waiting for the observables.*/
            return shopIdNumericObservable.pipe(map(id => ({id})));
            /* We proceed once all data has been loaded.*/
            // return combinedObservable.pipe(take(1));
        } else {
            return EMPTY;
        }
    }
}
