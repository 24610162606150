import {mapArray, mapObject} from '../utils';
import {Item} from './item';
import {OrderItemOption} from './order-item-option';
import {OrderServiceCharge} from './order-service-charge';

export class OrderItem {
    currency: string;
    description: string;
    /** When ordering an Item with selected options, it may be configured that
     *  some of these options will be extracted as their own OrderItems. An
     *  example would be where the Item is a burger menu and one option is a
     *  drink. The drink can then be extracted as its own OrderItem so it can
     *  be handled separately.
     *
     *  This field holds a list of the OrderItem.ids for OrderItems that were
     *  extracted from this OrderItem. */
    extractedItemIds: number[];
    extractedItemPrices: number[];
    id: number;
    item: Item;
    itemKey: string;
    options: OrderItemOption[];
    originalQuantity: number;
    price: number;
    quantity: number;
    /** free text string describing the reason for rejecting this OrderItem in the Order */
    reason: string;
    serviceCharges: OrderServiceCharge[];
    serviceChargesTotal: number;
    status: OrderItemStatusEnum;
    taxRate: number;
    total: number;
    type: string;
    unit: string;

    public revive() {
        if (this.item) {
            this.item = mapObject(this.item, Item);
        }
        if (this.serviceCharges) {
            this.serviceCharges = mapArray(this.serviceCharges, OrderServiceCharge);
        }
    }
}

export type OrderItemStatusEnum = 'always_confirm' |
    'wait_confirm' |
    'confirmed' |
    'packaged' |
    'delivered' |
    'partially_refunded' |
    'refunded' |
    'shop_rejected';
