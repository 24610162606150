import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {capitalizeFirst, localizedRelativeDate, localizedTime, PejLocale, Shop} from 'pej-sdk';
import {TimeRequestedLogic} from './time-requested.logic';
import { Constants } from '../constants';
import {TranslateService} from '@ngx-translate/core';
import {MatDatepicker} from '@angular/material/datepicker';

type DateOptionType = 'day' | 'calendar'
type LayoutType = 'small' | 'large'

interface DateOption {
    primaryLabel: string;
    secondaryLabel?: string;
    day?: DateTime;
    type: DateOptionType;
};

@Component({
    selector: 'time-requested-selector',
    encapsulation: ViewEncapsulation.None,
    templateUrl: 'time-requested-selector.component.html',
})
export class TimeRequestedSelectorComponent implements OnInit, OnDestroy {
    public asapLabel: string;
    @Input() public continueAsapText: string;
    public name: string;
    public dateOptions: DateOption[];
    public description: string;
    public layout: LayoutType
    private logic: TimeRequestedLogic;
    public minDate: Date;
    @ViewChild('matDatePicker') matDatePicker : MatDatepicker<Date>;
    public maxDate: Date;
    @Input() public places: any[];
    public selectedDate: Date;
    public selectedDateOption: DateOption[];
    public showCalendar = false;
    public selectedDateRelativeString: string;
    public selectLater: boolean = false;
    public selectLaterDate: boolean = false;
    public selectLaterTime: boolean = true;
    public selectedTimeString: string;
    public selectedTime24HString: string;
    @Input() public shop: Readonly<Shop>;
    public showCalendarText: string;
    private subscriptions: Subscription[] = [];
    public timeList: string[] = [];
    public shopOpen: boolean;

    @Output() timeSelected = new EventEmitter<number>();

    constructor(private translateService: TranslateService) {
    }

    public ngOnInit() {
        this.logic = new TimeRequestedLogic(this.shop, this.places);
        this.name = this.places?.[this.places.length - 1]?.name;
        this.description = this.places?.[this.places.length - 1]?.description;
        const preorderDays = this.logic.preOrderDays;
        this.minDate = DateTime.local().toJSDate();
        this.layout = window.innerWidth < Constants.SCREEN_SM ? 'small' : 'large';
        if (preorderDays != null) {
            this.maxDate = DateTime.local().plus({'days': preorderDays}).toJSDate();
        } else {
            this.maxDate = null;
        }
        this.shopOpen = this.shop.active;
        this.subscriptions = [
            this.translateService.stream(['time_requested_selector_asap', 'time_requested_selector_show_calendar']).subscribe(s => {
                this.asapLabel = s['time_requested_selector_asap'];
                this.showCalendarText = s['time_requested_selector_show_calendar'];
            }),
            this.logic.dateList().subscribe(dateList => this.setDays(dateList)),
            this.logic.selectedDate.subscribe(selectedDate => {
                let newDate = selectedDate;
                if (!selectedDate) {
                    const asapDateOption = this.dateOptions[0];
                    newDate = asapDateOption.day.toMillis();
                    this.selectedDateOption = [asapDateOption];
                }
                this.selectedDate = new Date(newDate);
                this.selectedDateRelativeString = localizedRelativeDate(newDate);
            }),
            this.logic.timeList().subscribe(timeList => this.timeList = timeList),
            this.logic.selectedTime.subscribe(selectedTime => {
                if (selectedTime === 0) {
                    this.selectedTimeString = this.asapLabel;
                    this.selectedTime24HString = '0';
                    this.showCalendar = false;
                    return;
                }
                this.selectedTimeString = selectedTime != null ? localizedTime(selectedTime) : null;
                this.selectedTime24HString = selectedTime != null ? DateTime.fromMillis(selectedTime).toFormat('HH:mm') : null;
            }),
            this.logic.timeAdjusted.subscribe(adjusted => {
                if (adjusted) {
                    // allow time for the right list option to render
                    setTimeout(() => {
                        this.showSelectedTime();
                    }, 20);
                };
            }),
        ];
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }


    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth < Constants.SCREEN_SM) {
            if (this.layout !== 'small') {
                this.layout = 'small';
                if (this.showCalendar) {
                    this.showCalendar = false;
                };
            };
            return;
        };
        if (this.layout !== 'large') {
            this.layout = 'large';
        };
    }
    
    public changeSelectionView() {
        if(this.selectLaterDate) {
            this.selectLaterDate = false;
            this.selectLaterTime = true;
            setTimeout(() => {
                this.showSelectedTime();
            }, 20);
        } else {
            this.selectLaterDate = true;
            this.selectLaterTime = false;
            // Select the right date option when switching to date view
            if (this.selectedDate) {
                const selectedDateTime = DateTime.fromJSDate(this.selectedDate);
                for (const option of this.dateOptions) {
                    if (!option.day) {
                        continue;
                    };
                    if (selectedDateTime.hasSame(option.day, 'day') ) {
                        this.selectedDateOption = [option];
                        return;
                    };
                };
            };
        };
    }

    public setDate(value: DateOption[]) {
        const newOption = value?.[0];
        for (const option of this.dateOptions) {
            const match = option === newOption;
            if (match) {
                this.logic.setDate(option.day?.toMillis() || Date.now());
                if (option.type === 'calendar') {
                    this.toggleCalendar();
                };
            };
        };
    }

    public setDateFromCalendar(value) {
        this.logic.setDate(value);
        const calendarOption = this.dateOptions?.find((o) => o.type === 'calendar');
        calendarOption.day = DateTime.fromMillis(value).setLocale(PejLocale.lang);
        calendarOption.secondaryLabel = capitalizeFirst(calendarOption.day.toLocaleString({ weekday: 'short', month: 'long', day: '2-digit' }));
        return;
    }

    public setTime(value: string) {
        this.logic.setTime(value);
    }

    public continueSelected() {
        this.timeSelected.emit(this.logic.selectedTime.getValue());
    }

    public showSelectedTime() {
        const firstTime = this.selectedTime24HString === this.timeList?.[0]
        const target = firstTime ? '#time-selection-list .mat-list-option:first-child' : '#time-selection-list .mat-list-single-selected-option';
        document.querySelectorAll(target).forEach((e) => e.scrollIntoView({behavior: "smooth", inline: "nearest"}))
    }

    public setDays(dateList: number[]) {
        this.dateOptions = dateList.map((dateMillis) => {
            const day = DateTime.fromMillis(dateMillis).setLocale(PejLocale.lang);
            return {
                primaryLabel: day ? capitalizeFirst(day.setLocale(PejLocale.lang).toRelativeCalendar({ unit: 'days' })) : '',
                secondaryLabel: capitalizeFirst(day.toLocaleString({ weekday: 'short', month: 'long', day: '2-digit' })),
                day,
                type: 'day'
            };
        });
        this.dateOptions.push({
            primaryLabel: this.showCalendarText,
            type: 'calendar',
        });
        this.selectedDateOption = [this.dateOptions[0]];
    }

    public toggleCalendar() {
        const calendarOption = this.dateOptions?.find((o) => o.type === 'calendar');
        if (calendarOption) {
            this.selectedDateOption = [calendarOption];
        };
        if (this.layout === 'large') {
            this.showCalendar = !this.showCalendar;
        } else {
            this.matDatePicker.open();
        };
    }
}
