import {mapArray} from '../utils/object-mapper';
import {PejInfoLink} from './info-link';
import {FirestoreObjectStringId} from './shared/firestore-object';

export class PejShopInfo implements FirestoreObjectStringId {
    id: string;
    _footers: PejInfoLink[];
    public get footers(): PejInfoLink[] { return this._footers; }
    public set footers(array: PejInfoLink[]) { this._footers = mapArray(array, PejInfoLink); }
    _headers: PejInfoLink[];
    public get headers(): PejInfoLink[] { return this._headers; }
    public set headers(array: PejInfoLink[]) { this._headers = mapArray(array, PejInfoLink); }
    _links: PejInfoLink[];
    public get links(): PejInfoLink[] { return this._links; }
    public set links(array: PejInfoLink[]) { this._links = mapArray(array, PejInfoLink); }

    fromCache: boolean;
    setStringId(id: string): void {
        this.id = id;
    }
}
