import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {SWEDISH_DATE_FORMATS, SwedishDateAdapter} from './swedish-date-adapter';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        {provide: DateAdapter, useClass: SwedishDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: SWEDISH_DATE_FORMATS},
    ],
})
export class L10nModule {
}
