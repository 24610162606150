import {FirestoreObjectStringId} from './shared/firestore-object';

export class PejGroupPrivate implements FirestoreObjectStringId {
    id: string;
    languages: string[];
    overrideTemplates: string[];

    fromCache: boolean;
    setStringId(id: string): void { this.id = id; }
}

export class PejGroupWeb implements FirestoreObjectStringId {
    css: string;
    id: string;

    fromCache: boolean;
    setStringId(id: string): void { this.id = id; }
}
