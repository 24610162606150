import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {HeaderComponent} from '../header/header.component';
import {FullscreenSpinnerComponent} from './fullscreen-spinner.component';
import {TimeRequestedSelectorComponent} from './time-requested-selector.component'
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {L10nModule} from '../../../../pej-ng-shared/src/l10n/l10n.module';
import {MatListModule} from '@angular/material/list';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        MatInputModule,
        MatListModule,
        MatDatepickerModule,
        MatSelectModule,
        MatSlideToggleModule,
        L10nModule,
    ],
    declarations: [
        FullscreenSpinnerComponent,
        HeaderComponent,
        TimeRequestedSelectorComponent,
    ],
    exports: [
        FullscreenSpinnerComponent,
        HeaderComponent,
        TimeRequestedSelectorComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
        };
    }
}
