import {translateI18nFieldWithBackup} from '../core/locale';
import {mapObject} from '../utils';
import {ItemStatusEnum} from './item';
import {PosData} from './pos-data';
import {ServingImage} from './shared';

export class MenuOptionItem {
    id: string;
    ignore: boolean;
    images: ServingImage[];
    itemId: number;
    maxQuantity: number;
    nameI18n: {[id: string]: string};
    descriptionI18n: {[id: string]: string};
    posData: PosData;
    precheck: boolean | undefined;
    /** Legacy parameter */
    price: number | undefined;
    /** Price as fractions of currency. A value of 15000 (with Swedish currency) represents 150 SEK. */
    prices: {[id: string]: number} | undefined;
    shopId: number;
    status: ItemStatusEnum;
    timeUpdated: number;
    type: string;
    _name: string;
    _description: string;

    public revive() {
        if (this.posData) {
            this.posData = mapObject(this.posData, PosData);
        }
    }

    public get name(): string {
        return translateI18nFieldWithBackup(this.nameI18n, this._name);
    }

    public set name(string: string) {
        this._name = string;
    }

    public get description() {
        return translateI18nFieldWithBackup(
            this.descriptionI18n,
            this._description
        );
    }

    public set description(string) {
        this._description = string;
    }

    public getPriceForCurrency(currency: string) {
        if (this.prices != null) {
            return this.prices[currency];
        }
        return this.price;
    }

    public static setDefaults(obj) {
        if (obj.nameI18n == null) {
            obj.nameI18n = {};
        }

        if (obj.descriptionI18n == null) {
            obj.descriptionI18n = {};
        }

        return obj;
    }

    public trackingEquals(o: MenuOptionItem) {
        return this.id === o.id && this.timeUpdated === o.timeUpdated;
    }

    public validate() {
        const violations = [];
        if (!this.id) {
            violations.push('No id');
        }
        if (!this.nameI18n['en']) {
            violations.push('No name');
        }
        if (violations.length === 0) {
            return true;
        }
        return violations;
    }
}
