import {translateI18nFieldWithBackup} from '../core/locale';
import {mapArray} from '../utils/object-mapper';
import {trackingArrayEquals} from '../utils/tracking';
import {Item} from './item';
import {FirestoreObjectNumericId} from './shared/firestore-object';
import {ServingImage} from './shared/serving-image';
import {Shop} from './shop';

export class Category implements FirestoreObjectNumericId {
    categories: number[];
    coverImage: ServingImage;
    _description: string;
    descriptionI18n: {[id: string]: string};
    expire: number;
    id: number;
    image: ServingImage;
    itemCategories: number[];
    itemCount: number;
    itemTags: string[];
    layout: ContainerLayoutEnum;
    nameI18n: {[id: string]: string};
    nextPageToken: string;
    ordering: number;
    overviewImage: ServingImage;
    shop: Shop;
    shopId: number;
    storefrontId: number;
    timeCreated: number;
    timeUpdated: number;
    title: string;
    upsellTitleI18n: {[id: string]: string};
    _upsellTitle: string;
    private _items: Item[] = [];
    public get items(): Item[] {
        return this._items;
    }
    public set items(items: Item[]) {
        this._items = mapArray(items, Item);
    }

    public get description() {
        return translateI18nFieldWithBackup(this.descriptionI18n, this._description);
    }
    public set description(ignore) {
    }

    public get name() {
        return translateI18nFieldWithBackup(this.nameI18n, this.title);
    }
    public set name(ignored) {
    }

    public get upsellTitle() {
        return translateI18nFieldWithBackup(this.upsellTitleI18n, this._upsellTitle);
    }

    public set upsellTitle(ignore) {
    }

    public setDefaults() {
        if (this.categories === undefined) {
            this.categories = [];
        }
        if (this.descriptionI18n == null) {
            this.descriptionI18n = {};
            if (this.description != null) {
                this.descriptionI18n['en'] = this.description;
            }
        }
        if (this.itemCategories === undefined) {
            this.itemCategories = [];
        }
        if (this.itemTags === undefined) {
            this.itemTags = [];
        }
        if (this.layout == null) {
            this.layout = 'horizontal_scroll';
        }
        if (this.nameI18n == null) {
            this.nameI18n = {};
            if (this.title != null) {
                this.nameI18n['en'] = this.title;
            }
        }
        if (this.upsellTitleI18n == null) {
            this.upsellTitleI18n = {};
            if (this.upsellTitle != null) {
                this.upsellTitleI18n['en'] = this.upsellTitle;
            }
        }
    }

    public setStringId(id: string): void {
        this.id = Number(id);
    }

    public trackingEquals(o: Category) {
        return this.id === o.id && this.timeUpdated === o.timeUpdated && trackingArrayEquals(this.items, o.items);
    }
}

export type ContainerLayoutEnum = 'horizontal_scroll' |
    'popup_list' | 'parent' | 'upsell';
