import {PejConnectionService} from '../core/connection';
import {
    getFromCache,
    getTupleFromCache,
    queryAsPromise,
    queryCollectionResponse,
    queryCollectionResponseLimitCache,
    queryObject,
    removeFromCache,
    removeFromCollectionResponseInCache,
    removeFromMapInCache,
    setInCache,
    setInMapInCache,
    updateInArrayInCache,
    updateInCollectionResponseInCache,
} from '../core/query';
import {
    CollectionResponse,
    MenuOptionGroup,
    MenuOptionItem,
    Offer,
    OpeningHourOverrides,
    Places,
    PlaceSpec,
    PosSummary,
    Shop,
    ShopEvent,
    ShopEventPlace,
    ShopEventStatus,
    ValidationInput,
    ValidationResponse,
    WaveConfig,
    WaveJobStatus,
    Week,
} from '../models';
import {isPromise, mapArray, mapObject} from '../utils';
import {PejAuthService} from './auth';

const CACHE_SIZE = 100;

export const cacheKeyGet = (id) => `get:${id}`;
const cacheKeyEventsGet = (shopId, id) => `eventsGet/${shopId}/${id}`;
const cacheKeyEventsList = (shopId, status?) => status ? `eventsList/${shopId}/${status}` : `eventsList/${shopId}`;
const cacheKeyEventPlacesList = (shopId, id) => `eventPlacesList/${shopId}/${id}`;
const cacheKeyOffersList = (id) => `offersList/${id}`;
const cacheKeyOpeningHourOverridesGet = (id) => `openingHourOverridesGet/${id}`;
const cacheKeyOpeningHoursGet = (id) => `openingHoursGet/${id}`;
const cacheKeyPlacesList = (shopId) => `placesList/${shopId}`;

export interface VippsDetails {
    merchantSerialNumber: number;
    clientId: string;
    clientSecret: string;
    ocpApimSubscriptionKey: string;
}

export interface SearchClosestParams {
    term?: string;
    groups?: string | string[];
    services?: string | string[];
    anyservice?: string | string[];
    categories?: number | number[];
    tags?: string | string[];
    anyTag?: string | string[];
    lat?: number;
    lng?: number;
    range?: number;
}

export interface SearchParams {
    term?: string;
    areas?: string | string[];
    groups?: string | string[];
    services?: string | string[];
    anyservice?: string | string[];
    categories?: number | number[];
    tags?: string | string[];
    anyTag?: string | string[];
    lat?: number;
    lng?: number;
    range?: number;
}

export class PejShopServiceClass {
    private cache = {};
    private cacheAnon = {};
    private cacheAnonKeyList = [];

    constructor() {
        PejAuthService.userChanged.subscribe(() => {
            this.cache = {};
        });
    }

    private static eventPlacesListInner(
        methodName: string,
        cache,
        shopId: number,
        eventId: string,
        expire: number
    ): Promise<ShopEventPlace[]> {
        const caller = (etag) => PejConnectionService[methodName](`shops/${shopId}/events/${eventId}/places`, null, etag);
        const cacheKey = cacheKeyEventPlacesList(shopId, eventId);
        return queryAsPromise(queryObject<ShopEventPlace[]>(caller, cache, cacheKey, null, expire));
    }

    private static eventPlacesListMultiInner(
        methodName: string,
        cache,
        shopId: number,
        ids: string[],
        expire: number
    ): Promise<ShopEventPlace[][]> {
        if (ids.length === 0) {
            return Promise.resolve([]);
        } else if (ids.length === 1) {
            return PejShopServiceClass.eventPlacesListInner(methodName, cache, shopId, ids[0], expire).then((result) => [result]);
        }
        const idsCsv = ids.join(',');
        const caller = (etag) => PejConnectionService[methodName](`shops/${shopId}/events/${idsCsv}/places`, null, etag);
        const cacheKey = cacheKeyEventPlacesList(shopId, idsCsv);
        const p = queryObject<ShopEventPlace[][]>(caller, cache, cacheKey, null, expire);
        if (isPromise(p)) {
            return p.then((response: ShopEventPlace[][]) => {
                for (let i = 0; i < ids.length; i++) {
                    const id = ids[i];
                    const shopEventList = response[i];
                    setInCache(cache, cacheKeyEventPlacesList(shopId, id), shopEventList);
                }
                return response;
            });
        } else {
            return Promise.resolve(p);
        }
    }

    private static eventsListInner(
        methodName: string,
        cache,
        shopId: number,
        status: ShopEventStatus,
        limit: number,
        more: boolean,
        expire: number
    ): Promise<CollectionResponse<ShopEvent>> {
        const caller = (params, etag) => PejConnectionService[methodName](`shops/${shopId}/events`, params, etag);
        const cacheKey = cacheKeyEventsList(shopId, status);
        const mapper = a => mapArray(a, ShopEvent);
        const p = queryCollectionResponse<ShopEvent>(caller, {status}, cache, cacheKey, limit, more, mapper, expire);
        if (isPromise(p)) {
            return p.then((response: CollectionResponse<ShopEvent>) => {
                for (const item of response.items) {
                    item.setDefaults();
                    setInCache(cache, cacheKeyEventsGet(shopId, item.id), item);
                }
                return response;
            });
        } else {
            return Promise.resolve(p);
        }
    }

    public eventPlacesList(shopId: number, eventId: string, expire?: number): Promise<ShopEventPlace[]> {
        return PejShopServiceClass.eventPlacesListInner('get', this.cache, shopId, eventId, expire);
    }

    public eventPlacesListAnon(shopId: number, eventId: string, expire?: number): Promise<ShopEventPlace[]> {
        return PejShopServiceClass.eventPlacesListInner('getAnon', this.cacheAnon, shopId, eventId, expire);
    }

    public eventPlacesListMulti(shopId: number, eventIds: string[], expire?: number): Promise<ShopEventPlace[][]> {
        return PejShopServiceClass.eventPlacesListMultiInner('get', this.cache, shopId, eventIds, expire);
    }

    public eventPlacesPatch(shopId: number, eventId: string, input: ShopEventPlace): Promise<ShopEventPlace> {
        const placeId = input.placeId;
        const cacheKey = cacheKeyEventPlacesList(shopId, eventId);
        return PejConnectionService.patch(`shops/${shopId}/events/${eventId}/places/${placeId}`, input).then((response) => {
            const mapped = mapObject(response, ShopEventPlace);
            updateInArrayInCache(this.cache, cacheKey, mapped, (obj) => obj.placeId === mapped.placeId);
            return mapped;
        });
    }

    public eventsDelete(shopId: number, id: string): Promise<void> {
        return PejConnectionService.delete(`shops/${shopId}/events/${id}`).then(() => {
                removeFromCache(this.cache, cacheKeyEventsGet(shopId, id));
                removeFromCollectionResponseInCache(this.cache, cacheKeyEventsList(shopId), (obj: ShopEvent) => id === obj.id);
                removeFromCollectionResponseInCache(this.cache, cacheKeyEventsList(shopId, 'active'), (obj: ShopEvent) => id === obj.id);
            },
        );
    }

    public eventsGet(shopId: number, id: string, expire?: number): Promise<ShopEvent> {
        const caller = (etag) => PejConnectionService.get<ShopEvent>(`shops/${shopId}/events/${id}`, null, etag);
        const cacheKey = cacheKeyEventsGet(shopId, id);
        const mapper = o => mapObject(o, ShopEvent);
        return queryAsPromise(queryObject(caller, this.cache, cacheKey, mapper, expire));
    }

    public eventsGetAnon(shopId: number, id: string, expire?: number): Promise<ShopEvent> {
        const caller = (etag) => PejConnectionService.getAnon<ShopEvent>(`shops/${shopId}/events/${id}`, null, etag);
        const cacheKey = cacheKeyEventsGet(shopId, id);
        const mapper = o => mapObject(o, ShopEvent);
        return queryAsPromise(queryObject(caller, this.cacheAnon, cacheKey, mapper, expire));
    }

    public eventsList(
        shopId: number,
        status: ShopEventStatus,
        limit?: number,
        more?: boolean,
        expire?: number
    ): Promise<CollectionResponse<ShopEvent>> {
        return PejShopServiceClass.eventsListInner('get', this.cache, shopId, status, limit, more, expire);
    }

    public eventsListAnon(
        shopId: number,
        status: ShopEventStatus,
        limit?: number,
        more?: boolean,
        expire?: number
    ): Promise<CollectionResponse<ShopEvent>> {
        return PejShopServiceClass.eventsListInner('getAnon', this.cacheAnon, shopId, status, limit, more, expire);
    }

    public eventsPost(shopId: number, input: ShopEvent) {
        return PejConnectionService.post(`shops/${shopId}/events`, input).then((response) => {
            const mapped = mapObject(response, ShopEvent).setDefaults();
            const id = mapped.id;
            setInCache(this.cache, cacheKeyEventsGet(shopId, id), mapped);
            updateInCollectionResponseInCache(this.cache, cacheKeyEventsList(shopId, 'active'), mapped, (obj: ShopEvent) => id === obj.id);
            updateInCollectionResponseInCache(this.cache, cacheKeyEventsList(shopId), mapped, (obj: ShopEvent) => id === obj.id);
        });
    }

    public eventsPut(shopId: number, input: ShopEvent) {
        const id = input.id;
        return PejConnectionService.put(`shops/${shopId}/events/${id}`, input).then((response) => {
            const mapped = mapObject(response, ShopEvent).setDefaults();
            setInCache(this.cache, cacheKeyEventsGet(shopId, id), mapped);
            updateInCollectionResponseInCache(this.cache, cacheKeyEventsList(shopId, 'active'), mapped, (obj: ShopEvent) => id === obj.id);
            updateInCollectionResponseInCache(this.cache, cacheKeyEventsList(shopId), mapped, (obj: ShopEvent) => id === obj.id);
        });
    }

    public get(id: number | string, expire?: number): Promise<Readonly<Shop>> {
        const caller = (etag) => PejConnectionService.get<Shop>(`shops/${id}`, {fields: '**'}, etag);
        const cacheKey = cacheKeyGet(id);
        const mapper = o => mapObject(o, Shop);
        const p = queryObject(caller, this.cache, cacheKey, mapper, expire);
        if (isPromise(p)) {
            return (p as Promise<Shop>).then((response: Shop) => {
                const withDefaults = response.setDefaults();
                const tuple = getTupleFromCache(this.cacheAnon, cacheKey);
                if (tuple != null) {
                    if (id !== response.id) {
                        const cacheKeyNumeric = cacheKeyGet(response.id);
                        setInCache(this.cache, cacheKeyNumeric, withDefaults, tuple[2]);
                    }
                    if (response.uniqueName && id !== response.uniqueName) {
                        const cacheKeyString = cacheKeyGet(response.uniqueName);
                        setInCache(this.cache, cacheKeyString, withDefaults, tuple[2]);
                    }
                }
                return withDefaults;
            });
        } else {
            return Promise.resolve(p);
        }
    }

    public getAnon(id: number | string, expire?: number): Promise<Readonly<Shop>> {
        const caller = (etag) => PejConnectionService.getAnon<Shop>(`shops/${id}`, {fields: '**'}, etag);
        const cacheKey = cacheKeyGet(id);
        const mapper = o => mapObject(o, Shop);
        const p = queryObject(caller, this.cacheAnon, cacheKey, mapper, expire);
        if (isPromise(p)) {
            return (p as Promise<Shop>).then((response: Shop) => {
                const withDefaults = response.setDefaults();
                const tuple = getTupleFromCache(this.cacheAnon, cacheKey);
                if (tuple != null) {
                    if (id !== response.id) {
                        const cacheKeyNumeric = cacheKeyGet(response.id);
                        setInCache(this.cacheAnon, cacheKeyNumeric, withDefaults, tuple[2]);
                    }
                    if (response.uniqueName && id !== response.uniqueName) {
                        const cacheKeyString = cacheKeyGet(response.uniqueName);
                        setInCache(this.cacheAnon, cacheKeyString, withDefaults, tuple[2]);
                    }
                }
                return withDefaults;
            });
        } else {
            return Promise.resolve(p);
        }
    }

    /** get from cache if it exists */
    public getSync(id: number | string): Shop {
        return getFromCache(this.cache, cacheKeyGet(id));
    }

    public menuOptionGroupDelete(shopId: number, id: string) {
        const path = `shops/${shopId}/menu-option-groups/${id}`;
        return PejConnectionService.delete(path).then(() => {
            removeFromMapInCache(this.cache, path, id);
        });
    }

    public menuOptionGroupList(shopId: number, expire?: number): Promise<CollectionResponse<MenuOptionGroup>> {
        const path = `shops/${shopId}/menu-option-groups`;
        const caller = (etag) => PejConnectionService.get<CollectionResponse<MenuOptionGroup>>(path, null, etag);
        const mapper = a => mapArray(a, MenuOptionGroup);
        return queryAsPromise(queryCollectionResponse(caller, {}, this.cache, path, 1000, false, mapper, expire));
    }

    public menuOptionGroupPut(shopId: number, id: string, input: MenuOptionGroup) {
        const path = `shops/${shopId}/menu-option-groups/${id}`;
        return PejConnectionService.put(path, input).then(response => {
            const mapped = mapObject(response, MenuOptionGroup);
            const updatedId = mapped.id; // may have been slugified
            const updatedIdPath = `shops/${shopId}/menu-option-groups/${updatedId}`;
            setInMapInCache(this.cache, updatedIdPath, updatedId, mapped);
        });
    }

    public menuOptionItemPatch(shopId: number, menuOptionGroupId: string, id: string, input: Partial<MenuOptionItem>) {
        const path = `shops/${shopId}/menu-option-groups/${menuOptionGroupId}/${id}`;
        return PejConnectionService.patch<Partial<MenuOptionItem>>(path, input).then(response => {
            const mapped = mapObject(response, MenuOptionItem);
            // ignore cache update since we don't have a good way to invalidate a single menu option item
            return mapped;
        });
    }

    public offersDelete(shopId: number, id: number) {
        return PejConnectionService.delete(`offers/${id}`).then(() => {
            // use if we add offersGet()
            // removeFromCache(this.cache, cacheKeyOffersGet(shopId, id));
            removeFromCollectionResponseInCache(this.cache, cacheKeyOffersList(shopId), (obj: Offer) => id === obj.id);
        });
    }

    public offersList(shopId: number, limit?: number, more?: boolean, expire?: number) {
        const caller = (params, etag) => PejConnectionService.get<CollectionResponse<Offer>>(`shops/${shopId}/offers`, params, etag);
        const cacheKey = cacheKeyOffersList(shopId);
        const mapper = (v) => mapArray(v, Offer);
        const p = queryCollectionResponse<Offer>(caller, {}, this.cache, cacheKey, limit, more, mapper, expire);
        if (isPromise(p)) {
            return p.then((response: CollectionResponse<Offer>) => {
                for (const item of response.items) {
                    item.setDefaults();
                    // use if we add offersGet()
                    // setInCache(this.cache, cacheKeyOffersGet(shopId, item.id), item);
                }
                return response;
            });
        } else {
            return Promise.resolve(p);
        }
    }

    public offersPost(shopId: number, input: Offer) {
        return PejConnectionService.post(`offers`, input).then((response) => {
            const mapped = mapObject(response, Offer).setDefaults();
            updateInCollectionResponseInCache(this.cache, cacheKeyOffersList(shopId), mapped);
            return mapped;
        });
    }

    public offersPut(shopId: number, input: Offer) {
        const id = input.id;
        return PejConnectionService.put(`offers/${id}`, input).then((response) => {
            const mapped: Offer = mapObject(response, Offer).setDefaults();
            updateInCollectionResponseInCache(this.cache, cacheKeyOffersList(shopId), mapped, (obj: Offer) => id === obj.id);
            return mapped;
        });
    }

    public openingHourOverridesGet(id: number | string, expire?: number): Promise<OpeningHourOverrides> {
        const caller = (etag) => PejConnectionService.get<OpeningHourOverrides>(`shops/${id}/opening-hour-overrides`, null, etag);
        const mapper = o => mapObject(o, OpeningHourOverrides);
        const cacheKey = cacheKeyOpeningHourOverridesGet(id);
        return queryAsPromise(queryObject(caller, this.cache, cacheKey, mapper, expire));
    }

    public openingHourOverridesPatch(id: number | string, input: OpeningHourOverrides) {
        return PejConnectionService.patch(`shops/${id}/opening-hour-overrides`, input).then((response) => {
            const mapped = mapObject(response, OpeningHourOverrides);
            setInCache(this.cache, cacheKeyOpeningHourOverridesGet(id), mapped);
            return mapped;
        });
    }

    public openingHoursGet(id: number | string, expire?: number) {
        const caller = (etag) => PejConnectionService.get<Week>(`shops/${id}/opening-hours/0`, null, etag);
        const mapper = (v) => mapObject(v, Week);
        const cacheKey = cacheKeyOpeningHoursGet(id);
        return queryAsPromise(queryObject(caller, this.cache, cacheKey, mapper, expire));
    }

    public openingHoursGetNoOverride(id: number | string, expire?: number) {
        const caller = (etag) => PejConnectionService.get<Week>(`shops/${id}/opening-hours/0?override=false`, null, etag);
        const mapper = (v) => mapObject(v, Week);
        const cacheKey = cacheKeyOpeningHoursGet(id);
        return queryAsPromise(queryObject(caller, this.cache, cacheKey, mapper, expire));
    }

    public openingHoursPut(id: number, input: Week) {
        return PejConnectionService.put(`shops/${id}/opening-hours/0`, input).then((response) => {
            const mapped = mapObject(response, Week);
            setInCache(this.cache, cacheKeyOpeningHoursGet(id), mapped);
            return mapped;
        });
    }

    public placesDelete(shopId: number, id: string) {
        return PejConnectionService.delete(`shops/${shopId}/places/${id}`).then(() => {
            removeFromCache(this.cache, cacheKeyGet(shopId));
            removeFromCache(this.cacheAnon, cacheKeyGet(shopId));
        });
    }

    public placesList(shopId: number, expire?: number): Promise<Places> {
        const caller = (etag) => PejConnectionService.get<Places>(`shops/${shopId}/places`, null, etag);
        const cacheKey = cacheKeyPlacesList(shopId);
        const mapper = o => mapObject(o, Places);
        return queryAsPromise(queryObject<Places>(caller, this.cache, cacheKey, mapper, expire));
    }

    public placesPut(shopId: number, id: string, input: PlaceSpec) {
        return PejConnectionService.put(`shops/${shopId}/places/${id}`, input).then((response) => {
            const mapped = mapObject(response, PlaceSpec);
            removeFromCache(this.cache, cacheKeyGet(shopId));
            removeFromCache(this.cacheAnon, cacheKeyGet(shopId));
            return mapped;
        });
    }

    /** Finalizes the current summary as a Z-dagrapport. Returns all the
     *  information needed for the Z-dagrapport. Query parameter `force`
     *  will need to be set to true to generate an empty report. */
    public posSummaryFinalize(shopId: number, posId?: string, force?: boolean): Promise<PosSummary> {
        if (posId == null) {
            posId = 'None';
        }
        let url = `shops/${shopId}/pos/${posId}/summaries`;
        if (force) {
            url += '?force=true'
        }
        return PejConnectionService.post(url, {});
    }

    /** Get information for a previous Z-dagrapport by receipt number (or the
     *  current X-dagrapport with receiptNumber set to either the the next
     *  receipt number or 'current') */
    public posSummaryGet(shopId: number, posId?: string, receiptNumber?: number | 'current', expire?: number): Promise<PosSummary> {
        if (posId == null) {
            posId = 'None';
        }
        if (receiptNumber == null) {
            receiptNumber = 'current';
        }
        const caller = (etag) =>
            PejConnectionService.get<PosSummary>(`shops/${shopId}/pos/${posId}/summaries/${receiptNumber}`, null, etag);
        const cacheKey = `posSummaryGet:${shopId}/${posId}/${receiptNumber}`;
        const mapper = (v) => mapObject(v, PosSummary);
        return queryAsPromise(queryObject(caller, this.cache, cacheKey, mapper, expire));
    }

    public put(input: Shop): Promise<Readonly<Shop>> {
        const id = input.id;
        const cacheKey = cacheKeyGet(id);
        return PejConnectionService.put(`shops/${id}`, input).then((response) => {
            const mapped = mapObject(response, Shop);
            setInCache(this.cache, cacheKey, mapped);
            return mapped;
        });
    }

    public search(params: SearchParams, limit?: number, more?: boolean, expire?: number): Promise<Readonly<CollectionResponse<Shop>>> {
        const caller = (params2, etag) => PejConnectionService.getAnon<CollectionResponse<Shop>>(`shops/views/search`, params2, etag);
        const cacheKey = 'search:' + JSON.stringify(params);
        const mapper = a => mapArray(a, Shop);
        const q = queryCollectionResponseLimitCache(caller, params,
            this.cacheAnon, this.cacheAnonKeyList, cacheKey, limit,
            more, mapper, expire, CACHE_SIZE);
        return queryAsPromise(q);
    }

    public searchClosest(
        params: SearchClosestParams,
        limit?: number,
        more?: boolean,
        expire?: number
    ): Promise<Readonly<CollectionResponse<Shop>>> {
        const caller = (p, etag) => PejConnectionService.getAnon<CollectionResponse<Shop>>(`shops/views/search-closest`, p, etag);
        const cacheKey = 'searchClosest:' + JSON.stringify(params);
        const mapper = a => mapArray(a, Shop);
        const q = queryCollectionResponseLimitCache(caller, params,
            this.cacheAnon, this.cacheAnonKeyList, cacheKey, limit,
            more, mapper, expire, CACHE_SIZE);
        return queryAsPromise(q);
    }

    public setActive(id: number) {
        return PejConnectionService.post(`shops/${id}/actions/set-active`, {});
    }

    public setInactive(id: number) {
        return PejConnectionService.post(`shops/${id}/actions/set-inactive`, {});
    }

    public stripeConnect(id: number) {
        return PejConnectionService.post(`shops/${id}/actions/connect-to-stripe`, {});
    }

    public stripeCreateConnectUrl(id: number) {
        return PejConnectionService.get(`shops/${id}/actions/stripe-create-connect-url`, {}, null).then(response => response.json);
    }

    public stripeRequirements(id: number) {
        return PejConnectionService.get(`shops/${id}/stripe-requirements`, {}, null);
    }

    public connectVipps(shopId: number, vippsDetails: VippsDetails) {
        return PejConnectionService.post(`shops/${shopId}/vipps-connect`, vippsDetails, null);
    }

    public connectNets(shopId: number, netsSecretKey: {'secretKey': string}) {
        return PejConnectionService.post(`shops/${shopId}/nets-connect`, netsSecretKey, null);   
    }

    public createWaveConfig(shopId: number, waveConfig: Partial<WaveConfig>) {
        return PejConnectionService.post<WaveConfig>(`shops/${shopId}/wave/config`, waveConfig, null);
    }

    public getWaveConfig(shopId: number) {
        return PejConnectionService.get<WaveConfig>(`shops/${shopId}/wave/config`, {}, null).then(response => response.json);
    }

    public setPosClients(shopId: number, wavePosClients: { [key: number]: number }) {
        return PejConnectionService.post<{ [key: number]: number }>(`shops/${shopId}/wave/pos-config`, wavePosClients, null);
    }

    public getWaveJobStatues(shopId: number): Promise<WaveJobStatus[]> {
        return PejConnectionService.get<WaveJobStatus[]>(`shops/${shopId}/wave/jobs`, {}, null).then(response => response.json);
    }

    public triggerRestore(shopId: number): Promise<WaveJobStatus> {
        return PejConnectionService.post<WaveJobStatus>(`shops/${shopId}/wave/trigger-restore`, {}, null);
    }


    public validate(shopId: number, validationInput: ValidationInput): Promise<ValidationResponse> {
        return PejConnectionService.post<ValidationResponse>(`shops/${shopId}/actions/validate`, validationInput, null);
    }
}


export const PejShopService = Object.seal(new PejShopServiceClass());
/** @deprecated Use PejShopService */
export const shopService = PejShopService;
