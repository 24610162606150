import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TranslationResolverService implements Resolve<boolean> {
    constructor(private translateService: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Observable<never> {
        return this.translateService.getTranslation(this.translateService.currentLang)
            .pipe(take(1));
    }
}
