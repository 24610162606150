import {translateI18nField} from '../core/locale';
import {arrayRemoveAll} from '../utils/array';
import {iteratorFilter} from '../utils/iterator';
import {mapArray} from '../utils/object-mapper';
import {DeliveryPrice} from './delivery-price';
import {PejGuideStep} from './guide-step';
import {PejHelpQuestion} from './help-question';
import {PejInfoLink} from './info-link';
import {PejOrderCustomData} from './order-custom-data';
import {FirestoreObjectStringId} from './shared/firestore-object';
import {ServingImage} from './shared/serving-image';
import {DeliveryOptionsEnum} from './shop';

export class PlaceSpec implements FirestoreObjectStringId {
    /**
     * Time offset for displaying of orders, for orders made through oracle integrations.
     */
    oracleCheckOffsetTime: number;
    /** List of PlaceSpecs by ID that should be listed as children to this
     * PlaceSpec.
     *
     * If this is set the range* fields are ignored. */
    children: string[];
    /** An image that is shown as a cover image for this place when it is
     * displayed as a card (in the kiosk layout).
     *
     * It will be displayed as 264 x 180 dp. To support a pixel density of 4
     * you could upload it as 1056 x 720 px.
     *
     * Transparency is supported.
     *
     * Upload information:
     * Landscape mode. 22:15 aspect ratio.
     * 1056 x 720 px max.
     * 10 MB max. */
    coverImage: ServingImage;
    /** Delivery service provider ID. Must be set iff
     *  option=external_delivery. */
    deliveryService: string;
    /** I18n description dictionary to display for status "enabled". */
    descriptionI18n: {[key: string]: string};
    /** I18n description dictionary to display for status "disabled". */
    disabledDescriptionI18n: {[key: string]: string};

    private _guideInfoLinks: PejInfoLink[];
    public get guideInfoLinks(): PejInfoLink[] { return this._guideInfoLinks; }
    public set guideInfoLinks(array: PejInfoLink[]) { this._guideInfoLinks = mapArray(array, PejInfoLink); }

    /** I18n steps for guide page */
    private _scanAndPayGuide: PejGuideStep[];
    public get scanAndPayGuide():  PejGuideStep[] { return this._scanAndPayGuide; }
    public set scanAndPayGuide(array: PejGuideStep[]) { this._scanAndPayGuide = mapArray(array, PejGuideStep);}

    /** I18n question & answer */
    private _scanAndPayHelp: PejHelpQuestion[];
    public get scanAndPayHelp(): PejHelpQuestion[] {return this._scanAndPayHelp; }
    public set scanAndPayHelp(array: PejHelpQuestion[]) {this._scanAndPayHelp = mapArray(array, PejHelpQuestion);}


    private _checkoutInfoLinks: PejOrderCustomData[];
    public get checkoutInfoLinks(): PejOrderCustomData[] { return this._checkoutInfoLinks; }
    public set checkoutInfoLinks(array: PejOrderCustomData[]) { this._checkoutInfoLinks = mapArray(array, PejOrderCustomData); }

    /** An image that is shown as an icon for this place when it is
     * displayed as an option on the web.
     *
     * Transparency is supported.
     *
     * Upload information:
     * Square. 1:1 aspect ratio.
     * 1080x1080 px max.
     * 10 MB max. */
    iconImage: ServingImage;
    /** IDs must be unique within the Shop. */
    id: string;
    /** An image that is displayed as a background when selecting among
     * this place's children in a kiosk in landscape mode.
     *
     * It makes the most sense to upload this in the size that your
     * kiosk screen uses. (This is probably 1920 x 1080.)
     *
     * Upload information:
     * Landscape mode. 16:9 aspect ratio.
     * 3840 x 2160 px max.
     * 10 MB max. */
    landscapeImage: ServingImage;

    /** I18n description dictionary to display for menu title. */
    menuTitleI18n: {[key: string]: string};
    /** I18n description dictionary to display for menu description. */
    menuDescriptionI18n: {[key: string]: string};

    /** I18n name dictionary. */
    nameI18n: {[key: string]: string};

    nestedCategories: boolean;
    /** The orderInput.deliveryOption that should be set when a point in the
     * group is selected. Can potentially be overridden in children. */
    option: DeliveryOptionsEnum;
    ordering: number;

    private _orderInfoLinks: PejInfoLink[];
    public get orderInfoLinks(): PejInfoLink[] { return this._orderInfoLinks; }
    public set orderInfoLinks(array: PejInfoLink[]) { this._orderInfoLinks = mapArray(array, PejInfoLink); }

    /** Orders to this place should be displayed with the selected layout.
     *
     * qr: Order view shows a QR code and an animated gradient background
     * standard: The default Order view */
    orderLayout: string;
    orderPlacedMessageI18n: {[id: string]: string};
    overrideDeliveryFeeTaxRate: number;
    paymentOptions: string[];
    /** How many days ahead of time you are allowed to pre-order to this place.
     * Only useful if services contains `pre_orders`. */
    preOrderDays: number;
    /** Defines the cost of delivery. It is possible to define different
     * costs for different order totals and delivery distances.
     *
     * The list is sorted in ascending price order and the price from the
     * first entry with criteria matching the order will be used. */
    pricing: DeliveryPrice[];
    /** A list of customer fields required to be provided by clients when placing
     * an order to this place.
     *
     * Values include: "firstName", "lastName", "phone", "email" */
    requiredFields: string[];
    /** If false this placeSpec is not included when listing the (root)
     * PlaceSpecs for a Shop. It is then only used if referenced from
     * PlaceSpec.children in another PlaceSpec. */
    root: boolean;
    /** The services enabled for this place.
     *
     * event_pre_orders: Include this place when pre-ordering for an event.
     *
     * pre_orders: The client should display a time requested dialog when
     * ordering to this place.
     *
     * proximity_orders: Include this place for non-event orders. Assumed to be
     * set unless `event_pre_orders` is set. Both can be set.
     *
     * tipping: The client should displayed a tipping dialog when ordering to
     * this place. */
    services: string[];
    /** Status is one of: disabled, enabled, hidden.
     *
     * disabled: Client should display the place as unselectable enabled: Client
     * should display the place as selectable hidden: Client should not display
     * the place */
    status: string;
    /** Timestamp (UNIX milliseconds) of when this object was last updated.
     * Useful to quickly determine if the object might have changed. */
    timeUpdated: number;
    /** Used for generating ranges of places. Number of tables of this type.
     *
     * If `children` is non-null this will be ignored and a sum of the childrens
     * counts will be used. */
    count: number;
    /** Used for generating ranges of places. Start of naming range. In the
     * example "Table [1...20] Outside" this would be "1".
     *
     * Ignored if children are non-null. */
    rangeStart: string;
    /** Used for generating ranges of places. End of naming range. In the
     * example "Table [1...20] Outside" this would be "20".
     *
     * Ignored if `children` is non-null. */
    rangeEnd: string;
    /** Used for generating ranges of places. Prefix for naming range. In the
     * example "Table [1...20] Outside" this would be "Table " (space included).
     *
     * Ignored if `children` is non-null. */
    rangePrefixI18n: {[key: string]: string};
    /** Used for generating ranges of places. Prefix for naming range. In the
     * example "Table [1...20] Outside" this would be " Outside" (space
     * included).
     *
     * Ignored if `children` is non-null. */
    rangeSuffixI18n: {[key: string]: string};
    /** Used for generating ranges of places. If you do not wish to utilize the
     * naming range you can set a list of names here. */
    customNamesI18n: {[key: string]: string}[];
    /** Used for reservations. Number of seats at each table of this type. */
    seats: number;
    /** Used for reservations. If you select this place you must reserve at
     * least this many seats. */
    minPartySize: number;
    /** Used for reservations. If true, the tables can be joined together to
     * serve bigger parties. */
    joinable: boolean;
    /** Used for reservations. How many seats are lost (or gained if negative)
     * when tables are joined to serve bigger parties. */
    joiningCost: number;
    /** Used for reservations. If true, the tables can be split to serve
     * multiple smaller parties. */
    splittable: boolean;
    /** Used for reservations. How many seats are lost when tables are split to
     * serve multiple smaller parties. For example if you split a 6 seat table
     * you may accept to book 2x2 persons at the table, but not 3x2, by putting
     * a cost of 2. */
    splittingCost: number;

    /** Temporary parameter to be able to save child PlaceSpecs with the
     *  PlaceSpec. */
    points: PlaceSpec[];
    
    /**
     * Id for upsell categories
     */
    upsellCategory: number;

    public static setDefaults(obj: PlaceSpec) {
        if (obj.nameI18n == null) {
            obj.nameI18n = {en: obj.name};
        }
        if (obj.descriptionI18n == null) {
            obj.descriptionI18n = {en: obj.description};
        }
        if (obj.orderPlacedMessageI18n == null) {
            obj.orderPlacedMessageI18n = {};
        }
    }

    public get description() {
        return translateI18nField(this.descriptionI18n);
    }

    public set description(ignored) {
    }

    public get menuTitle() {
        return translateI18nField(this.menuTitleI18n);
    }

    public set menuTitle(ignored) {
    }

    public get menuDescription() {
        return translateI18nField(this.menuDescriptionI18n);
    }

    public set menuDescription(ignored) {
    }

    public get name(): string {
        return translateI18nField(this.nameI18n);
    }

    public set name(ignored) {
    }

    public get orderPlacedMessage() {
        return translateI18nField(this.orderPlacedMessageI18n);
    }
    public set orderPlacedMessage(ignored) {
    }

    public hasPaymentOption(option) {
        return this.paymentOptions && this.paymentOptions.indexOf(option) !== -1;
    }

    public hasService(service) {
        return this.services && this.services.indexOf(service) !== -1;
    }

    public setPaymentOption(option, enabled) {
        if (this.paymentOptions === undefined) {
            this.paymentOptions = [];
        }
        if (enabled) {
            this.paymentOptions.push(option);
        } else {
            arrayRemoveAll(this.paymentOptions, option);
        }
    }

    public hasRequiredField(field) {
        return this.requiredFields && this.requiredFields.indexOf(field) !== -1;
    }

    public setRequiredField(field, enabled) {
        if (this.requiredFields === undefined) {
            this.requiredFields = [];
        }
        if (enabled) {
            this.requiredFields.push(field);
        } else {
            arrayRemoveAll(this.requiredFields, field);
        }
    }

    public setService(service, enabled) {
        if (this.services === undefined) {
            this.services = [];
        }
        if (enabled) {
            this.services.push(service);
        } else {
            arrayRemoveAll(this.services, service);
        }
    }

    public setStringId(id) {
        this.id = id;
    }

    public trackingEquals(o: PlaceSpec) {
        return this.id === o.id && this.timeUpdated === o.timeUpdated;
    }
}

export function pejPlacesFilterRoot(places: PlaceSpec[]): PlaceSpec[] {
    if (places == null) { return null; }
    return places.filter(place => place.root);
}

export function pejPlaceMapFilterRoot(m: Map<string, PlaceSpec>): PlaceSpec[] {
    if (m == null) { return null; }
    return iteratorFilter(m.values(), place => place.root);
}

export function pejPlacesHasService(places: PlaceSpec[], service: string): boolean {
    return pejPlacesServices(places).indexOf(service) !== -1;
}

export function pejPlacesServices(places: PlaceSpec[]): string[] {
    if (places == null) { return []; }
    return places.filter(place => place != null && place.services != null)
        .reduce((previousValue: string[], currentValue) => currentValue.services, []);
}

export function pejPlacesOption(places: PlaceSpec[]): DeliveryOptionsEnum | null {
    if (places == null) { return null; }
    return places.filter(place => place != null && place.option != null)
        .reduce((previousValue: DeliveryOptionsEnum, currentValue) => currentValue.option, null);
}

export function pejPlacesPreorderDays(places: PlaceSpec[]): number | null {
    if (places == null) { return null; }
    return places.filter(place => place != null && place.preOrderDays != null)
        .reduce((previousValue: number, currentValue) => currentValue.preOrderDays, null);
}

export function pejPlacesRequiredFields(places: PlaceSpec[]): string[] {
    if (places == null) { return []; }
    return places.filter(place => place != null && place.requiredFields != null)
        .reduce((previousValue: string[], currentValue) => currentValue.requiredFields, []);
}