import Stripe = stripe.Stripe;

declare const window: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Stripe: Stripe;
};

/** Lazy-loading Stripe.js when it is initialized. */
export class StripeWrapperClass {
    private publishableKey: string = undefined;
    private scriptLoadedPromise: Promise<void> = undefined;
    private stripe: Stripe = undefined;

    public get(): Promise<Stripe> {
        if (this.stripe == null) {
            return this.init();
        } else {
            return Promise.resolve(this.stripe);
        }
    }

    public setPublishableKey(publishableKey: string) {
        this.publishableKey = publishableKey;
    }

    private init() {
        return this.load().then(() => {
            if (this.stripe != null) {
                return this.stripe;
            } else {
                // `Stripe` is a global instance created by Stripe.js and defined in @types/stripe-v3
                this.stripe = Stripe(this.publishableKey);
                return this.stripe;
            }
        });
    }

    private load(): Promise<void> {
        if (window.Stripe != null) { // already loaded
            return Promise.resolve();
        }
        if (this.scriptLoadedPromise == null) {
            this.scriptLoadedPromise = new Promise((resolve) => {
                this.loadRecursive(resolve);
            });
        }
        return this.scriptLoadedPromise;
    }

    private loadRecursive(resolve: (value?: PromiseLike<void>) => void, backoff = 100) {
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.onload = () => {
            resolve();
        };
        script.onerror = () => {
            document.head.removeChild(script);
            setTimeout(() => {
                this.loadRecursive(resolve, Math.min(backoff * 2, 2000));
            }, backoff);
        };
        document.head.appendChild(script);
    }
}

export const stripeWrapper = Object.seal(new StripeWrapperClass());
