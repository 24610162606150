import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {pejPlacesPreorderDays, PlaceSpec, shareCached, Shop, TimeInterval} from 'pej-sdk';
import {PejShopRxService} from 'pej-sdk/services/shop-rx';

const BUFFER_NOW_MINUTES = 30;
const BUFFER_SHOP_CLOSE_MINUTES = 15;
const BUFFER_SHOP_OPEN_MINUTES = 15;
const STEP_MINUTES = 15;

export class TimeRequestedLogic {
    private observableCache = {};
    private places: Readonly<PlaceSpec>[];
    public preOrderDays: number = null;
    private shop: Readonly<Shop>;
    public selectedDate = new BehaviorSubject<number>(Date.now());
    public selectedTime = new BehaviorSubject<number>(0);
    public timeAdjusted = new BehaviorSubject<boolean>(false);

    constructor(shop: Readonly<Shop>, places: Readonly<PlaceSpec[]>) {
        this.shop = shop;
        this.places = places as PlaceSpec[];
        this.preOrderDays = pejPlacesPreorderDays(this.places as PlaceSpec[]);
    }

    public dateList(): Observable<number[]> {
        return shareCached(this.observableCache, 'dateList', () => {
            const maxDays = this.preOrderDays !== null ? Math.min(Math.max(this.preOrderDays, 0), 2) : 2;
            const dateList = [];
            let dt = DateTime.local();
            for (let i = 0; i < maxDays + 1; i++) {
                dateList.push(dt.toMillis());
                dt = dt.plus({'days': 1});
            }
            return of(dateList);
        });
    }

    public setDate(timestamp: number) {
        this.selectedDate.next(timestamp);
        combineLatest([this.timeList(), this.selectedTime]).pipe(take(1)).toPromise().then(([timeList, selectedTime]) => {
            let newTime = timeList?.[0] ?? null;
            if (selectedTime) {
                const selectedTimeString = DateTime.fromMillis(selectedTime).toFormat('HH:mm');
                if (timeList.find((t) => t === selectedTimeString)) {
                    newTime = selectedTimeString;
                }
            }
            this.setTime(newTime);
            this.timeAdjusted.next(true);
        });
    }

    public setTime(formatted: string) {
        if (!formatted) {
            this.selectedTime.next(undefined);
            return;
        }
        if (formatted === '0') {
            this.selectedTime.next(0);
            this.selectedDate.next(undefined);
            return;
        }
        const input = DateTime.fromFormat(formatted, 'HH:mm');
        const selectedDateNullable = this.selectedDate.value;
        const selectedDate = selectedDateNullable != null ? DateTime.fromMillis(selectedDateNullable) : DateTime.local();
        const dt = selectedDate.set({
                'hour': input.hour,
                'minute': input.minute,
                'second': 0,
                'millisecond': 0
            });
        this.selectedTime.next(dt.toMillis());
    }

    public timeList(): Observable<string[]> {
        return shareCached(this.observableCache, 'timeList', () => {
            return combineLatest([PejShopRxService.getOpeningHours(this.shop.id), this.selectedDate])
                .pipe(map(([week, selectedDateNullable]) => {
                    const selectedDate = selectedDateNullable != null ? DateTime.fromMillis(selectedDateNullable) : DateTime.local();
                    const threeLetterDayOfWeek = selectedDate.setLocale('en').toFormat('ccc').toLowerCase();
                    const todayString = week[threeLetterDayOfWeek];
                    const timeIntervals = TimeInterval.fromString(todayString);
                    const timeList = [];
                    if (timeIntervals == null) {
                        return timeList;
                    }
                    const earliest = DateTime.local()
                        .plus({'minute': BUFFER_NOW_MINUTES});
                    for (const timeInterval of timeIntervals) {
                        const opening = selectedDate.set({
                            'hour': timeInterval.h0,
                            'minute': timeInterval.m0,
                            'second': 0,
                            'millisecond': 0
                        })
                            .plus({'minute': BUFFER_SHOP_OPEN_MINUTES});

                        const closing = selectedDate.set({
                            'hour': timeInterval.h1,
                            'minute': timeInterval.m1,
                            'second': 0,
                            'millisecond': 0
                        })
                            .minus({'minute': BUFFER_SHOP_CLOSE_MINUTES});

                        let loopTime = opening;
                        while (loopTime <= closing) {
                            if (loopTime >= earliest) {
                                timeList.push(loopTime.toFormat('HH:mm'));
                            }
                            loopTime = loopTime.plus({'minute': STEP_MINUTES});
                        }
                    }
                    return timeList;
                }));
        });
    }
}
