import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';

export function getRecursiveDataParam(activatedRoute: ActivatedRoute, param: string, children?) {
    const rootData = activatedRoute.snapshot.data;
    if (rootData != null && rootData[param] != null) {
        return rootData[param];
    }
    if (children == null) {
        children = activatedRoute.children;
    }
    for (const child of children) {
        const data = child.data.value;
        if (data != null && data[param] != null) {
            return data[param];
        }
    }
    for (const child of children) {
        if (child.children != null) {
            const foundRecursive = getRecursiveDataParam(activatedRoute, param, child.children);
            if (foundRecursive) {
                return foundRecursive;
            }
        }
    }
    return undefined;
}

export function getRecursiveParam(activatedRoute: ActivatedRoute, param: string, children?) {
    const rootParams = activatedRoute.snapshot.params;
    if (rootParams != null && rootParams[param] != null) {
        return rootParams[param];
    }
    if (children == null) {
        children = activatedRoute.children;
    }
    for (const child of children) {
        const params = child.params.value;
        if (params != null && params[param] != null) {
            return params[param];
        }
    }
    for (const child of children) {
        if (child.children != null) {
            const foundRecursive = getRecursiveParam(activatedRoute, param, child.children);
            if (foundRecursive) {
                return foundRecursive;
            }
        }
    }
    return undefined;
}

export function getRecursiveParamSnapshot(route: ActivatedRouteSnapshot, param: string, children?) {
    if (children == null) {
        children = route.children;
    }
    for (const child of children) {
        const params = child.params;
        if (params != null && params[param] != null) {
            return params[param];
        }
    }
    for (const child of children) {
        if (child.children != null) {
            const foundRecursive = getRecursiveParamSnapshot(route, param, child.children);
            if (foundRecursive) {
                return foundRecursive;
            }
        }
    }
    return undefined;
}
