import {translateI18nField} from '../core';

export class ServiceChargeTotal {
  serviceChargeId: string;
  total: number;
  nameI18n: {[id: string]: string};

  public get name() {
      return translateI18nField(this.nameI18n);
  }
}
