import firebase from 'firebase/app';
import {combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {PejFirestoreService} from '../core/firestore';
import {Item, ItemOverride, ItemStatusEnum, Shop} from '../models';
import {FirestoreMap} from '../models/shared/firestore-map';
import {firestoreQueryListener} from '../utils/firestore';
import {shareCached} from '../utils/rx';
import {determineActiveTimeIntervals, PejShopFirestoreService} from './shop-firestore';
import Query = firebase.firestore.Query;

export interface PejItemFilter {
    shopId: number;
    placeId?: string;
    eventId?: string;
    timestamp?: number;
}

function fsShopItems(shopId: number): Query {
    return PejFirestoreService.firestore()
        .collection('shops')
        .doc(`${shopId}`)
        .collection('items')
        .orderBy('ordering', 'asc');
}

function determineStatusForTimeIntervals(
    startStatus: ItemStatusEnum,
    timeIntervalMap: {[id: string]: ItemOverride},
    activeIntervals: string[]
): ItemStatusEnum {
    let result = startStatus;
    for (const timeIntervalKey of activeIntervals) {
        const override = timeIntervalMap[timeIntervalKey];
        if (override != null) {
            if (override.status != null) {
                if (override.status === 'restore') {
                    result = startStatus;
                } else {
                    result = override.status;
                }
            }
        }
    }
    return result;
}

export class PejItemFirestoreServiceClass {
    private observableCache = {};

    public forShop(shopId: number): Observable<FirestoreMap<Item>> {
        const path = `shops/${shopId}/items`;
        return shareCached(this.observableCache, path, () =>
            firestoreQueryListener(fsShopItems(shopId), Item)
                .pipe(map((it: FirestoreMap<Item>) => {
                    for (const obj of it.values()) {
                        obj.shopId = shopId;
                    }
                    return it;
                })));
    }

    public forShopFiltered(params: PejItemFilter): Observable<Item[]> {
        const path = `shops/${params.shopId}/items;filtered:${params.placeId}:${params.eventId}:${params.timestamp}`;
        return shareCached(this.observableCache, path, () => {
            const itemsObservable = this.forShop(params.shopId);
            const shopObservable = PejShopFirestoreService.shop(params.shopId);
            return combineLatest([itemsObservable, shopObservable])
                .pipe(map(([items, shop]: [FirestoreMap<Item>, Shop]) => {
                    let result: Item[] = [...items.values()];
                    if (shop.services != null) {
                        if (shop.hasService('filter_by_delivery_group')) {
                            const placeId = params.placeId;
                            result = result.filter(item =>
                                !item.deliveryGroupsFilter || (placeId != null && item.deliveryGroupsFilter.includes(placeId))
                            );
                        }
                        if (shop.hasService('filter_by_event')) {
                            result = result.filter(item =>
                                !item.eventIds || (params.eventId != null && item.eventIds.includes(params.eventId))
                            );
                        }
                        if (shop.hasService('filter_by_time_of_day')) {
                            const timestamp = params.timestamp ?? new Date().valueOf();
                            const active = determineActiveTimeIntervals(shop.timeIntervals, shop.timeZone, timestamp);
                            result = result.filter(item =>
                                !item.timeIntervalMap ||
                                'hidden' !== determineStatusForTimeIntervals(item.status, item.timeIntervalMap, active)
                            );
                        }
                    }
                    return result;
                }));
        });
    }

    public item(shopId: number, id: number): Observable<Item> {
        return this.forShop(shopId)
            .pipe(map(m => m.get(`${id}`)))
            .pipe(distinctUntilChanged((a, b) => a.trackingEquals(b)));
    }

    public itemOnline(shopId: number, id: number): Observable<Item> {
        return this.forShop(shopId)
            .pipe(filter(obj => !obj?.fromCache))
            .pipe(map(m => m.get(`${id}`)))
            .pipe(distinctUntilChanged((a, b) => a.trackingEquals(b)));
    }
}


export const PejItemFirestoreService = Object.seal(new PejItemFirestoreServiceClass());
