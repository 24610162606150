import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, take} from 'rxjs/operators';

/** Service used to wait for a ready state from component before page scroll
 *  is done after back navigation. */
@Injectable({
    providedIn: 'root',
})
export class PageStateService implements Resolve<void> {
    public stateSubject = new BehaviorSubject('ready');
    public get state() {
        return this.stateSubject.value;
    }
    public set state(value: string) {
        this.stateSubject.next(value);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        this.state = 'loading';
        return of(null);
    }

    public whenReady(): Observable<any> {
        return this.stateSubject.pipe(filter(state => state === 'ready'), take(1));
    }
}
