import {BehaviorSubject, Observable} from 'rxjs';
import {PejConnectionService} from '../core/connection';
import {Offer} from '../models/offer';
import {mapObject} from '../utils/object-mapper';

export class PejOfferServiceClass {
    public _claimedOffersSubject = new BehaviorSubject<ReadonlyArray<Offer>>([]);
    public get claimedOffersObservable(): Observable<ReadonlyArray<Offer>> {
        return this._claimedOffersSubject;
    }
    public get claimedOffers(): ReadonlyArray<Offer> {
        return this._claimedOffersSubject.value;
    }
    public set claimedOffers(ls: ReadonlyArray<Offer>) {
        this._claimedOffersSubject.next(ls);
    }

    public claimedOfferAdd(offer: Offer) {
        const ls = this.claimedOffers;
        // add the offer if is not in the list
        if (ls.filter(o => o.id === offer.id).length === 0) {
            this.claimedOffers = [...ls, offer];
        }
    }

    public claimedOffersClear() {
        this.claimedOffers = [];
    }

    public offerClaim(shopId: number, code: string) {
        const input = {shopId, code};
        return PejConnectionService.post(`offers/actions/claim`, input)
            .then(response => mapObject(response, Offer))
            .then(offer => {
                this.claimedOfferAdd(offer);
                return offer;
            });
    }
}


export const PejOfferService = Object.seal(new PejOfferServiceClass());
