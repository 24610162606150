import {translateI18nField} from '../core/locale';
import {ServingImage} from './shared/serving-image';

export class PejInfoLink {
    descriptionI18n: {[key: string]: string};
    id: string;
    image: ServingImage;
    htmlI18n: {[key: string]: string};
    nameI18n: {[key: string]: string};
    url: string;

    public get description(): string {
        return translateI18nField(this.descriptionI18n);
    }
    public set description(ignored) {
    }

    public get html(): string {
        return translateI18nField(this.htmlI18n);
    }
    public set html(ignored) {
    }

    public get name(): string {
        return translateI18nField(this.nameI18n);
    }
    public set name(ignored) {
    }
}
