/* eslint-disable max-len */
import firebase from 'firebase/app';
import 'firebase/firestore';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {getQueryParam} from '../utils/web';
import {PejFirebaseService} from './firebase';
import {PejFirebaseAuthService} from './firebase-auth';

function shouldEnablePersistence() {
    // For the web, offline persistence is supported only by the Chrome, Safari, and Firefox web browsers.
    // https://firebase.google.com/docs/firestore/manage-data/enable-offline#configure_offline_persistence

    if (navigator && navigator.userAgent) {
        // From crash reports it seems too many iOS devices crash regularly with
        // persistence enabled, even in Chrome.
        // https://bugs.webkit.org/show_bug.cgi?id=197050

        // Disabling for Chrome on iOS devices
        // Example user agent iOS Chrome: Mozilla/5.0 (iPhone; CPU iPhone OS 13_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/80.0.3987.95 Mobile/15E148 Safari/604.1
        const chromeOnIOSMatch = navigator.userAgent.match(/(?:iPad|iPhone|iPod).*CriOS/);
        if (chromeOnIOSMatch) {
            return false;
        }

        // Disabling for all iOS devices version <14
        // Example user agent iOS Safari: Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.2 Mobile/15E148 Safari/604.1
        // Example user agent iOS Chrome: Mozilla/5.0 (iPhone; CPU iPhone OS 13_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/80.0.3987.95 Mobile/15E148 Safari/604.1
        const iosMatch = navigator.userAgent.match(/(?:iPad|iPhone|iPod)[^)]*OS\s+([\d_]+)/);
        if (iosMatch) {
            const osVersionString = iosMatch[1];
            const osVersionArray = osVersionString.split('_');
            const osVersionMajor = Number(osVersionArray[0]);
            if (osVersionMajor < 14) {
                return false;
            }
        }

        // Enable for Chrome/Chromium/Safari versions >58
        // Arbitrarily chosen as version 58 implemented IndexedDB 2.0 - may change in the future
        // https://en.wikipedia.org/wiki/Google_Chrome_version_history
        // Safari user agent is nowadays indistinguishable from Chrome
        // https://www.whatismybrowser.com/guides/the-latest-user-agent/chrome
        // https://www.whatismybrowser.com/guides/the-latest-user-agent/safari
        const chromeMatch = navigator.userAgent.match(/(Chromium|Chrome|CriOS)\/([\d\.]+)/);
        if (chromeMatch) {
            const browserVersionString = chromeMatch[2];
            const browserVersionArray = browserVersionString.split('.');
            const browserVersionMajor = Number(browserVersionArray[0]);
            return browserVersionMajor >= 58;
        }

        // Enable for Firefox versions >44
        // https://en.wikipedia.org/wiki/Firefox_version_history
        // Crash reports noticed for version 43 - using 44 as new minimum
        // https://www.whatismybrowser.com/guides/the-latest-user-agent/firefox
        // Example user agent older Firefox: Mozilla/5.0 (Windows NT 6.1; WOW64; rv:43.0) Gecko/20100101 Firefox/43.0
        const firefoxMatch = navigator.userAgent.match(/(Firefox|FxiOS)\/([\d\.]+)/);
        if (firefoxMatch) {
            const browserVersionString = firefoxMatch[2];
            const browserVersionArray = browserVersionString.split('.');
            const browserVersionMajor = Number(browserVersionArray[0]);
            return browserVersionMajor >= 44;
        }
    }
    return false;
}

function shouldEnableExperimentalLongPolling() {
    return !!getQueryParam('alt-network');
}

export class PejFirestoreServiceClass {
    private _firestore: firebase.firestore.Firestore = undefined;

    public authedFirestoreObservable(): Observable<firebase.firestore.Firestore> {
        return PejFirebaseAuthService.currentUser.pipe(
            filter(user => user != null),
            map(() => this.firestore())
        );
    }

    public firestore() {
        if (this._firestore === undefined) {
            this._firestore = PejFirebaseService.app().firestore();
            if (shouldEnableExperimentalLongPolling()) {
                console.log('Enabling experimental force long polling');
                this._firestore.settings({ experimentalForceLongPolling: true, merge: true });
            }

            if (shouldEnablePersistence()) {
                // try to enable persistence, mostly to minimize the amount of calls we do
                console.log('Enabling persistence');
                this._firestore.enablePersistence({synchronizeTabs: true}).catch(error => {
                    console.log(error);
                });
            } else {
                console.log('Disabling persistence');
            }
        }
        return this._firestore;
    }
}


export const PejFirestoreService = Object.seal(new PejFirestoreServiceClass());
