/** Returns nested field with '.'-separated `path` from `obj`. */
export function resolveObjectPath(obj: {[key: string]: any}, path: string): any {
    const pathList = path.split('.');
    let p = obj;
    while (pathList.length > 0) {
        if (typeof p !== 'object') { return undefined; }
        const key = pathList.shift();
        p = p[key];
    }
    return p;
}

/** Sets nested field with '.'-separated `path` in `obj` to `value`. */
export function setObjectPath(obj: {[key: string]: any}, path: string, value: any): void {
    const pathList = path.split('.');
    let p = obj;
    while (pathList.length > 1) {
        const iterKey = pathList.shift();
        const q = p[iterKey];
        if (q != null) {
            p = q;
        } else {
            p[iterKey] = {};
            p = p[iterKey];
        }
    }
    const leafKey = pathList.shift();
    p[leafKey] = value;
}

/** Deletes nested field with '.'-separated `path` in `obj` to `value`. */
export function deleteObjectPath(obj: {[key: string]: any}, path: string): void {
    const pathList = path.split('.');
    let p = obj;
    while (pathList.length > 1) {
        const iterKey = pathList.shift();
        const q = p[iterKey];
        if (q != null) {
            p = q;
        } else {
            p[iterKey] = {};
            p = p[iterKey];
        }
    }
    const leafKey = pathList.shift();
    delete p[leafKey];
}
