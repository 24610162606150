import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';
import {isNumeric, PejShopWeb, Shop} from 'pej-sdk';
import {shopRxService} from 'pej-sdk/services/shop-rx';
import {PejShopFirestoreService} from 'pej-sdk/services/shop-firestore';
import {getRecursiveParamSnapshot} from '../utils/router-utils';

export interface CustomCssResolved {
    type: string;
    id;
}

@Injectable({
    providedIn: 'root',
})
export class CustomCssShopResolverService implements Resolve<CustomCssResolved> {
    constructor(protected activatedRoute: ActivatedRoute) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CustomCssResolved> | Observable<never> {
        const paramId = getRecursiveParamSnapshot(route, 'shopId');
        if (paramId) {
            // support custom URLs: get the numeric shop ID
            let shopIdNumericObservable;
            if (isNumeric(paramId)) {
                shopIdNumericObservable = of(paramId);
            } else {
                shopIdNumericObservable = shopRxService.getAnon(paramId)
                    .pipe(take(1))
                    .pipe(map((shop: Shop) => shop.id));
            }

            return shopIdNumericObservable
                .pipe(switchMap((id: number) => PejShopFirestoreService.shopWeb(id)))
                .pipe(take(1))
                .pipe(map((obj: PejShopWeb) => {
                    if (obj?.css) {
                        return {'type': 'shop', 'id': `${obj.id}`};
                    } else {
                        return null;
                    }
                }));
        } else {
            return null;
        }
    }
}
