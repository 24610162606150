import {translateI18nField} from '../core';
import {mapObject} from '../utils';

export class PosData {
  serviceCharge: PosDataServiceCharge;
  prices: PosDataPrice;

  public revive() {
    if (this.serviceCharge) {
      this.serviceCharge = mapObject(this.serviceCharge, PosDataServiceCharge);
    }
  }
}

export class PosDataPrice {
  priceSequence: number;
  prices: {[id: string]: number};
}

export class PosDataServiceCharge {
  id: number;
  nameI18n: {[key: string]: any};
  prices: {[id: string]: number};

  public get name() {
    return translateI18nField(this.nameI18n);
  }
  public set name(_: string) {
  }
}
