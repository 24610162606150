import {animate, style, transition, trigger} from '@angular/animations';
import {Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {ApiError} from 'pej-sdk';
import {PejAuthService} from 'pej-sdk/services/auth';
import {Subscription} from 'rxjs';

@Component({
    selector: 'navbar',
    styleUrls: ['./navbar.component.scss'],
    templateUrl: './navbar.component.html',
    animations: [
        trigger('showMobileMenu', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('.25s cubic-bezier(0.25, 0.5, 0.5, 1)', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                animate('.25s cubic-bezier(0.1, 0.25, 0.25, .25)', style({ opacity: 0 }))
            ])
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
})

export class NavbarComponent implements OnInit, OnDestroy {
    public booleanValue = false;
    public hidePolicyUpdate = true;
    public navbarType: string;
    private routerEventsSubscription: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {}

    public ngOnInit() {
        this.routerEventsSubscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.setNavbarType();
            }
        });
    }

    public ngOnDestroy() {
        if (this.routerEventsSubscription != null) {
            this.routerEventsSubscription.unsubscribe();
        }
    }

    public isLoggedIn() {
        return PejAuthService.isLoggedIn();
    }

    public logout() {
        PejAuthService.logout().then((result) => {
            location.reload();
        }, (error: ApiError) => {
            console.log('error: ' + error.message);
        });
    }

    private setNavbarType(children?) {
        if (children == null) {
            children = this.activatedRoute.children;
        }
        for (const child of children) {
            const data = child.data.value;
            if (data != null && data['navbarType'] != null) {
                this.navbarType = data['navbarType'];
                return true;
            }
        }
        for (const child of children) {
            if (child.children != null) {
                const foundRecursive = this.setNavbarType(child.children);
                if (foundRecursive) {
                    return true;
                }
            }
        }
        return false;
    }
}
