import {Offer} from './offer';

export class UserOffer {
    /** The number of charges we have. When this offer is claimed in an order,
     * we reduce this by one.
     *
     * `charged_card`: The number of items you may purchase from this offer.
     *
     * `punch_card`: When {@link #stamps} reach {@link Offer#goal} we increase
     * this by one. */
    charges: number;
    id: number;
    offer: Offer;
    /** `punch_card`: The number of stamps we have. When this reaches
     * {@link Offer#goal} we increase {@link #charges} by 1. */
    stamps: number;
    /** Timestamp of when this entity was created. */
    timeCreated: number;
    /** The number of times the user has utilized this offer. */
    used: number;
}
