export class WaveConfig {
    waveShopId: number;
    queueName: string;
    syncPosClientNumber: number;
    cashierId: number;
    keystore: string;
    keystorePassword: string;
    enabled: boolean;
    posClients: { [key: number]: number };
}

export class WaveJobStatus {
    waveJobId: string;
    status: string;
    description: string;
    started: string;
    updated: string;
}
