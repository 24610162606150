import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {PejConnectionService} from '../core/connection';
import {queryAsPromise, queryObject} from '../core/query';
import {AuthInput, AuthResponse, User} from '../models';

const LOCAL_STORAGE_KEY = 'authService';

export class PejAuthServiceClass {
    private _cache = {};
    private _timeExpires: number = null;
    private _timeExpiresBehaviorSubject = new BehaviorSubject<number | null>(null);
    private _token: string;
    private _userChanged: number;
    private _userIdBehaviorSubject = new BehaviorSubject<number>(null);
    private _userIdSubject = new Subject<number>();

    constructor() {
        let json;
        let obj;
        try {
            json = localStorage?.getItem(LOCAL_STORAGE_KEY);
        } catch (ignore) {}
        if (json != null) {
            try {
                obj = JSON.parse(json);
            } catch (ignore) {
                localStorage?.removeItem(LOCAL_STORAGE_KEY);
            }
        }
        if (obj != null) {
            this._token = obj.token;
            this._userChanged = obj.userId;
            this._timeExpires = obj.timeExpires;
            this.authUpdate();
        } else {
            this._token = null;
            this._userChanged = null;
        }
    }

    public get userChanged(): Observable<number> {
        return this._userIdSubject;
    }

    public get userId(): Observable<number> {
        return this._userIdBehaviorSubject;
    }

    public get timeExpires(): Observable<number | null> {
        return this._timeExpiresBehaviorSubject;
    }

    public authPost(body: AuthInput): Promise<AuthResponse> {
        return PejConnectionService.post('auth', body).then((response: AuthResponse) => {
            this.authStore(response);
            return response;
        });
    }

    public clear(): void {
        const empty = {token: undefined, userId: undefined};
        this.authStore(empty);
    }

    public createFirebaseToken(): Promise<AuthResponse> {
        return PejConnectionService.post('auth/actions/create-firebase-token', {});
    }

    public revokeFirebaseToken(): Promise<AuthResponse> {
        return PejConnectionService.post('auth/actions/revoke-firebase-token', {});
    }

    public get(email: string, expire?: number): Promise<boolean> {
        const cacheKey = 'get:' + email;
        const caller = (etag) => PejConnectionService.get<{response: boolean}>(`auth?email=${email}`, null, etag);
        const query = queryObject(caller, this._cache, cacheKey, null, expire);
        return queryAsPromise(query).then(response => response.response);
    }

    public isLoggedIn(): boolean {
        return this._token != null;
    }

    public login(email, password, deltaExpires?): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'login';
        input.email = email;
        input.password = password;
        input.deltaExpires = deltaExpires;
        return this.authPost(input);
    }

    public loginWithFacebook(fbToken: string): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'login_facebook';
        input.token = fbToken;
        return this.authPost(input);
    }

    public logout(): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'logout';
        return PejConnectionService.post('auth', input).then((response: AuthResponse) => {
            this.clear();
            return response;
        });
    }

    public logoutEverywhere(): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'logout_everywhere';
        return PejConnectionService.post('auth', input).then((response: AuthResponse) => {
            this.clear();
            return response;
        });
    }

    public register(email, password, user?: User, deltaExpires?): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'register';
        input.email = email;
        input.password = password;
        input.user = user;
        input.deltaExpires = deltaExpires;
        return this.authPost(input);
    }

    public registerWithFacebook(fbToken: string): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'register_facebook';
        input.token = fbToken;
        return this.authPost(input);
    }

    public reset(email): Promise<AuthResponse> {
        const input = new AuthInput();
        input.action = 'reset_password';
        input.email = email;
        return PejConnectionService.post('auth', input);
    }

    private authStore(response: AuthResponse) {
        this._token = response.token;
        this._userChanged = response.userId;
        this._timeExpires = response.timeExpires;
        if (this._token != null) {
            const timeExpires = this._timeExpires ?? null;
            localStorage?.setItem(LOCAL_STORAGE_KEY,
                `{"token": "${this._token}", "userId": ${this._userChanged}, "timeExpires": ${timeExpires}}`);
        } else {
            localStorage?.removeItem(LOCAL_STORAGE_KEY);
        }
        this.authUpdate();
    }

    private authUpdate() {
        PejConnectionService.authToken = this._token;
        this._userIdBehaviorSubject.next(this._userChanged);
        this._userIdSubject.next(this._userChanged);
        if (this._timeExpiresBehaviorSubject.value !== this._timeExpires) {
            this._timeExpiresBehaviorSubject.next(this._timeExpires);
        }
    }
}


export const PejAuthService = Object.seal(new PejAuthServiceClass());
