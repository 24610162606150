export function arrayCompare<T>(a: ReadonlyArray<T>, b: ReadonlyArray<T>) {
    if (a === b) { // same object
        return true;
    } else if (a == null) {
        return b == null;
    } else if (a == null) {
        return false;
    } else if (a.length !== b.length) {
        return false;
    } else {
        return JSON.stringify(a) === JSON.stringify(b);
    }
}

export function arrayRemove<T>(array: T[], o: T): T[] {
    const index = array.indexOf(o);
    if (index > -1) {
        return array.splice(index, 1);
    } else {
        return undefined;
    }
}

export function arrayRemoveAll<T>(array: T[], o: T): void {
    for (let i = 0; i < array.length;) {
        if (o === array[i]) {
            array.splice(i, 1);
        } else {
            i++; // only increment if element pointed to is not removed
        }
    }
}

/** Mutate array into target without creating a new object, updating in-place. */
export function mutateArray<T>(array: T[], target: T[], comparator: (a: T, b: T) => boolean) {
    // using old-style for-loop since we are removing elements from
    // the array we are iterating over
    outer:
        for (let i = 0; i < array.length;) {
            const a = array[i];
            for (const t of target) {
                if (comparator(a, t)) {
                    i++; // only increment counter when an item is not removed
                    continue outer;
                }
            }
            array.splice(i, 1); // remove a
        }
    for (let i = 0; i < target.length; i++) {
        const a = i < array.length ? array[i] : undefined;
        const b = target[i];
        if (a == null || !comparator(a, b)) {
            array.splice(i, 0, b); // insert b
        }
    }
}

/** Mutate array into target without creating a new object, updating in-place.
 *  `array` and `target` are expected to maintain the same ordering. If
 *  comparable objects are found ordered differently; they will be treated as
 *  different objects. */
export function mutateSortedArray<T>(array: T[], target: T[], comparator: (a: T, b: T) => boolean) {
    const targetLength = target.length;
    outer:
        for (let i = 0; i < targetLength; i++) {
            const t = target[i];
            if (i < array.length) {
                const a = array[i];
                if (comparator(a, t)) {
                    continue; // normal case. array[i] compares true to target[i]
                }
                // array[0..i-1] is already relaxed
                for (let j = i + 1; j < array.length; j++) {
                    if (comparator(array[j], t)) {
                        // array[j] compares true to target[i]
                        // => array[i..j-1] does not exist in target
                        array.splice(i, j - i);
                        continue outer;
                    }
                }
            }
            // t does not exist in array; insert it
            array.splice(i, 0, t); // insert t
        }
    // remove surplus items from array
    if (array.length > targetLength) {
        array.splice(targetLength, array.length - targetLength);
    }
}

export function primitiveArrayEquals<T extends string | number | boolean>(a: T[], b: T[]) {
    if (a === b) { // object reference comparison
        return true;
    }
    if (a == null) {
        return b == null; // one null and one undefined would slip by first ===
    }
    if (b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}

export function range(start: number, end: number): number[] {
    return Array.from({length: (end - start)}, (v, k) => k + start);
}

/** Does not mutate `array`. Returns a new array if array.length > 1. */
export function stripDuplicatesFromSortedArray<T>(array: T[]) {
    if (array == null) {
        return array;
    }
    if (array.length < 2) {
        return array;
    }
    const result = [array[0]];
    for (let i = 1; i < array.length; i++) {
        if (array[i] !== array[i - 1]) {
            result.push(array[i]);
        }
    }
    return result;
}
