export class ApiError {
    public key: string;
    public message: string;

    public static create(key: string, message: string): ApiError {
        const e = new ApiError();
        e.key = key;
        e.message = message;
        return e;
    }

    public static fromHttpError(error: any): ApiError {
        const e = new ApiError();
        e.key = 'no_key';

        if (error.response && error.response.data) {
            // The request was made, but the server responded with a status code
            // that falls out of the range of 2xx
            const data = error.response.data;
            e.key = data.key;
            e.message = data.message;
        } else {
            // Something happened in setting up the request that triggered an Error
            e.message = error.message ? error.message : error.toString();
        }
        return e;
    }

    public static fromString(message: string): ApiError {
        const e = new ApiError();
        e.key = 'no_key';
        e.message = message;
        return e;
    }
}
