import firebase from 'firebase/app';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {PejFirebaseAuthService} from '../core/firebase-auth';
import {PejFirestoreService} from '../core/firestore';
import {PejGroupPrivate, PejGroupWeb} from '../models/group';
import {firestoreDocumentListener} from '../utils/firestore';
import {shareCached} from '../utils/rx';
import DocumentReference = firebase.firestore.DocumentReference;

function fsGroupPrivate(id: string): DocumentReference {
    return PejFirestoreService.firestore().collection('groups-private').doc(`${id}`);
}

function fsGroupWeb(id: string): DocumentReference {
    return PejFirestoreService.firestore().collection('groups-web').doc(`${id}`);
}

export class PejGroupFirestoreServiceClass {
    private observableCache = {};

    public groupPrivate(id: string): Observable<PejGroupPrivate> {
        const path = `groups-private/${id}`;
        return shareCached(this.observableCache, path, () =>
            PejFirestoreService.authedFirestoreObservable().pipe(switchMap(() =>
                firestoreDocumentListener(fsGroupPrivate(id), PejGroupPrivate))));
    }

    public groupPrivateOnline = (id: string) =>
        this.groupPrivate(id).pipe(filter(obj => !obj?.fromCache));

    public groupPrivatePatch(id: string, body: Partial<PejGroupPrivate>): Promise<void> {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsGroupPrivate(id).set(body, {merge: true}));
    }

    public groupWeb(id: string): Observable<PejGroupWeb> {
        const path = `groups-web/${id}`;
        return shareCached(this.observableCache, path, () =>
            firestoreDocumentListener(fsGroupWeb(id), PejGroupWeb));
    }

    public groupWebOnline = (id: string) =>
        this.groupWeb(id).pipe(filter(obj => !obj?.fromCache));

    public groupWebPatch(id: string, body: Partial<PejGroupWeb>): Promise<void> {
        return PejFirebaseAuthService.currentUserPromise
            .then(() => fsGroupWeb(id).set(body, {merge: true}));
    }
}


export const PejGroupFirestoreService = Object.seal(new PejGroupFirestoreServiceClass());
