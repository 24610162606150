import firebase from 'firebase/app';
import {Observable, Subscriber} from 'rxjs';
import {map, retryWhen} from 'rxjs/operators';
import {FirestoreMap} from '../models/shared/firestore-map';
import {FirestoreObject} from '../models/shared/firestore-object';
import {mapObject} from './object-mapper';
import {exponentialBackoffRetry} from './rx';
import DocumentReference = firebase.firestore.DocumentReference;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import Query = firebase.firestore.Query;
import QuerySnapshot = firebase.firestore.QuerySnapshot;

export function firestoreDocumentSnapshotToObject<T extends FirestoreObject>(snapshot: DocumentSnapshot, type: new() => T): T {
    if (snapshot == null) { return null; }
    const data = snapshot.data();
    if (data == null) { return undefined; }
    const mapped = mapObject(data, type);
    if (mapped.setStringId != null) { mapped.setStringId(snapshot.id); } else { mapped.id = snapshot.id; }
    mapped.fromCache = snapshot.metadata.fromCache;
    return mapped;
}

export function firestoreDocumentListener<T extends FirestoreObject>(ref: DocumentReference, type: new () => T): Observable<T> {
    return new Observable<any>(subscriber =>
        ref.onSnapshot({includeMetadataChanges: true}, subscriber)
    ).pipe(
        map(snapshot => firestoreDocumentSnapshotToObject(snapshot, type)),
        /* We never want the Observable to raise an error. */
        retryWhen(exponentialBackoffRetry({retries: 100000, maxBackoff: 60000}))
    );
}

/** Using a Map (ES2015 feature) instead of an object ({}) since we want to maintain the order.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map */
export function firestoreQuerySnapshotToMap<T extends FirestoreObject>(snapshot: QuerySnapshot, type: new() => T): FirestoreMap<T> {
    if (snapshot == null) { return new FirestoreMap<T>(); }
    const docs = snapshot.docs;
    const result = new FirestoreMap<T>();
    result.fromCache = snapshot.metadata.fromCache;
    for (const doc of docs) {
        const data = doc.data();
        const mapped: T = mapObject(data, type);
        if (mapped.setStringId != null) { mapped.setStringId(doc.id); } else { mapped.id = doc.id; }
        result.fromCache = snapshot.metadata.fromCache;
        result.set(doc.id, mapped);
    }
    return result;
}

export function firestoreQueryListener<T extends FirestoreObject>(query: Query, type: new () => T): Observable<FirestoreMap<T>> {
    return new Observable<any>(subscriber =>
        query.onSnapshot({includeMetadataChanges: true}, subscriber)
    ).pipe(
        map(snapshot => firestoreQuerySnapshotToMap(snapshot, type)),
        /* We never want the Observable to raise an error. */
        retryWhen(exponentialBackoffRetry({retries: 100000, maxBackoff: 60000}))
    );
}
