import {FirestoreObjectNumericId} from './shared/firestore-object';

export class PejShopPrivate implements FirestoreObjectNumericId {
    /** List of alerts to report for this shop. Includes things like
     * settings for sending emails when incoming orders go unhandled. */
    alerts: PejShopPrivateAlert[];
    /** Shop ID */
    id: number;
    /** POS setup data for the shop. Used in Pej Kiosk for example. */
    pos: {[key: string]: any};
    stripeConnectId: string;
    /** List of sub shop IDs if this is a master shop. */
    subShopIds: number[];

    fromCache: boolean;

    setStringId(id: string): void {
        this.id = Number(id);
    }
}

export class PejShopPrivateAlert {
    /** Duration in milliseconds before the alert should be triggered. */
    threshold: number;
    /** E-mail address to send alert to. (Optional). */
    email: string;
    /** Phone number to send text alert to. (Optional). */
    sms: string;
}

export class PejShopPrivateAdmin implements FirestoreObjectNumericId {
    public roles: {[key: string]: boolean};
    public shopId: number;
    public userId: number;

    fromCache?: boolean;

    setStringId(id: string): void {
        this.userId = Number(id);
    }
}
