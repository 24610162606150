/** To be used in toJSON() of classes that use Typescript getters. */
import {forOwn} from './object';
import {isArray} from './type';

export function unmapGetters(mapped: {[key: string]: any}): {[key: string]: any} {
    if (mapped == null || typeof mapped !== 'object') {
        return mapped;
    } else if (isArray(mapped)) { // arrays are objects in JavaScript
        return mapped.map(obj => unmapGetters(obj));
    } else if (mapped instanceof Map) {
        const unmapped: {[key: string]: any} = {};
        for (const [fullKey, lookup] of mapped) {
            let value;
            if (lookup == null || typeof lookup !== 'object') {
                value = lookup;
            } else { // recurse for both arrays and objects
                value = unmapGetters(lookup);
            }
            if (fullKey[0] === '_') {
                const key = fullKey.substring(1);
                unmapped[key] = value;
            } else {
                unmapped[fullKey] = value;
            }
        }
        return unmapped;
    } else { // mapped is now proven non-array object
        const unmapped: {[key: string]: any} = {};
        const keys = Object.keys(mapped);
        for (const fullKey of keys) {
            const lookup = mapped[fullKey];
            let value;
            if (lookup == null || typeof lookup !== 'object') {
                value = lookup;
            } else { // recurse for both arrays and objects
                value = unmapGetters(lookup);
            }
            if (fullKey[0] === '_') {
                const key = fullKey.substring(1);
                unmapped[key] = value;
            } else {
                unmapped[fullKey] = value;
            }
        }
        return unmapped;
    }
}

export function jsonSerializeWithGetters(mapped: any) {
    // JSON.stringify does not work together with Typescript Accessors
    // (private variables with getter and setter) out of the box.
    //
    // This is a workaround for serializing private variables by removing
    // the underscore from the key. This must be done before mapObject()
    // as mapObject() will re-create the private variables in the cloned
    // object.
    //
    // Solution inspired by https://stackoverflow.com/a/40084747/3849087
    const unmapped = unmapGetters(mapped);
    return JSON.stringify(unmapped);
}

/** Returns a deep copy of this object. This copy contains no ties to the original object. */
export function deepClone<T>(obj: T | Readonly<T>, type: new() => T): T {
    const json = jsonSerializeWithGetters(obj);
    const clonedObj = JSON.parse(json);
    return mapObject(clonedObj, type);
}

/** Returns a deep copy of this array *of objects*. */
export function deepCloneArray<T>(list: T[], type: new() => T): T[] {
    const result = [];
    for (const obj of list) {
        const cloned = deepClone(obj, type);
        result.push(cloned);
    }
    return result;
}

/** Returns a deep copy of this object. This copy contains no ties to the original object. */
export function deepCloneNoType<T>(obj: T | Readonly<T>): T {
    const json = jsonSerializeWithGetters(obj);
    return JSON.parse(json);
}

/** Returns a deep copy of this array *of objects or primitives*. Array of arrays not tested. */
export function deepCloneNoTypeArray<T>(list: T[]): T[] {
    const result = [];
    for (const obj of list) {
        if (typeof obj === 'object') {
            const cloned = deepCloneNoType(obj);
            result.push(cloned);
        } else { // primitive (= immutable)
            result.push(obj);
        }
    }
    return result;
}

export function forOwnWithGetters<K, V>(mapped: any, callback: (v: V, k: string) => void): void {
    const unmapped = unmapGetters(mapped);
    for (const prop in unmapped) {
        if (unmapped.hasOwnProperty(prop)) {
            callback(unmapped[prop], prop);
        }
    }
}

/**
 * Maps an object created from JSON to a Typescript object.
 * Rationale: this is needed in order to access a type's methods.
 *
 * Types can implement a revive method to ensure properties are
 * mapped to the correct types. They should call the map* functions
 * to ensure complex structures are recursively mapped.
 */
export function mapObject<T>(obj: any, type: new() => T): T {
    const result = Object.assign(new type(), obj);
    result.revive?.();
    return result;
}

/** Maps an array of objects created from JSON to an array of Typescript objects. */
export function mapArray<T>(array: any[], type: new() => T): T[] {
    if (array == null) { return array; }
    const result: T[] = [];
    for (const obj of array) {
        const o = Object.assign(new type(), obj);
        o.revive?.();
        result.push(o);
    }
    return result;
}

/** Maps a map of object-arrays created from JSON to a map of arrays of
 *  Typescript objects. Keys are assumed to be a string. TODO: support
 *  number keys. */
export function mapArrayMap<T>(map: { [key: string]: any[] }, type: new() => T): { [key: string]: T[] } {
    const result: { [key: string]: T[] } = {};
    forOwn(map, (v, k) => {
        result[k] = mapArray(v, type);
    });
    return result;
}

/** Maps a map of objects created from JSON to a map of Typescript objects.
 *  Keys are assumed to be a string. TODO: support number keys. */
export function mapMapMap<T>(map: { [key: string]: any }, type: new() => T): Map<string, T> {
    const result = new Map<string, T>();
    forOwn(map, (v, k) => {
        result.set(k, mapObject(v, type));
    });
    return result;
}

/** Maps a map of objects created from JSON to a map of Typescript objects. Keys are assumed to be a string. TODO: support number keys. */
export function mapObjectMap<T>(map: { [key: string]: any }, type: new() => T): { [key: string]: T } {
    const result: { [key: string]: T } = {};
    forOwn(map, (v, k) => {
        result[k] = mapObject(v, type);
    });
    return result;
}

/** Converts Map with string keys to Object */
export function mapToObject<T>(map: Map<string, any>): T {
    return Object.assign(Object.create(null), ...[...map].map(v => ({ [v[0]]: v[1] })));
}

/** Returns a shallow copy of this object. Shallow means every child object is shared between the instances. */
export function shallowClone<T>(obj: T): T {
    return Object.assign({}, obj);
}
