import firebase from 'firebase/app';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {PejFirestoreService} from '../core/firestore';
import {Offer, UserOffer} from '../models';
import {mapArray, shareCached} from '../utils';
import {PejAuthService} from './auth';
import {PejOfferService} from './offer';
import CollectionReference = firebase.firestore.CollectionReference;
import Query = firebase.firestore.Query;

const cacheKeyMyOffers = () => `myOffers`;
const cacheKeyShopOffers = (shopId) => `shopOffers:${shopId}`;

function fsShopOffers(shopId: number): Query {
    return PejFirestoreService.firestore().collection('offers').where('shopIds', 'array-contains', shopId);
}

function fsUserOffers(userId: number): CollectionReference {
    return PejFirestoreService.firestore().collection('users').doc('' + userId).collection('offers');
}

export function getRewardItemIds(offer: Offer) {
    const rewardType = offer.reward?.type;
    if (rewardType === 'free_item_same' || rewardType == null) { // default if no reward type specified
        return offer.itemIds;
    } else if (rewardType === 'free_item_other') {
        return offer.reward.itemIds;
    } else {
        console.error(`Offer reward type unknown. type=${rewardType}`);
    }
}

export function getRewardItemKeys(offer: Offer) {
    const rewardType = offer.reward?.type;
    if (rewardType === 'free_item_same' || rewardType == null) { // default if no reward type specified
        return offer.itemKeys;
    } else if (rewardType === 'free_item_other') {
        return offer.reward.itemKeys;
    } else {
        console.error(`Offer reward type unknown. type=${rewardType}`);
    }
}

export class PejOfferFirestoreServiceClass {
    private observableCache: { [id: string]: Observable<any> } = {};

    public myOffers(): Observable<UserOffer[]> {
        return shareCached(this.observableCache, cacheKeyMyOffers(), () =>
             PejAuthService.userId.pipe(switchMap(userId => {
                if (userId == null) {
                    return of([]);
                } else {
                    return new Observable(subscriber => {
                        const onSnapshotListener = fsUserOffers(userId).onSnapshot(snapshot => {
                            const data = snapshot.docs.map(doc => doc.data());
                            const mapped = mapArray(data, UserOffer);
                            subscriber.next(mapped);
                        });
                        return () => {
                            onSnapshotListener();
                        };
                    });
                }
            })));
    }

    public myShopOffers(shopId: number): Observable<UserOffer[]> {
        return combineLatest([this.myOffers(),
                              this.shopOffers(shopId),
                              PejOfferService.claimedOffersObservable])
            .pipe(map(([userOffers, offers, claimedOffers]: [UserOffer[], Offer[], Offer[]]) => {
                const result: UserOffer[] = [];
                const claimedOffersForShop = claimedOffers.filter(offer => offer.shopIds?.includes(shopId));
                outer:
                for (const offer of [...offers, ...claimedOffersForShop]) {
                    for (const userOffer of userOffers) {
                        if (offer.id === userOffer.id) {
                            userOffer.offer = offer;
                            result.push(userOffer);
                            continue outer;
                        }
                    }
                    { // continue was not reached
                        const userOffer = new UserOffer();
                        userOffer.id = offer.id;
                        userOffer.offer = offer;
                        result.push(userOffer);
                    }
                }
                return result;
            }));
    }

    public shopOffers(shopId: number): Observable<Offer[]> {
        return shareCached(this.observableCache, cacheKeyShopOffers(shopId), () =>
            new Observable(subscriber => {
                const onSnapshotListener = fsShopOffers(shopId).onSnapshot(snapshot => {
                    const data = snapshot.docs.map(doc => doc.data());
                    const mapped = mapArray(data, Offer);
                    mapped.forEach(o => o.setDefaults());
                    subscriber.next(mapped);
                });
                return () => {
                    onSnapshotListener();
                };
            }));
    }
}


export const PejOfferFirestoreService = Object.seal(new PejOfferFirestoreServiceClass());
