import {FirestoreObjectNumericId} from './shared/firestore-object';

export class PejShopWeb implements FirestoreObjectNumericId {
    css: string;
    fromCache: boolean;
    id: number;

    setStringId(id: string): void {
        this.id = Number(id);
    }
}
