import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'fullscreen-spinner',
    encapsulation: ViewEncapsulation.Emulated,
    styles: [`
		.fullscreen-spinner {
            position: fixed;
			height: 100vh;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
						z-index: 9000;
						top: 0;
		}

		.fullscreen-spinner .inner-spinner {
			width: 40px;
			height: 40px;
			position: relative;
			top: 0;
			left: calc(50% - 20px);
		}

		.fullscreen-spinner .pej-spot-1, .fullscreen-spinner .pej-spot-2 {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			opacity: 0.85;
			position: absolute;
			top: 0;
			left: 0;
			-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
			animation: sk-bounce 2.0s infinite ease-in-out;
		}

		.pej-spot-2 {
			-webkit-animation-delay: -1.0s;
			animation-delay: -1.0s;
		}

		@-webkit-keyframes sk-bounce {
			0%, 100% {
				-webkit-transform: scale(0.0)
			}
			50% {
				-webkit-transform: scale(1.0)
			}
		}

		@keyframes sk-bounce {
			0%, 100% {
				transform: scale(0.0);
				-webkit-transform: scale(0.0);
			}
			50% {
				transform: scale(1.0);
				-webkit-transform: scale(1.0);
			}
		}

		h1 {
			margin: 15px 0 0 0;
		}
    `],
    template: `
      <div class="fullscreen-spinner" [style.background-color]="background">
        <div>
          <div class="inner-spinner">
            <div class="pej-spot-1" [style.background-color]="color"></div>
            <div class="pej-spot-2" [style.background-color]="color"></div>
          </div>
          <h1 [style.color]="color">{{title}}</h1>
        </div>
      </div>
    `,
})
export class FullscreenSpinnerComponent {
    @Input() public background: string = '#fff';
    @Input() public color: string = 'rgb(255, 221, 87)';
    @Input() public title: string = 'Loading';
}
