import {translateI18nFieldWithBackup} from '../core/locale';
import {mapArray} from '../utils/object-mapper';
import {MenuOptionItem} from './menu-option-item';
import {FirestoreObjectStringId} from './shared/firestore-object';
import {MenuOptionItemTypeEnum} from './shop';

export class MenuOptionGroup implements FirestoreObjectStringId {
    /** Read-only. ID of the Shop this object is defined in. This will be the ID
     * of the master shop if this is an inherited group. */
    definedIn: number;
    /** IDs are unique within the Shop. */
    id: string;
    /** `type=quantity`: The quantity of options that are selectable for free in
     * this group. */
    includedQuantity: number;
    /** `type=quantity`: The maximum value that is included for free in this
     * group. You may set this either together with includedQuantity to impose a
     * stricter free limit or by itself to limit by value alone. */
    includedValues: {[id: string]: number};
    /** The list of options in this group. See MenuOptionItem. */
    private _list: MenuOptionItem[];
    public get list(): MenuOptionItem[] {
        return this._list;
    }
    public set list(list: MenuOptionItem[]) {
        this._list = mapArray(list, MenuOptionItem);
    }
    /** `type=quantity`: The maximum quantity of options that are selectable. */
    maxQuantity: number;
    /** minimum required quantity */
    minimumRequiredQuantity: number;
    /** I18n name dictionary. */
    nameI18n: {[key: string]: string};
    /** I18n name map translated by the server according to Accept-Language as a
     * single string. */
    _name: string;
    shopId: number;
    /** Status of option group */
    status: MenuOptionGroupStatus;
    /** Timestamp (UNIX milliseconds) of when this object was last updated.
     * Useful to quickly determine if the object might have changed. */
    timeUpdated: number;
    /** Type is one of checkbox, radio, quantity. Defaults to checkbox. */
    type: MenuOptionItemTypeEnum;

    fromCache: boolean;

    public static setDefaults(obj) {
        if (obj._list == null) {
            obj._list = [];
        }
        if (obj.nameI18n == null) {
            obj.nameI18n = {};
        }
        if (obj.status == null) {
            obj.status = 'VISIBLE';
        }
        return obj;
    }

    public get name(): string {
        return translateI18nFieldWithBackup(this.nameI18n, this._name);
    }

    public set name(string: string) {
        this._name = string;
    }

    public setStringId(id) {
        this.id = id;
    }

    public trackingEquals(o: MenuOptionGroup) {
        return this.id === o.id && this.timeUpdated === o.timeUpdated;
    }

    public validate() {
        const violations = [];
        if (!this.id) {
            violations.push('No id');
        }
        if (!this.nameI18n['en']) {
            violations.push('No name');
        }
        if (violations.length === 0) {
            return true;
        }
        return violations;
    }
}

export type MenuOptionGroupStatus = null | 'VISIBLE' | 'HIDDEN';
