import {ApiError} from '../models/api-error';

export interface EtagResponse<T> {
    headers: {[key: string]: any};
    json: T;
    status: number;
}

/**
 * Callback function to pass as the onrejected argument to `fetch.then()`.
 * This will always reject with a key=network_error ApiError.
 */
export function handleError(error: string): never {
    throw ApiError.create('network_error', error);
}

/**
 * Callback function to pass as the onfulfilled argument to `fetch.then()`.
 * This function has support for HTTP 304 NOT MODIFIED and will resolve with an
 * EtagResponse on success.
 *
 * This function will translate the response as follows:
 *
 * For status:
 * 1xx                 Not supported. Reject with a key=not_supported ApiError.
 * 204 (No Content)    Success. Resolve {json: undefined, status: 204}.
 * 2xx (except 204)    Success. Resolve {json: the parsed JSON object}.
 * 304 (Not Modified)  Success. Resolve {json: undefined, status: 304}.
 * 3xx (except 304)    Not supported. Reject with a key=status_not_supported ApiError.
 * 4xx                 Error. Reject the parsed JSON as ApiError.
 * 5xx                 Error. Reject with a key=server_error ApiError.
 */
export function handleEtagResponse(response: Response): Promise<EtagResponse<any>> {
    const status = response.status;
    if (200 <= status && status < 300) {
        if (status !== 204) {
            return response.json().then(json =>
                ({json, headers: response.headers, status})
            );
        } else {
            return Promise.resolve({json: undefined, headers: response.headers, status});
        }
    } else if (status === 304) {
        return Promise.resolve({json: undefined, headers: response.headers, status});
    } else if (400 <= status && status < 500) {
        return response.json().then(json => { throw ApiError.create(json['key'], json['message']); });
    } else if (500 <= status) {
        throw ApiError.create('server_error', response.statusText);
    } else {
        throw ApiError.create('status_not_supported', response.statusText);
    }
}

/**
 * Callback function to pass as the onfulfilled argument to `fetch.then()`.
 *
 * This function will translate the response as follows:
 *
 * For status:
 * 1xx                 Not supported. Reject with a key=not_supported ApiError.
 * 204 (No Content)    Success. Resolve undefined.
 * 2xx (except 204)    Success. Resolve the parsed JSON object.
 * 3xx                 Not supported. Reject with a key=status_not_supported ApiError.
 * 4xx                 Error. Reject the parsed JSON as ApiError.
 * 5xx                 Error. Reject with a key=server_error ApiError.
 */
export function handleResponse(response: Response): Promise<any | undefined> {
    const status = response.status;
    if (200 <= status && status < 300) {
        if (status !== 204) {
            return response.json();
        } else {
            return Promise.resolve(undefined);
        }
    } else if (400 <= status && status < 500) {
        return response.json().then(json => { throw ApiError.create(json['key'], json['message']); });
    } else if (500 <= status) {
        throw ApiError.create('server_error', response.statusText);
    } else {
        throw ApiError.create('status_not_supported', response.statusText);
    }
}

/**
 * Callback function to pass as the onfulfilled argument to `fetch.then()`.
 *
 * This function will translate the response as follows:
 *
 * For status:
 * 1xx                 Not supported. Reject with a key=not_supported ApiError.
 * 204 (No Content)    Success. Resolve undefined.
 * 2xx (except 204)    Success. Resolve the response data.
 * 3xx                 Not supported. Reject with a key=status_not_supported ApiError.
 * 4xx                 Error. Reject the parsed JSON as ApiError.
 * 5xx                 Error. Reject with a key=server_error ApiError.
 */
export function handleBinaryResponse(response: Response): Promise<ArrayBuffer | undefined> {
    const status = response.status;
    if (200 <= status && status < 300) {
        if (status !== 204) {
            return response.arrayBuffer();
        } else {
            return Promise.resolve(undefined);
        }
    } else if (400 <= status && status < 500) {
        return response.json().then(json => { throw ApiError.create(json['key'], json['message']); });
    } else if (500 <= status) {
        throw ApiError.create('server_error', response.statusText);
    } else {
        throw ApiError.create('status_not_supported', response.statusText);
    }
}
