import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CartItem} from '../models';
import {ShopCart} from '../services/cart';
import {PejItemFirestoreService} from '../services/item-firestore';

/** Returns an observable of the cart age limit based on all items and menu option items */
export function  ageLimit(cart: ShopCart): Observable<number> {
    return cart.itemsObservable.pipe(
        map(cartItems => getAgeLimitAndItemIds(cartItems)),
        switchMap(v => combineLatest([
            of(v.ageLimit),
            ...Array.from(v.itemIds).map((itemId: number) => getAgeLimitFromItemId(cart.shopId, itemId))])),
        map(ageLimits => ageLimits.reduce((a, b) => Math.max(a, b), 0))
    );
}

/** Returns an observable of the item age limit */
function getAgeLimitFromItemId(shopId: number, itemId: number) {
    return PejItemFirestoreService.itemOnline(
        shopId,
        itemId
    ).pipe(map(item => item?.ageLimit ?? 0));
}


/** Returns the age limit of the item and a set of all item ids in menu option groups */
function getAgeLimitAndItemIds(cartItems: readonly CartItem[]) {
    const initial = { ageLimit: 0, itemIds: new Set() };
    return cartItems?.reduce((a, b) => ({
        ageLimit: Math.max(a.ageLimit, b.item?.ageLimit ?? 0),
        itemIds: new Set([...a.itemIds, ...getMenuOptionItemIds(b)])
    }), initial) ?? initial;
}

/** Returns a set of item ids containing any items linked in menu option groups. */
function getMenuOptionItemIds(cartItem: CartItem): Set<number> {
    const itemIdSet = new Set<number>();
    if (!cartItem.optionGroups) {
        return itemIdSet;
    };
    for (const cog of cartItem.optionGroups?.values()) {
        if (!cog.items) {
            continue;
        };
        cog.items.forEach(coi => {
            const itemId = coi.item?.itemId;
            if (itemId) {
                itemIdSet.add(itemId);
            };
        });
    };
    return itemIdSet;
}
