import {User} from '../user';

export class AuthInput {
    action: string;
    deltaExpires?: number;
    email: string;
    key: string;
    password: string;
    token: string;
    user: User;
    userId: string;
}
