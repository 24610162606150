import {FirestoreObjectStringId} from './shared/firestore-object';

export class PejAccounting implements FirestoreObjectStringId {
    id: string;
    accounts: PejAccount[];

    fromCache: boolean;
    setStringId(id: string): void {
        this.id = id;
    }
}

export class PejAccount {
    id: string;
    name: string;
    taxRate: number;
    type: PejAccountTypeEnum;

    constructor(id: string, name: string, taxRate: number, type: PejAccountTypeEnum) {
        this.id = id;
        this.name = name;
        this.taxRate = taxRate;
        this.type = type;
    }

    public static getAccountsById(id, shopAccounts) {
        const accounts = [];
        for (const account of shopAccounts) {
            if (account.id === id) {
                accounts.push(account);
            }
        }
        return accounts;
    }

    public static getAccountsByType(type, shopAccounts) {
        const accounts = [];
        for (const account of shopAccounts) {
            if (account.type === type) {
                accounts.push(account);
            }
        }
        return accounts;
    }

    public static getTaxAccountMap(shopAccounts) {
        const accountMap = {};
        for (const account of shopAccounts) {
            if (account.type === 'tax') {
                accountMap[account.taxRate] = account;
            }
        }
        return accountMap;
    }
}

export type PejAccountTypeEnum =
    'in_amount' |
    'fee' |
    'net' |
    'tax' |
    'wrap' |
    'tips' |
    'delivery' |
    'service';
