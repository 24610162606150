import {CollectionResponse} from '../models';
import {arrayRemove, isPromise} from '../utils';
import {EtagResponse} from '../utils/fetch';

// QUERYING FUNCTIONS
/**
 * Retrieves cached value from cache if possible, otherwise calls
 * `caller` with parameters `params`.
 *
 * The function handles paging using nextPageToken/cursor and more
 * items are requested by setting `more` to true.
 *
 * `cacheKey`s are expected to be limited to a finite set of alternatives to
 * guarantee that we will not leak memory.
 *
 * Data is considered stale after expire seconds.
 *
 * The reason for cache and cacheKey is that you cannot pass a String by
 * reference in JavaScript. We set cache[cacheKey] to a tuple with the
 * cached value.
 *
 * Parameters
 *   caller: The method to call to fetch the data. Must return a Promise!
 *   params: Params to pass to caller
 *   cache: Cache dictionary
 *   cacheKeyList: List of cached keys that is used to track what to eject
 *                 from the cache first
 *   cacheKey: Key to use in the cache dictionary
 *   limit: Number of items requested in the response
 *   more: Is the client requesting to load additional items?
 *   mapper: Function to use to transform the result into the desired class
 *           before caching it or null for no transformation
 *   expire: Cached object is considered expired if this many seconds have
 *           transpired since retrieval
 */
export function queryCollectionResponse<T>(
    caller: (params: Record<string, unknown>, etag?: string) => Promise<EtagResponse<CollectionResponse<T>>>,
    params: {[id: string]: any},
    cache: {[id: string]: any},
    cacheKey: string,
    limit?: number,
    more?: boolean,
    mapper?: (json: T[]) => T[],
    expire?: number
): CollectionResponse<T> | Promise<CollectionResponse<T>> {
    const lookup: [CollectionResponse<T>, number, string] | Promise<CollectionResponse<T>> = cache[cacheKey];
    /* if cache lookup gives us a promise, wait for this promise and then run this method again */
    if (isPromise(lookup)) {
        const p = lookup as Promise<CollectionResponse<T>>;
        const recurse = () => queryCollectionResponse<T>(caller, params, cache, cacheKey, limit, more, mapper, expire);
        return p.then(recurse, recurse);
    } else {
        const now = Math.floor(Date.now() / 1000);
        if (lookup == null || expired(lookup, now, expire)) {
            const etagLookup = lookup != null ? lookup[2] : null;
            // cloning since we want to keep limit and cursor out of params
            const withLimit = Object.assign({limit}, params);
            const p = caller(withLimit, etagLookup).then((response) => {
                if (response.status === 304) {
                    cache[cacheKey] = [lookup[0], now, etagLookup];
                    return lookup[0];
                } else {
                    const data = response.json;
                    const etag = response.headers != null ? response.headers.get('ETag') : null;
                    if (data.items == null) {
                        data.items = [];
                    }
                    if (mapper != null) {
                        data.items = mapper(data.items);
                    }
                    cache[cacheKey] = [data, now, etag];
                    return data;
                }
            }, (error) => {
                cache[cacheKey] = lookup; // keep last good value
                throw error;
            });
            cache[cacheKey] = p;
            return p;
        } else { /* we have a usable cached value */
            const collectionResponse = lookup[0];
            const cursor = collectionResponse.nextPageToken;
            if (more && cursor) {
                const withCursor = Object.assign({limit, cursor}, params);
                const p = caller(withCursor, null).then((response) => {
                    const data = response.json;
                    if (data.items !== undefined) {
                        const result = mapper != null ? mapper(data.items) : data.items;
                        collectionResponse.items.push(...result);
                    }
                    collectionResponse.nextPageToken = data.nextPageToken;
                    cache[cacheKey] = lookup; // was a promise
                    return collectionResponse;
                }, (error) => {
                    cache[cacheKey] = lookup; // keep last good value
                    throw error;
                });
                cache[cacheKey] = p;
                return p;
            } else {
                return lookup[0];
            }
        }
    }
}

/**
 * Retrieves cached value from cache if possible, otherwise calls
 * `caller` with parameters `params`.
 *
 * The function handles paging using nextPageToken/cursor and more
 * items are requested by setting `more` to true.
 *
 * `cacheKeyList` is a list of the n (`cacheLimit`) most recent calls. We
 * only cache the `cacheLimit` number of latest queries to avoid using an
 * infinite amount of memory.
 *
 * Data is considered stale after expire seconds.
 *
 * The reason for cache and cacheKey is that you cannot pass a String by
 * reference in JavaScript. We set cache[cacheKey] to a tuple with the
 * cached value.
 *
 * Parameters
 *   caller: The method to call to fetch the data. Must return a Promise!
 *   params: Params to pass to caller
 *   cache: Cache dictionary
 *   cacheKeyList: List of cached keys that is used to track what to eject
 *                 from the cache first
 *   cacheKey: Key to use in the cache dictionary
 *   limit: Number of items requested in the response
 *   more: Is the client requesting to load additional items?
 *   mapper: Function to use to transform the result into the desired class
 *           before caching it or null for no transformation
 *   expire: Cached object is considered expired if this many seconds have
 *           transpired since retrieval
 *   cacheLimit: the number of items allowed in `cacheKeyList` before starting
 *               to eject items from the cache
 */
export function queryCollectionResponseLimitCache<T>(
    caller: (params: Record<string, unknown>, etag?: string) => Promise<EtagResponse<CollectionResponse<T>>>,
    params: {[id: string]: any},
    cache: {[id: string]: any},
    cacheKeyList: string[],
    cacheKey: string,
    limit?: number,
    more?: boolean,
    mapper?: (json: T[]) => T[],
    expire?: number,
    cacheLimit?: number
): CollectionResponse<T> | Promise<CollectionResponse<T>> {
    const lookup: [CollectionResponse<T>, number, string] | Promise<CollectionResponse<T>> = cache[cacheKey];
    /* if cache lookup gives us a promise, wait for this promise and then run this method again */
    if (isPromise(lookup)) {
        const p = lookup as Promise<CollectionResponse<T>>;
        const recurse = () =>
            queryCollectionResponseLimitCache<T>(caller, params, cache, cacheKeyList, cacheKey, limit, more, mapper, expire, cacheLimit);
        return p.then(recurse, recurse);
    } else {
        const now = Math.floor(Date.now() / 1000);
        if (lookup == null || expired(lookup, now, expire)) {
            const etagLookup = lookup != null ? lookup[2] : null;
            // cloning since we want to keep limit and cursor out of params
            const withLimit = Object.assign({limit}, params);
            const p = caller(withLimit, etagLookup).then((response) => {
                if (response.status === 304) {
                    cache[cacheKey] = [lookup[0], now, etagLookup];
                    arrayRemove(cacheKeyList, cacheKey);
                    cacheKeyList.push(cacheKey); // put `cacheKey` first in list
                    return lookup[0];
                } else {
                    const data = response.json;
                    const etag = response.headers != null ? response.headers.get('ETag') : null;
                    if (data.items == null) {
                        data.items = [];
                    }
                    if (mapper != null) {
                        data.items = mapper(data.items);
                    }
                    cache[cacheKey] = [data, now, etag];
                    cacheKeyList.push(cacheKey);
                    return data;
                }
            }, (error) => {
                if (lookup != null && cacheKeyList.indexOf(cacheKey) !== -1) {
                    cache[cacheKey] = lookup; // keep last good value
                } else {
                    delete cache[cacheKey];
                }
                throw error;
            });
            cache[cacheKey] = p;
            // async: clear up space if needed after starting query
            cacheLimit = cacheLimit || 100;
            if (cacheKeyList.length >= cacheLimit) {
                const oldestKey = cacheKeyList.shift();
                delete cache[oldestKey];
            }
            return p;
        } else { /* we have a usable cached value */
            const collectionResponse = lookup[0];
            const cursor = collectionResponse.nextPageToken;
            if (more && cursor) {
                const withCursor = Object.assign({limit, cursor}, params);
                const p = caller(withCursor, null).then((response) => {
                    const data = response.json;
                    if (data.items !== undefined) {
                        const result = mapper != null ? mapper(data.items) : data.items;
                        collectionResponse.items.push(...result);
                    }
                    collectionResponse.nextPageToken = data.nextPageToken;
                    cache[cacheKey] = lookup; // was a promise
                    arrayRemove(cacheKeyList, cacheKey);
                    cacheKeyList.push(cacheKey); // put `cacheKey` first in list
                    return collectionResponse;
                }, (error) => {
                    cache[cacheKey] = lookup; // keep last good value
                    throw error;
                });
                cache[cacheKey] = p;
                return p;
            } else {
                arrayRemove(cacheKeyList, cacheKey);
                cacheKeyList.push(cacheKey); // put `cacheKey` first in list
                return lookup[0];
            }
        }
    }
}

/**
 * Do a call to `caller` and cache it as `cache`[`cacheKey`].
 *
 * Data is considered stale after expire seconds.
 *
 * The reason for cache and cacheKey is that you cannot pass a String by
 * reference in JavaScript. We set cache[cacheKey] to a tuple with the
 * cached value.
 *
 * Parameters
 *   caller: The method to call to fetch the data. Must return a Promise!
 *   cache: Cache dictionary
 *   cacheKey: Key to use in the cache dictionary
 *   mapper: Function to use to transform the result into the desired class
 *           before caching it or null for no transformation
 *   expire: Cached object is considered expired if this many seconds have
 *           transpired since retrieval
 */
export function queryObject<T>(
    caller: (etag: string) => Promise<EtagResponse<T>>,
    cache: {[id: string]: any},
    cacheKey: string,
    mapper?: (json: T) => T,
    expire?: number
): T | Promise<T> {
    const lookup: [T, number, string] | Promise<T> = cache[cacheKey];
    /* if cache lookup gives us a promise, wait for this promise and then run this method again */
    if (isPromise(lookup)) {
        const p = lookup as Promise<T>;
        const recurse = () => queryObject<T>(caller, cache, cacheKey, mapper, expire);
        return p.then(recurse, recurse);
    } else {
        const now = Math.floor(Date.now() / 1000);
        if (lookup == null || expired(lookup, now, expire)) {
            const etagLookup = lookup != null ? lookup[2] : null;
            const p = caller(etagLookup).then((response) => {
                if (response.status === 304) {
                    cache[cacheKey] = [lookup[0], now, etagLookup];
                    return lookup[0];
                } else {
                    const data = response.json;
                    const etag = response.headers != null ? response.headers.get('ETag') : null;
                    const result = mapper != null ? mapper(data) : data;
                    cache[cacheKey] = [result, now, etag];
                    return result;
                }
            }, (error) => {
                cache[cacheKey] = lookup; // keep last good value
                throw error;
            });
            cache[cacheKey] = p;
            return p;
        } else {
            return lookup[0];
        }
    }
}

export function callRecursive<T>(
    caller: (limit: number, more: boolean, expire: number) => Promise<CollectionResponse<T>>,
    limit: number,
    expire: number,
    more?: boolean
): Promise<T[]> {
    return caller(limit, more, expire).then(response => {
        if (response.nextPageToken && response.items.length < limit) {
            return callRecursive(caller, limit, null, true);
        } else {
            return response.items;
        }
    });
}

// CACHE GET/UPDATE/REMOVE FUNCTIONS
/** Gets a cached object from outside the QueryFactory. */
export function getFromCache<T>(cache: {[id: string]: any}, cacheKey: string) {
    const lookup: [T, number, string] | Promise<any> = cache[cacheKey];
    if (lookup !== undefined && !isPromise(lookup)) {
        return lookup[0];
    }
    return undefined;
}

/** Gets a cached object from outside the QueryFactory. */
export function getTupleFromCache<T>(cache: {[id: string]: any}, cacheKey: string) {
    const lookup: [T, number, string] | Promise<any> = cache[cacheKey];
    if (lookup !== undefined && !isPromise(lookup)) {
        return lookup;
    }
    return undefined;
}

/** Remove `cache`[`cacheKey`] from outside the QueryFactory. */
export function removeFromCache(cache: {[id: string]: any}, cacheKey: string) {
    delete cache[cacheKey];
}

/** Removes an item from inside a cached array from outside the QueryFactory.
 *
 *  `cache`[`cacheKey`] must point to an array or null entry. */
export function removeFromArrayInCache<T>(cache: {[id: string]: any}, cacheKey: string, cmp: (obj: T) => boolean) {
    const lookup: [T[], number, string] | Promise<any> = cache[cacheKey];
    if (lookup != null && !isPromise(lookup)) {
        // noinspection UnnecessaryLocalVariableJS
        const now = Math.floor(Date.now() / 1000);
        lookup[1] = now;
        lookup[2] = null;
        const items = lookup[0];
        if (items == null) {
            return null;
        }
        if (cmp != null) {
            for (let i = 0; i < items.length; i++) {
                const obj = items[i];
                if (cmp(obj)) {
                    items.splice(i, 1);
                    return items;
                }
            }
        }
        return items;
    } else {
        return null;
    }
}

/** Removes an item from inside a cached CollectionResponse from outside the
 *  QueryFactory.
 *
 *  `cache`[`cacheKey`] must point to a CollectionResponse or null entry. */
export function removeFromCollectionResponseInCache<T>(cache: {[id: string]: any}, cacheKey: string, cmp: (obj: T) => boolean) {
    const lookup: [CollectionResponse<T>, number, string] | Promise<any> = cache[cacheKey];
    if (lookup != null && !isPromise(lookup)) {
        // noinspection UnnecessaryLocalVariableJS
        const now = Math.floor(Date.now() / 1000);
        lookup[1] = now;
        lookup[2] = null;
        const collectionResponse = lookup[0];
        if (collectionResponse == null || collectionResponse.items == null) {
            return null;
        }
        const items = collectionResponse.items;
        for (let i = 0; i < items.length; i++) {
            const obj = items[i];
            if (cmp(obj)) {
                items.splice(i, 1);
                return collectionResponse;
            }
        }
        return collectionResponse;
    } else {
        return null;
    }
}

/** Removes an item from inside a cached array from outside the QueryFactory.
 *
 *  `cache`[`cacheKey`] must point to an array or null entry. */
export function removeFromMapInCache<T>(cache: {[id: string]: any}, cacheKey: string, key: string) {
    const lookup: [{[key: string]: T}, number, string] | Promise<any> = cache[cacheKey];
    if (lookup != null && !isPromise(lookup)) {
        // noinspection UnnecessaryLocalVariableJS
        const now = Math.floor(Date.now() / 1000);
        lookup[1] = now;
        lookup[2] = null;
        const items = lookup[0];
        if (items == null) {
            return null;
        }
        delete items[key];
        return items;
    } else {
        return null;
    }
}

/** Set `cache`[`cacheKey`] from outside the QueryFactory. */
export function setInCache<T>(cache: {[id: string]: any}, cacheKey: string, data: T, etag?: string) {
    const now = Math.floor(Date.now() / 1000);
    cache[cacheKey] = [data, now, etag];
    return data;
}

/** Sets an item inside a cached map from outside the QueryFactory.
 *
 *  `cache`[`cacheKey`] must point to a map or null entry. */
export function setInMapInCache<T>(cache: {[id: string]: any}, cacheKey: string, key: string, data: T) {
    const lookup: [{[key: string]: T}, number, string] | Promise<any> = cache[cacheKey];
    if (lookup != null && !isPromise(lookup)) {
        // noinspection UnnecessaryLocalVariableJS
        const now = Math.floor(Date.now() / 1000);
        lookup[1] = now;
        lookup[2] = null;
        const items = lookup[0];
        if (items == null) {
            return null;
        }
        items[key] = data;
        return items;
    } else {
        return null;
    }
}

/** Updates an item inside a cached array from outside the QueryFactory.
 *
 *  `cache`[`cacheKey`] must point to an array or null entry. */
export function updateInArrayInCache<T>(cache: {[id: string]: any}, cacheKey: string, data: T, cmp?: (obj: T) => boolean) {
    const lookup: [T[], number, string] | Promise<any> = cache[cacheKey];
    if (lookup != null && !isPromise(lookup)) {
        // noinspection UnnecessaryLocalVariableJS
        const now = Math.floor(Date.now() / 1000);
        lookup[1] = now;
        lookup[2] = null;
        const items = lookup[0];
        if (items == null) {
            return null;
        }
        if (cmp != null) {
            for (let i = 0; i < items.length; i++) {
                const obj = items[i];
                if (cmp(obj)) {
                    items[i] = data;
                    return items;
                }
            }
        }
        items.push(data);
        return items;
    } else {
        return null;
    }
}

/** Updates an item inside a cached CollectionResponse from outside the
 *  QueryFactory.
 *
 *  `cache`[`cacheKey`] must point to a CollectionResponse or null entry. */
export function updateInCollectionResponseInCache<T>(cache: {[id: string]: any}, cacheKey: string, data: T, cmp?: (obj: T) => boolean) {
    const lookup: [CollectionResponse<T>, number, string] | Promise<any> = cache[cacheKey];
    if (lookup != null && !isPromise(lookup)) {
        // noinspection UnnecessaryLocalVariableJS
        const now = Math.floor(Date.now() / 1000);
        lookup[1] = now;
        const collectionResponse = lookup[0];
        if (collectionResponse == null || collectionResponse.items == null) {
            return null;
        }
        const items = collectionResponse.items;
        if (cmp != null) {
            for (let i = 0; i < items.length; i++) {
                const obj = items[i];
                if (cmp(obj)) {
                    items[i] = data;
                    return collectionResponse;
                }
            }
        }
        items.push(data);
        return collectionResponse;
    } else {
        return null;
    }
}


// UTILITY FUNCTIONS
export function queryAsPromise<T>(r: T | Promise<T>): Promise<T> {
    return isPromise(r) ? r : Promise.resolve(r);
}


// HELPER FUNCTIONS
/** Parameters
 *    lookup: Cache entries as tuples of (the object, timestamp (seconds), etag)
 *    now: Current time in seconds
 *    expire: Cached object is considered expired if this many seconds have
 *            transpired since retrieval
 */
function expired<T>(lookup: [T, number, string], now: number, expire: number): boolean {
    const expireSafe = expire != null ? expire : 3600; // 1 hour default
    return now >= lookup[1] + expireSafe;
}
