<div class="callback-message">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-sm-8 col-md-6">
        <h1>{{header}}</h1>
        <p class="large">{{body}}</p>
        <p class="small">
          Hör av dig till oss om du har några frågor:
          <br>
          <br>
          <strong>John Elf</strong><br>
          <a href="mailto:john@pej.se">john@pej.se</a><br>
          +46&nbsp;(0)70-327&nbsp;23&nbsp;43
          <br>
          <br>
          <strong>Joel Oredsson</strong><br>
          <a href="mailto: joel@pej.se">joel@pej.se</a><br>
          +46&nbsp;(0)73-786&nbsp;31&nbsp;32.
        </p>
      </div>
    </div>
  </div>
</div>
