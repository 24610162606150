import {FlavorConstants} from './flavor-constants';

export class Constants {
    public static readonly TEST = FlavorConstants.TEST;

    public static readonly PEJ_API_KEY = 'Wo5LIYjts180wzUd3NDs1TDdbdmh8we6rLlOeV2JOv4OuNAGuDCNxZn6e9FNuU3o';
    public static readonly PEJ_API_VERSION = 'v1.1';

    //private static readonly ROOT_URL_LIVE = 'https://dev-dot-luckan-api.appspot.com/';
    private static readonly ROOT_URL_LIVE = 'https://api.pej.se/';
    //private static readonly ROOT_URL_TEST = 'https://dev-dot-luckan-api-test.appspot.com/';
    private static readonly ROOT_URL_TEST = 'https://sandbox-api.pej.se/';
    public static readonly ROOT_URL = !Constants.TEST ? Constants.ROOT_URL_LIVE : Constants.ROOT_URL_TEST;

    private static readonly FIREBASE_API_KEY_LIVE = 'AIzaSyAI-fMxl7hUlEIPM_DAsSN_yUF9REzKXqU';
    private static readonly FIREBASE_API_KEY_TEST = 'AIzaSyCoFxk8a0e1BrvpMlNWqEgQtPFytHfFdo8';
    public static readonly FIREBASE_API_KEY = !Constants.TEST ? Constants.FIREBASE_API_KEY_LIVE : Constants.FIREBASE_API_KEY_TEST;
    private static readonly FIREBASE_PROJECT_ID_LIVE = 'pej-eu';
    private static readonly FIREBASE_PROJECT_ID_TEST = 'pej-sandbox';
    public static readonly FIREBASE_PROJECT_ID = !Constants.TEST ? Constants.FIREBASE_PROJECT_ID_LIVE : Constants.FIREBASE_PROJECT_ID_TEST;

    private static readonly GOOGLE_ANALYTICS_MEASUREMENT_ID_LIVE = 'G-TFN5J3MRJR';
    private static readonly GOOGLE_ANALYTICS_MEASUREMENT_ID_TEST = 'G-3CXLT7F8YC';
    public static readonly GOOGLE_ANALYTICS_MEASUREMENT_ID = !Constants.TEST ? Constants.GOOGLE_ANALYTICS_MEASUREMENT_ID_LIVE : Constants.GOOGLE_ANALYTICS_MEASUREMENT_ID_TEST;

    private static readonly STRIPE_KEY_LIVE = 'pk_live_abvMCYsVmdLhZSlCrdAKWDjo';
    private static readonly STRIPE_KEY_TEST = 'pk_test_ZsfgpZFnPeoGZYbh7Akmiu7c';
    public static readonly STRIPE_KEY = !Constants.TEST ? Constants.STRIPE_KEY_LIVE : Constants.STRIPE_KEY_TEST;

    public static readonly SCREEN_XS = 480;
    public static readonly SCREEN_SM = 768;
    public static readonly SCREEN_MD = 992;
    public static readonly SCREEN_LG = 1200;
    public static readonly SCREEN_XL = 1400;
    public static readonly SCREEN_XXL = 1700;
}
