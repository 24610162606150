import {ViewportScroller} from '@angular/common';
import {Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, ActivationEnd, Event, Router, Scroll} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {of, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {PejGroupWeb, PejShopWeb} from 'pej-sdk';
import {PejConfig} from 'pej-sdk/core/config';
import {PejFirebaseConfig} from 'pej-sdk/core/firebase-config';
import {PejGroupFirestoreService} from 'pej-sdk/services/group-firestore';
import {PejShopFirestoreService} from 'pej-sdk/services/shop-firestore';
import {googleMapsWrapper} from 'pej-sdk/wrappers/google-maps';
import {stripeWrapper} from 'pej-sdk/wrappers/stripe';
import {Constants} from './constants';
import {LanguageService} from './services/language.service';
import {PageStateService} from './services/page-state.service';
import {getRecursiveDataParam} from './utils/router-utils';
import {customCssSet} from '../../../pej-ng-shared/src/utils/style-utils';

@Component({
    selector: 'app',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    private customCssGroupElement: Element;
    private customCssShopElement: Element;
    private disposables: Subscription[] = [];

    constructor(private activatedRoute: ActivatedRoute,
                private pageStateService: PageStateService,
                private renderer: Renderer2,
                private router: Router,
                private viewportScroller: ViewportScroller,
                deviceService: DeviceDetectorService,
                languageService: LanguageService) {
        // add classes so we can identify operating system and browser in CSS
        const deviceInfo = deviceService.getDeviceInfo();
        const os = deviceInfo.os;
        const browser = deviceInfo.browser;
        renderer.addClass(document.body, `os-${os}`);
        renderer.addClass(document.body, `browser-${browser}`);
        PejConfig.apiKey = Constants.PEJ_API_KEY;
        PejConfig.rootUrl = `${Constants.ROOT_URL}${Constants.PEJ_API_VERSION}/`;
        PejFirebaseConfig.apiKey = Constants.FIREBASE_API_KEY;
        PejFirebaseConfig.projectId = Constants.FIREBASE_PROJECT_ID;
        googleMapsWrapper.addLibrary('places');
        googleMapsWrapper.setKey('AIzaSyBlHZ9DtF1zpMawB7-cG7cMV2PtZ3obmXk');
        stripeWrapper.setPublishableKey(Constants.STRIPE_KEY);
        languageService.setup();
    }

    public ngOnInit() {
        const pageScrollPositionDisposable = this.router.events
            .pipe(filter((event: Event): event is Scroll => event instanceof Scroll))
            .pipe(switchMap(event => {
                // wait for OK from PageScrollAfterResolveService if needed
                return this.pageStateService.whenReady().pipe(map(() => event));
            }))
            .subscribe(event => {
                if (event.position) { // backward navigation
                    this.viewportScroller.scrollToPosition(event.position);
                } else if (event.anchor) { // anchor navigation
                    this.viewportScroller.scrollToAnchor(event.anchor);
                } else { // forward navigation
                    this.viewportScroller.scrollToPosition([0, 0]);
                }
            });
        this.disposables.push(pageScrollPositionDisposable);

        const customCssGroupDisposable = this.router.events
            .pipe(
                filter(event => event instanceof ActivationEnd),
                map(() => getRecursiveDataParam(this.activatedRoute, 'customCssGroup')?.id),
                distinctUntilChanged(), // only propagate if the ID is new
                switchMap(groupWebId => groupWebId != null ?
                    // this will keep firing on updates from remote
                    PejGroupFirestoreService.groupWeb(groupWebId) :
                    of(undefined)
                ),
                map((obj: PejGroupWeb | undefined) => obj?.css),
                distinctUntilChanged(), // only propagate if the CSS (string) is new
            )
            .subscribe((css: string | undefined) => {
                this.customCssGroupElement = customCssSet(this.renderer, this.customCssGroupElement, css);
            });
        this.disposables.push(customCssGroupDisposable);

        const customCssShopDisposable = this.router.events
            .pipe(
                filter(event => event instanceof ActivationEnd),
                map(() => getRecursiveDataParam(this.activatedRoute, 'customCssShop')?.id),
                distinctUntilChanged(), // only propagate if the ID is new
                switchMap(shopWebId => shopWebId != null ?
                    // this will keep firing on updates from remote
                    PejShopFirestoreService.shopWeb(shopWebId) :
                    of(undefined)
                ),
                map((obj: PejShopWeb | undefined) => obj?.css),
                distinctUntilChanged(), // only propagate if the CSS (string) is new
            )
            .subscribe((css: string | undefined) => {
                this.customCssShopElement = customCssSet(this.renderer, this.customCssShopElement, css);
            });
        this.disposables.push(customCssShopDisposable);
    }

    public ngOnDestroy() {
        this.disposables.forEach(subscription => subscription.unsubscribe());
        this.disposables.length = 0;
    }
}
