import {translateI18nField} from '../core/locale';
import {stripDuplicatesFromSortedArray} from '../utils/array';
import {mapArray, mapObject} from '../utils/object-mapper';
import {OrderItem} from './order-item';
import {OrderOffer} from './order-offer';
import {OrderInputServiceCharge, OrderServiceCharge} from './order-service-charge';
import {PlaceSpec} from './place-spec';
import {PosReceipt} from './pos-receipt';
import {ServiceChargeTotal} from './service-charge-total';
import {Address} from './shared/address';
import {DeliveryOptionsEnum, Shop} from './shop';
import {User} from './user';

export class Order {
    /** Delivery address. */
    address: Address;
    barcodes: OrderBarcode[];
    chargeIds;
    currency: string;
    customer: User;
    customerId: number;
    deliveryFee: number;
    deliveryOption: DeliveryOptionsEnum;
    deliveryPoint: string;
    errorMessage: string;
    eventId: string;
    expectedResponseTime: number;
    fsgmGuestId: string;
    groupId: string;
    id: number;
    items: OrderItem[];
    layout: string;
    /** Message to the merchant. */
    message: string;
    /** Unvalidated JSON metadata attached to the object */
    metadata: {[key: string]: any};
    netsPaymentUrl: string;
    offers: OrderOffer[];
    originalTotal: number;
    partialAccepted: boolean;
    partySize: number;
    paymentIntentSecret: string;
    paymentOption: string[];
    placeIds: string[];
    places: PlaceSpec[];
    qr: string;
    receipts: PosReceipt[];
    refunds: PosReceipt[];
    secret: string;
    serviceCharges: OrderServiceCharge[];
    serviceChargeTotals: ServiceChargeTotal[];
    shopIds: number[];
    shops: Shop[];
    status: OrderStatusEnum;
    statusHistory: [StatusHistoryItem];
    swishPaymentRequestToken: string;
    tax: number;
    timeCreated: number;
    timeDelivery: number;
    timeFinal: number;
    timeUpdated: number;
    tipAmount: number;
    total: number;
    verification: number;
    vippsPaymentUrl: string;

    public revive() {
        if (this.barcodes) {
            this.barcodes = mapArray(this.barcodes, OrderBarcode);
        }
        if (this.customer) {
            this.customer = mapObject(this.customer, User);
        }
        if (this.items) {
            this.items = mapArray(this.items, OrderItem);
            this.serviceChargeTotals = this.getServiceChargeTotals();
        }
        if (this.places) {
            this.places = mapArray(this.places, PlaceSpec);
        }
        if (this.serviceCharges) {
            this.serviceCharges = mapArray(this.serviceCharges, OrderServiceCharge);
        }
    }

    public getInfoLinks() {
        return this.places?.flatMap(place => place.orderInfoLinks ?? []);
    }

    public getItemById(orderItemId: number) {
        for (const item of this.items) {
            if (item.id === orderItemId) {
                return item;
            }
        }
        return null;
    }

    private getServiceChargeTotals() {
        const serviceChargeTotalsMap = new Map();
        for (const item of this.items) {
            if (!item.serviceCharges) {
                continue;
            }
            for (const orderServiceCharge of item.serviceCharges) {
                const serviceChargeId = orderServiceCharge.serviceChargeId;
                const total = orderServiceCharge.total;
                if (!total) {
                    continue;
                }
                const scInMap = serviceChargeTotalsMap.get(serviceChargeId);
                if (!scInMap) {
                    serviceChargeTotalsMap.set(serviceChargeId, mapObject({
                        serviceChargeId,
                        nameI18n: orderServiceCharge.nameI18n,
                        total,
                    }, ServiceChargeTotal));
                    continue;
                }
                serviceChargeTotalsMap.get(serviceChargeId).total += total;
            }
        }
        return [...serviceChargeTotalsMap.values()];
    }

    public getUniquePlaceNames() {
        if (this.places != null && this.places.length > 0) {
            const names = this.places.map(place => place.name);
            return stripDuplicatesFromSortedArray(names);
        }
        return [this.deliveryPoint];
    }

    public hasReservedFunds() {
        return this.status === 'wait_shop_confirm' ||
            this.status === 'wait_charge_capture' ||
            this.status === 'wait_packaging' ||
            this.status === 'wait_delivery_pickup' ||
            this.status === 'delivered' ||
            this.status === 'partially_refunded';
    }

    public isCancelled() {
        return this.status === 'customer_cancelled' || this.status === 'shop_rejected' || this.status === 'shop_timedout';
    }

    public setStringId(id: string) {
        this.id = Number(id);
    }

    public trackingEquals(o: Order) {
        return this.id === o.id && this.status === o.status && this.timeUpdated === o.timeUpdated;
    }
}

export class OrderRefund {
    item: OrderItem[];
    receipt: PosReceipt;
}

export class StatusHistoryItem {
    shopId: number;
    status: OrderStatusEnum;
    timestamp: number;

    public isCustomerStatus() {
        return this.status === 'created' || this.status === 'charge_authed' || this.status === 'customer_cancelled';
    }
}

export class OrderInput {
    /** Mandatory for business invoice. Currently not used anywhere in
     * production. */
    businessEntityId: string;
    currency: string;
    /** Mandatory for web orders without a User. */
    customer: User;
    /** Used for orders to a specific event. */
    eventId: string;
    /** List of items included in the order. */
    items: OrderInputItem[];
    /** Message to the merchant. Currently not used anywhere in production. */
    message: string;
    /** List of IDs of offers to apply to the order. */
    offerIds: number[];
    /** Does the customer accept an order where some of the items are rejected?
     * Defaults to true. */
    partialAccepted: boolean;
    /** Used for booking to determine number of places to book. */
    partySize: number;
    /** Which payment option to use.
     *
     * Currently acceptable values: stripe, stripe_payment_intent, swish
     *
     * Defaults to stripe. */
    paymentOption: string;
    /** A list of IDs of the selected places. (Delivery group ID followed by
     * delivery place ID). */
    placeIds: string[];
    /** Mandatory for Merchant-created orders. Set to the ID of the POS the order
     * is created from. Currently not used anywhere in production. */
    posId: string;
    /** Promo code to apply to the order. */
    promoCode: string;
    /** Mandatory for Merchant-created orders. Must be "shop". Currently not used
     * anywhere in production. */
    role: string;
    serviceCharges: OrderInputServiceCharge[];
    /** ID of the shop the order is placed at. */
    shopId: number;
    /** Used for testing Swish error cases only. */
    swishMessage: string;
    /** Used for Swish E-commerce where a phone number is provided at creation. */
    swishPayerAlias: string;
    /** Order tags for statistical purposes. */
    tags: string[];
    /** Timestamp (UNIX milliseconds) of the time the order is requested to or
     * null for ASAP. */
    timeRequested: number;
    /** How much the customer wishes to tip. Entered as the lowest currency unit
     * (cents, öre). */
    tipAmount: number;
    vippsMessage: string;
}

export class OrderInputItem {
    /** This field may be set instead of itemKey if shopId is set in OrderInput.
     * (Experimental). */
    itemId?: number;
    /** Item.key of the item being ordered. */
    itemKey: string;
    /** Menu options selected for this item. */
    options: OrderInputItemOption[];
    /** Quantity being ordered. */
    quantity: number;
}

export class OrderInputItemOption {
    /** ID of the MenuOptionGroup the selected item belongs to. */
    group: string;
    /** ID of the MenuOptionItem selected. */
    id: string;
    /** Quantity of the selected option. Defaults to 1. */
    quantity: number;
    /** ID of linked item if type is 'item' */
    itemId?: number;
}

export type OrderStatusEnum = 'created' |
    'wait_customer_confirm' |
    'wait_charge_auth' |
    'wait_3d_secure' |

    // STATUSES AFTER RESERVED FUNDS
    'wait_shop_confirm' |
    'wait_charge_capture' |
    'wait_packaging' |
    'wait_delivery_pickup' |
    'delivered' |

    // CANCELLED STATUSES
    /** the customer cancels the order. Currently not decided in which steps this is allowed */
    'customer_cancelled' |
    /** The Shop cancels the order. This is a response to WAIT_SHOP_CONFIRM only. */
    'shop_rejected' |
    /** The Shop has failed to approve the order in time. This is a response to WAIT_SHOP_CONFIRM only */
    'shop_timedout' |
    /** The order was rejected by the delivery service */
    'delivery_rejected' |

    // REFUNDED STATUSES
    'partially_refunded' |
    'refunded' |

    // HISTORY-ONLY STATUSES
    /** funds have been reserved */
    'charge_authed' |
    'shop_confirmed' |
    /** reserved funds collected */
    'charge_captured' |
    'packaged' |
    'delivery_picked_up' |
    /** there was an error while trying to allocate funds */
    'three_d_secure_failed';

export type OrderStatusQueryEnum = 'active' |
    'completed';

export class OrderBarcode {
    descriptionI18n: {[key: string]: string};
    format: string;
    value: string;

    public get description() {
        return translateI18nField(this.descriptionI18n);
    }
    public set description(_: string) {}
}
