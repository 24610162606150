import { Injectable } from "@angular/core";
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class SwedishDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            let monthString = month + '';
            if (monthString.length < 2) {
                monthString = '0' + monthString;
            }
            let dayString = day + '';
            if (dayString.length < 2) {
                dayString = '0' + dayString;
            }
            return `${year}-${monthString}-${dayString}`;
        } else {
            return date.toDateString();
        }
    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}

export const SWEDISH_DATE_FORMATS = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    },
    display: {
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'numeric'},
        dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    },
};
