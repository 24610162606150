import {mapArray, mapObject} from '../../utils';
import {MenuOptionGroup} from '../menu-option-group';
import {CartItemOption} from './cart-item-option';

export class CartItemOptionGroup {
    public currency: Readonly<string>;

    private _group: Readonly<MenuOptionGroup>;
    public get group(): Readonly<MenuOptionGroup> {
        return this._group;
    }
    public set group(group: Readonly<MenuOptionGroup>) {
        this._group = mapObject(group, MenuOptionGroup);
    }

    private _items: Array<CartItemOption>;
    public get items(): Array<CartItemOption> {
        return this._items;
    }
    public set items(items: Array<CartItemOption>) {
        this._items = mapArray(items as CartItemOption[], CartItemOption);
    }

    public maxed = false;

    /** Calculated when needed in `get price()`. Assumes the object is used
     *  immutably. */
    private _price: number;
    public get price(): number {
        if (this._price == null) {
            this._price = this.calcPrice();
        }
        return this._price;
    }
    public set price(price: number) { }

    public quantity: number;

    /**
     * Create CartItemOptionGroup from MenuOptionGroup and populate `items`
     * with all available CartItemOptions.
     */
    public static create(group: Readonly<MenuOptionGroup>, currency: Readonly<string>): CartItemOptionGroup {
        const obj = new CartItemOptionGroup();
        obj.currency = currency;
        obj.group = group;
        obj.items = group.list.map(moi => CartItemOption.create(group, moi, (moi.precheck && (!moi.status || moi.status === 'for_sale')) ? 1 : 0, currency));
        obj.updateGroupMax();
        return obj;
    }

    /**
     * Returns a new CartItemOptionGroup with the quantities from `group`
     * applied.
     */
    public patchQuantitiesForGroup(inputGroup: CartItemOptionGroup): CartItemOptionGroup {
        let resultItems: CartItemOption[];
        if (this.group.type === 'radio' && this.items.length > 0) {
            let selected: CartItemOption = null;
            for (const inputOption of inputGroup.items) {
                if (inputOption.getQuantitySafe() > 0) {
                    selected = inputOption;
                    break; // only one ID may have quantity > 0
                }
            }
            if (selected != null) {
                const item = CartItemOption.create(this.group, selected.item, 1, this.currency);
                resultItems = [item]; // selection changed
            } else {
                resultItems = this.items; // no change
            }
        } else { // checkbox or quantity
            resultItems = [];
            const inputItems = [...inputGroup.items]; // clone
            outer: for (const option of this.items) {
                for (let i = 0; i < inputItems.length;) {
                    const inputOption = inputItems[i];
                    if (option.id === inputOption.id) {
                        resultItems.push(inputOption);
                        inputItems.splice(i, 1);
                        continue outer;
                    } else {
                        i++;
                    }
                }
                resultItems.push(option); // no change
            }
            resultItems.push(...inputItems);
        }
        const obj = new CartItemOptionGroup();
        obj.currency = this.currency;
        obj.group = this.group;
        obj.items = resultItems;
        obj.updateGroupMax();
        return obj;
    }

    /**
     * Returns a new CartItemOptionGroup with the quantities from `groups`
     * applied.
     * <p>
     * If groups contain no groups with matching `id` this will do nothing.
     * <p>
     * Items not included will be set to 0.
     */
    public putQuantitiesForGroups(groups: CartItemOptionGroup[]): CartItemOptionGroup {
        const matchingGroups = groups.filter(group => this.group.id === group.group.id);
        if (matchingGroups.length === 0) { return this; }
        const resultItems: CartItemOption[] = [];
        for (const option of this.items) {
            let quantity = 0;
            for (const inputGroup of matchingGroups) {
                for (const inputOption of inputGroup.items) {
                    if (option.id === inputOption.id) {
                        quantity = inputOption.quantity;
                    }
                }
            }
            resultItems.push(CartItemOption.create(option.group, option.item, quantity, this.currency));
        }
        const obj = new CartItemOptionGroup();
        obj.currency = this.currency;
        obj.group = this.group;
        obj.items = resultItems;
        obj.ensureRadioGroupSelection();
        obj.updateGroupMax();
        return obj;
    }

    private calcPrice(): number {
        let amount = 0;

        if (this.group == null || this.group.id == null || this.group.type == null) { // legacy / group can't be found
            for (const optionItem of this.items) {
                amount += optionItem.getQuantitySafe() * optionItem.getPriceSafe();
            }
            return amount;
        }

        const includedValue = this.group.includedValues?.[this.currency];
        if (this.group.includedQuantity == null && includedValue == null) {
            for (const optionItem of this.items) {
                amount += optionItem.getQuantitySafe() * optionItem.getPriceSafe();
            }
            for (const optionItem of this.items) {
                optionItem.priceToAdd = optionItem.getPriceSafe();
            }
            return amount;
        }
        // included quantity/value case
        const options: CartItemOption[] = [...this.items];
        // sort highest price first
        options.sort((a, b) => b.price - a.price);
        const sumOfQuantities = options.reduce((sum, obj) => sum + obj.quantity, 0);
        // limit includedQuantity to sumOfQuantities to make loop safe
        // support includedQuantity == null case by using sumOfQuantities
        const includedQuantity = this.group.includedQuantity != null ?
            Math.min(this.group.includedQuantity, sumOfQuantities) : sumOfQuantities;

        let i = 0;
        let usedQuantity = 0;
        let usedValue = 0;

        while (i < options.length && usedQuantity < includedQuantity) {
            const option = options[i];
            const useQuantity = Math.min(option.quantity, includedQuantity - usedQuantity);

            i++;
            usedQuantity += useQuantity;
            usedValue += useQuantity * option.getPriceSafe();

            const nonIncludedQuantity = option.quantity - useQuantity;
            if (nonIncludedQuantity > 0) {
                // add remaining price of non-included quantity
                amount += nonIncludedQuantity * option.getPriceSafe();
            }
            if (includedValue != null) {
                const diff = usedValue - includedValue;
                if (diff > 0) { // included value has been used up
                    amount += diff; // add overflowing cost to price
                    break; // remaining includedQuantity is meaningless now
                }
            }
        }
        // continue with remaining non-included MenuOptionItems
        for (; i < options.length; i++) {
            const option = options[i];
            amount += option.quantity * option.getPriceSafe();
        }
        // calculate priceToAdd (cost for adding one more of each option)
        const moreIncludedQuantity = this.group.includedQuantity == null || sumOfQuantities < this.group.includedQuantity;
        const moreIncludedValue = includedValue != null ?
            Math.max(0, includedValue - usedValue) : Number.MAX_VALUE;
        if (moreIncludedQuantity) {
            for (const optionItem of this.items) {
                optionItem.priceToAdd = Math.max(0, optionItem.getPriceSafe() - moreIncludedValue);
            }
        } else {
            for (const optionItem of this.items) {
                optionItem.priceToAdd = optionItem.getPriceSafe();
            }
        }
        return amount;
    }

    /**
     * Makes sure one radio button is selected
     */
    private ensureRadioGroupSelection() {
        if (this.group.type === 'radio' && this.items.length > 0) {
            let anySet = false;
            for (const cio of this.items) {
                if (cio.quantity > 0) {
                    anySet = true;
                    break;
                }
            }
            if (!anySet) {
                this.items[0].quantity = 1;
            }
        }
    }

    private updateGroupMax() {
        if (this.group != null && this.group.type === 'checkbox' || this.group.type === 'quantity') {
            if (this.group.maxQuantity != null) {
                const sumOfQuantities = this.items.reduce((sum, obj) => sum + obj.quantity, 0);
                this.maxed = this.group.maxQuantity <= sumOfQuantities;
            } else {
                this.maxed = false;
            }
            if (this.maxed) {
                for (const item of this.items) {
                    item.maxed = true;
                }
            } else {
                for (const item of this.items) {
                    if (item.item.maxQuantity != null && item.item.maxQuantity > 0) {
                        item.maxed = item.item.maxQuantity <= item.getQuantitySafe();
                    } else {
                        item.maxed = false;
                    }
                }
            }
        }
    }
}
