import {mapObject} from '../../utils';
import {Order} from '../order';

export class StripeChargeAuthResponse {
    public authUrls: string;
    public order: Order;

    public revive() {
        if (this.order) {
            this.order = mapObject(this.order, Order);
        }
    }
}
