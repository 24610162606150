import {mapObject} from '../../utils';
import {MenuOptionGroup} from '../menu-option-group';
import {MenuOptionItem} from '../menu-option-item';
import {OrderInputItemOption} from '../order';

export class CartItemOption {
    public currency: Readonly<string>;
    public group: Readonly<MenuOptionGroup>;

    private _item: Readonly<MenuOptionItem>;
    public get item(): Readonly<MenuOptionItem> {
        return this._item;
    }
    public set item(obj: Readonly<MenuOptionItem>) {
        this._item = mapObject(obj, MenuOptionItem);
    }

    public priceToAdd: number;
    public quantity: number;

    public get id() {
        return this.item.id;
    }
    public set id(ignore) {
    }

    public maxed = false;

    public get name() {
        return this.item.name;
    }

    public get description() {
        return this.item.description;
    }

    public get price() {
        return this.item?.getPriceForCurrency(this.currency);
    }

    public static arrayCompare(listA: ReadonlyArray<CartItemOption>, listB: ReadonlyArray<CartItemOption>) {
        if (listA == null) {
            return listB == null;
        } else {
            if (listB == null) {
                return false;
            }
            if (listA.length !== listB.length) {
                return false;
            }
            found:
                for (const a of listA) {
                    for (const b of listB) {
                        if (a.item.id === b.item.id && a.group.id === b.group.id) {
                            continue found;
                        }
                    }
                    return false; // option in a did not exist in b
                }
            return true; // all options found
        }
    }

    public static buildArray(input: ReadonlyArray<CartItemOption>): OrderInputItemOption[] {
        if (input == null) {
            return null;
        }
        return input.map(o => o.build());
    }

    public static cloneArray(input: ReadonlyArray<CartItemOption>) {
        if (input == null) {
            return null;
        }
        return input.map(obj => CartItemOption.deserialize(obj));
    }

    public static create(group: Readonly<MenuOptionGroup>,
        item: Readonly<MenuOptionItem>,
        quantity: number,
        currency: Readonly<string>
    ): CartItemOption {
        const obj = new CartItemOption();
        obj.group = group;
        obj.item = item;
        obj.quantity = quantity;
        obj.currency = currency;
        return obj;
    }

    public static deserialize(input: Record<string, any>): CartItemOption {
        return mapObject(input, CartItemOption);
    }

    public static deserializeArray(input: Record<string, any>[]): CartItemOption[] {
        if (input == null) {
            return null;
        }
        return input.map(obj => CartItemOption.deserialize(obj));
    }

    public build(): OrderInputItemOption {
        const obj: OrderInputItemOption = {group: this.group.id, id: this.item.id, quantity: this.quantity};
        if (this.item.type === 'item') {
            obj.itemId = this.item.itemId;
        }
        return obj;
    }

    public getPriceSafe(): number {
        return this.item?.getPriceForCurrency(this.currency) ?? 0;
    }

    public getQuantitySafe(): number {
        return this.quantity != null ? this.quantity : 1;
    }

    public validate() {
        return this.group != null && this.item != null;
    }
}
