import {DateTime} from 'luxon';
import {PejLocale} from '../core/locale';

function getLocale() {
    return PejLocale.lang;
}

/** Format timestamp to YYYY-MM-DD HH:mm format used for Pej API calls. */
export function apiDateTime(millis: number | null) {
    const dt = millis != null ? DateTime.fromMillis(millis) : DateTime.local();
    return dt.toFormat('yyyy-MM-dd HH:mm');
}

/** Capitalize first character only - as opposed to Title Case. */
export function capitalizeFirst(s: string | null) {
    if (s == null || s.length < 1) { return s; }
    return s.charAt(0).toLocaleUpperCase() + s.slice(1);
}

/** Format timestamp to YYYY-MM-DD format (ISO 8601) used for Pej API calls. */
export function isoDate(millis: number | null) {
    const dt = millis != null ? DateTime.fromMillis(millis) : DateTime.local();
    return dt.toISODate();
}

export function localizedRelativeDate(millis: number | null) {
    const dt = millis != null ? DateTime.fromMillis(millis) : DateTime.local();
    const eod = DateTime.local().endOf('day');
    if (dt > eod) {
        return dt.setLocale(getLocale()).toFormat('yyyy-MM-dd');
    } else {
        // In Swedish the relative dates are relative, which we never want
        return capitalizeFirst(dt.setLocale(getLocale()).toRelativeCalendar());
    }
}

export function localizedDate(millis: number | null) {
    const dt = millis != null ? DateTime.fromMillis(millis) : DateTime.local();
    return dt.setLocale(getLocale()).toLocaleString();
}

export function localizedDateTime(millis: number | null) {
    const dt = millis != null ? DateTime.fromMillis(millis) : DateTime.local();
    return dt.setLocale(getLocale()).toLocaleString(DateTime.DATETIME_SHORT);
}

export function localizedRelativeDateAbsoluteTime(millis: number | null) {
    const relativeDate = localizedRelativeDate(millis);
    const time = localizedTime(millis);
    return `${relativeDate} ${time}`;
}

export function localizedTime(millis: number | null) {
    const dt = millis != null ? DateTime.fromMillis(millis) : DateTime.local();
    return dt.setLocale(getLocale()).toLocaleString(DateTime.TIME_SIMPLE);
}
