import {mapMapMap, mapObject} from '../../utils';
import {Item} from '../item';
import {OrderInputItem} from '../order';
import {CartItemOption} from './cart-item-option';
import {CartItemOptionGroup} from './cart-item-option-group';

/** Convert a Map<String, CartItemOptionGroup> to a List<CartItemOption>. */
function groupsToOptions(groups: ReadonlyMap<string, CartItemOptionGroup>): CartItemOption[] {
    if (groups == null) { return []; }
    const options = [];
    for (const [k, v] of groups) {
        for (const item of v.items) {
            options.push(item);
        }
    }
    return options;
}

export class CartItem {
    public currency: Readonly<string>;
    public id: number;

    private _item: Readonly<Item>;
    public get item(): Readonly<Item> {
        return this._item;
    }
    public set item(item: Readonly<Item>) {
        this._item = mapObject(item, Item);
    }

    private _optionGroups: ReadonlyMap<string, CartItemOptionGroup>;
    public get optionGroups(): ReadonlyMap<string, CartItemOptionGroup> {
        return this._optionGroups;
    }
    public set optionGroups(m: ReadonlyMap<string, CartItemOptionGroup>) {
        this._optionGroups = mapMapMap(m, CartItemOptionGroup);
    }

    public get options(): ReadonlyArray<CartItemOption> {
        return groupsToOptions(this._optionGroups);
    }
    public set options(optons: ReadonlyArray<CartItemOption>) { }

    /** Calculated when needed in `get price()`. Must be reset when `item` or `options` changes. */
    private _price: number;
    public get price(): number {
        if (this._price == null) {
            this._price = this.calcPrice();
        }
        return this._price;
    }
    public set price(price: number) { }

    public get priceWithoutMenuOptions(): number {
        if (this._item == null) { return null; }
        return this._item.prices[this.currency];
    }

    public quantity: number;

    public timeUpdated: number;

    public get taxRate(): number {
        return this._item?.taxRate;
    }

    public get total(): number {
        let result = this.price * this.quantity;
        const unitServiceCharge = this.item?.posData?.serviceCharge?.prices?.[this.currency];
        if (unitServiceCharge) {
            result += unitServiceCharge * this.quantity;
        }
        return result;
    }

    /** Note: this does not clone options. Do not modify your options array after setting it here. */
    public static create(item: Readonly<Item>,
        currency: Readonly<string>,
        optionGroups?: ReadonlyMap<string, CartItemOptionGroup>,
        quantity?: number
    ) {
        const cartItem = new CartItem();
        cartItem.currency = currency;
        cartItem._item = item;
        cartItem.quantity = quantity != null ? quantity : 1;
        cartItem._optionGroups = optionGroups;
        return cartItem;
    }

    public static deserialize(obj) {
        return mapObject(obj, CartItem);
    }

    public build(): OrderInputItem {
        return {itemKey: this._item.key, options: CartItemOption.buildArray(this.options), quantity: this.quantity};
    }

    /** Updates this CartItem's _item pointer and returns true if the price changed. */
    public updateItem(item: Readonly<Item>) {
        const oldPrice = this._price;
        this._item = item;
        this._price = undefined;
        this.calcPrice();
        return oldPrice !== this._price;
    }

    public validate() {
        return this._optionGroups == null || this.options.every(option => option.validate());
    }

    private calcPrice(): number {
        if (this._item == null) { return null; }
        let amount = this._item.prices[this.currency];
        if (this._optionGroups != null) {
            const groupIds = this._optionGroups.keys();
            for (const groupId of groupIds) {
                const group: CartItemOptionGroup = this._optionGroups.get(groupId);
                amount += group.price;
            }
        }
        return amount;
    }
}
