import {Component, Input} from '@angular/core';

@Component({
  selector: 'header',
  styleUrls: [ './header.component.scss' ],
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Input() public headerTitle: String;
  @Input() public subTitle: String;
}
